import { isEmail } from "@/hooks/user/userVerificationUtils";
import { platform } from "@/platform";
import Event from "@/utils/Event";
import { now } from "@/utils/SyncUtils";
import noop from "@/utils/noop";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import {
    fetchAuthSession,
    signIn as amplifySignIn,
    signInWithRedirect,
    signOut as amplifySignOut,
} from "aws-amplify/auth";
import { mutate } from "swr";
import { LocalUser, SIGN_UP_TRACKED, TEMP_CACHED_SIGN_IN_SOURCE_KEY } from "./types";
import { generateUnauthUser, getAuthenticatedUser } from "./graphqlUtils";

export const signOutListeners = new Event();

export const maybeLogSource = async (user?: UserDetails) => {
    if (!user) {
        return;
    }
    const storage = await platform.storage();

    // User has just logged in, if source is available, the user is new (within 60 seconds)
    const source = await storage.getWithExpiry(TEMP_CACHED_SIGN_IN_SOURCE_KEY);
    const isSignUpTracked = await storage.get(SIGN_UP_TRACKED);
    if (source && now() - user.created < 120 && !isSignUpTracked) {
        const mixpanel = await platform.analytics.mixpanel();
        mixpanel.alias(user.ID);
        mixpanel.track("Sign Up", { signInType: user.signInType, isNewUser: true, source });
        await storage.remove(TEMP_CACHED_SIGN_IN_SOURCE_KEY);
        await storage.set(SIGN_UP_TRACKED, "true");
    }
};

export const fetchCurrentUserInfo = async (): Promise<LocalUser> => {
    try {
        const localUser = await getAuthenticatedUser();
        maybeLogSource(localUser.user);
        refreshSession();
        return localUser;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("fetchCurrentUserInfo: failed to parse user", e);
        return generateUnauthUser();
    }
};

const sendSessionToChromeExtension = async (idToken: string) => {
    try {
        const chromeExtensionId = process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID;
        chrome.runtime.sendMessage(chromeExtensionId, { idToken }, noop);
    } catch {
        // ignore error thrown when chrome extension is not installed
    }
};

export const refreshSession = async (options: { forceRefresh?: boolean } = {}) => {
    try {
        const session = await fetchAuthSession({ forceRefresh: options.forceRefresh });
        await sendSessionToChromeExtension(session.tokens.idToken.toString());
        return session;
    } catch (error) {
        await sendSessionToChromeExtension(null);
        return null;
    }
};

const checkAndConvertLegacyUserAlertsIfNeeded = (alerts: string | undefined): string => {
    if (!alerts)
        return JSON.stringify({
            done: [],
            todo: [],
        });

    const oldUserAlertsKeys = ["shown", "toShow", "toRemove"];

    const parsedUserAlerts = JSON.parse(alerts);
    const isLegacyUserAlerts = Object.keys(parsedUserAlerts).every(key => oldUserAlertsKeys.includes(key));

    if (!isLegacyUserAlerts) return alerts;

    const done = Object.values(parsedUserAlerts.shown).flat() as string[];

    const todo = parsedUserAlerts.toShow.map(event => ({
        eventName: event.eventName,
        visitCount: event.visitCount,
    }));

    return JSON.stringify({ done, todo });
};

export const fetchServerUserInfo = async (): Promise<LocalUser> => {
    try {
        const retUser = await getAuthenticatedUser();
        retUser.user.alerts = checkAndConvertLegacyUserAlertsIfNeeded(retUser.user.alerts);

        maybeLogSource(retUser.user);
        await mutate("getCurrentUser", retUser, { revalidate: false });
        return retUser;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("fetchServerUserInfo: failed to parse user", e);
        signOut();
    }
};

// Signs the user out and sets up all state accordingly
export const signOut = async () => {
    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Logout");

    const storage = await platform.storage();
    try {
        // Sign out from AWS Amplify
        await amplifySignOut();
    } catch (e) {
        const { report } = await platform.analytics.logging();
        report(e, "signOut", {});
        return null;
    }

    // clean up referral information
    await storage.remove("alreadyReferred");
    await storage.remove("referralId");

    await mutate("getCurrentUser", generateUnauthUser(), { revalidate: false });
    signOutListeners.notify();
};

/**
 * A one-to-one mapping between emails and usernames
 */
export const usernameFromEmail = email => email.replace("@", "@-@");

/**
 * A one-to-one mapping between usernames and emails
 * @param email
 */
export const emailFromUsername = username => username?.replace("@-@", "@");

export const cleanEmail = email => email.replace(/ /g, "").trim().toLowerCase();

/**
 * Converts a Cognito auth function to a safe version with additional error handling
 */
export const authSafe = authFun => {
    const authFunSafe = (arg1, arg2, arg3) => {
        if (arg3) {
            return authFun(arg1, arg2, arg3);
        } else if (arg2) {
            return authFun(arg1, arg2);
        } else {
            return authFun(arg1);
        }
    };

    return (identifier: string, arg2: string = null, arg3: string = null) => {
        if (!identifier) {
            throw new Error(`"identifier" must be provided, found: ${identifier}`);
        }

        identifier = identifier.toLowerCase();
        return new Promise((resolve, reject) => {
            const isPotentialEmail = isEmail(identifier);
            if (isPotentialEmail) {
                authFunSafe(identifier.toLowerCase(), arg2, arg3)
                    .then(resolve)
                    .catch(err => {
                        if (err.name === "UserNotFoundException" || err.name === "InvalidParameterException") {
                            authFunSafe(usernameFromEmail(identifier), arg2, arg3)
                                .then(resolve)
                                .catch(async err => {
                                    if (
                                        err.name === "UserNotFoundException" ||
                                        err.name === "InvalidParameterException"
                                    ) {
                                        authFunSafe(identifier, arg2, arg3).then(resolve).catch(reject);
                                    } else {
                                        reject(err);
                                        const { report } = await platform.analytics.logging();
                                        report(err, "verify", { identifier });
                                    }
                                });
                        } else {
                            reject(err);
                        }
                    });
            } else {
                authFunSafe(identifier, arg2, arg3).then(resolve).catch(reject);
            }
        });
    };
};

export const federateSignIn = async provider => {
    await signInWithRedirect({ provider });
};

export const signIn = async (email: string, password: string) => {
    await amplifySignOut();
    await authSafe((a, b) => amplifySignIn({ username: a, password: b }))(email, password);
    await fetchServerUserInfo();
};
