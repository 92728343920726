import Node from "./Node";

const DEFAULT_LANGUAGE = "javascript";

export default class CodeFence extends Node {
    get name() {
        return "code_fence";
    }

    get schema() {
        return {
            attrs: {
                language: {
                    default: DEFAULT_LANGUAGE,
                },
            },
            content: "text*",
            marks: "",
            group: "block",
            code: true,
            defining: true,
            draggable: false,
            parseDOM: [
                { tag: "pre", preserveWhitespace: "full" },
                {
                    tag: ".code-block",
                    preserveWhitespace: "full",
                    contentElement: "code",
                    getAttrs: (dom: HTMLDivElement) => {
                        return {
                            language: dom.dataset.language,
                        };
                    },
                },
            ],
            toDOM: node => [
                "pre",
                [
                    "code",
                    {
                        spellCheck: false,
                        ...(node.attrs.language && { class: `language-${node.attrs.language}` }),
                    },
                    0,
                ],
            ],
        };
    }

    toMarkdown(state, node) {
        state.write("```" + (node.attrs.language || "") + "\n");
        state.text(node.textContent, false);
        state.ensureNewLine();
        state.write("```");
        state.closeBlock(node);
    }

    get markdownToken() {
        return "fence";
    }

    parseMarkdown() {
        return {
            block: "code_block",
            getAttrs: tok => ({ language: tok.info }),
        };
    }
}
