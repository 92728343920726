import { fromEntries } from "./genericUtils";
import { NonNullableObject } from "@/types/common";
import { ExclusiveUserContent } from "@knowt/syncing/types/common";
import { FlashcardSet, Folder, Media, Note } from "@knowt/syncing/graphql/schema";

export const DEFAULT_NOTE_SUMMARY = "";
export const DEFAULT_NOTE_TITLE = "";
export const DEFAULT_NOTE_CONTENT = "";

export const UNTITLED = "Untitled";
export const TITLE_FILLER = "Note";

/**
 * Removes all fields from an object that are null or undefined
 */
export const scrapeEmptyFields = <T extends Record<string, unknown>>(obj: T) => {
    return fromEntries(
        Object.entries(obj).filter(([, val]) => val !== null && val !== undefined)
    ) as NonNullableObject<T>;
};

export const deepScrapeEmptyFields = (obj: unknown, keysWithAllowedNullValue: string[] = []) => {
    if (obj === null || obj === undefined) return obj;
    if (typeof obj !== "object") return obj;
    if (Array.isArray(obj)) return obj.map(el => deepScrapeEmptyFields(el, []));
    return Object.fromEntries(
        Object.entries(obj)
            .map(([key, val]) => [key, deepScrapeEmptyFields(val, [])])
            .filter(([key, val]) => {
                if (keysWithAllowedNullValue.includes(key)) return true;
                return val !== null && val !== undefined;
            })
    );
};

/**
 * given a base object, create and return a new object without the given keys
 */
export const objectWithout = <T, K extends keyof T>(baseObject: T, ...keys: K[]): Omit<T, K> => {
    const newObject = { ...baseObject };
    keys.forEach(key => delete newObject[key]);
    return newObject;
};

/**
 * pick certain keys from a given base object
 */
export const pick = <T, K extends keyof T>(baseObject: T, ...keys: K[]): Pick<T, K> => {
    const newObject = {} as Pick<T, K>;
    keys.forEach(key => (newObject[key] = baseObject[key]));
    return newObject;
};

export const isNote = (
    item: FlashcardSet | Folder | Media | Note | ExclusiveUserContent | null | undefined
): item is Note => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.note?.__typename === "Note" ||
        (item as FlashcardSet | Folder | Media | Note)?.__typename === "Note"
    );
};

export const isFlashcardSet = (
    item: FlashcardSet | Folder | Media | Note | ExclusiveUserContent | null | undefined
): item is FlashcardSet => {
    if (!item) return false;
    return (
        (item as ExclusiveUserContent)?.flashcardSet?.__typename === "FlashcardSet" ||
        (item as FlashcardSet | Folder | Media | Note)?.__typename === "FlashcardSet"
    );
};

export const isFolder = (
    item: FlashcardSet | Folder | Media | Note | ExclusiveUserContent | null | undefined
): item is Folder => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.folder?.__typename === "Folder" ||
        (item as FlashcardSet | Folder | Media | Note)?.__typename === "Folder"
    );
};

export const isMedia = (
    item: FlashcardSet | Folder | Media | Note | ExclusiveUserContent | null | undefined
): item is Media => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.media?.__typename === "Media" ||
        (item as FlashcardSet | Folder | Media | Note)?.__typename === "Media"
    );
};
