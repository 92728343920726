import Mark from "./Mark";

export default class Strikethrough extends Mark {
    get name() {
        return "strikethrough";
    }

    get schema() {
        return {
            parseDOM: [
                {
                    tag: "s",
                },
                {
                    tag: "del",
                },
                {
                    tag: "strike",
                },
            ],
            toDOM: () => ["del", 0],
        };
    }

    get toMarkdown() {
        return {
            open: "~~",
            close: "~~",
            mixable: true,
            expelEnclosingWhitespace: true,
            escape: false,
        };
    }

    get markdownToken() {
        return "s";
    }

    parseMarkdown() {
        return { mark: "strikethrough" };
    }
}
