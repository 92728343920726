import Node from "./Node";

export default class TableCell extends Node {
    get name() {
        return "td";
    }

    get schema() {
        return {
            content: "paragraph+",
            tableRole: "cell",
            isolating: true,
            parseDOM: [{ tag: "td" }],
            toDOM(node) {
                return ["td", node.attrs.alignment ? { style: `text-align: ${node.attrs.alignment}` } : {}, 0];
            },
            attrs: {
                colspan: { default: 1 },
                rowspan: { default: 1 },
                alignment: { default: null },
            },
        };
    }

    toMarkdown() {
        // see: renderTable
    }

    parseMarkdown() {
        return {
            block: "td",
            getAttrs: tok => ({ alignment: tok.info }),
        };
    }
}
