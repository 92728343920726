"use client";

import React, { forwardRef } from "react";
// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import ConditionalTooltip from "../wrappers/ConditionalTooltip";
import { themeColors } from "@/utils/themeColors";

export type CircularButtonProps = {
    sx?: SxProps;
    radius?: string;
    tooltip?: string;
    children?: React.ReactNode;
    component?: React.ElementType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Our new CircularButton component with simpler props and easily customized through `sx` prop.
 * This component has automatic accessibility (color change on hovered and color change on clicked).
 * It also inherits all button tag prop that you could pass
 * ---
 * NOTE: (so we can prevent to over-extend this):
 * - Please prefer to style with `sx` rather than `style`
 * - if you want to disable the default hover effect, pass `"&:hover": {}` to `sx` prop
 */
const CircularButton = forwardRef(
    ({ sx, tooltip, children, radius, component, ...htmlButtonAttr }: CircularButtonProps, ref) => (
        <ConditionalTooltip tooltip={tooltip}>
            <Box
                ref={ref}
                component={component || "button"}
                aria-label="Knowt button"
                sx={{
                    // accessibility on hover
                    "&:hover": !htmlButtonAttr.disabled ? { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.35)" } : null,
                    "&:active": !htmlButtonAttr.disabled ? { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.2)" } : null,
                    transition: "all 0.1s ease-in-out",
                    // override the default button style
                    cursor: htmlButtonAttr.disabled ? "default" : "pointer",
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: 999,
                    border: "none",
                    color: themeColors.neutralBlack,
                    // center the wrapped item
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // customizations...
                    width: radius,
                    height: radius,
                    flexShrink: 0,
                    ...sx,
                }}
                {...htmlButtonAttr}>
                {children}
            </Box>
        </ConditionalTooltip>
    )
);

CircularButton.displayName = "CircularButton";
export default CircularButton;
