import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { deleteFlashcardSets, fetchFlashcardSetsMetaDataByNote } from "../flashcards/utils";
import { callCreateNote, callDeleteNote, callGetNote, callUpdateNote } from "./graphqlUtils";
import { safeLocalMutate } from "@/hooks/swr";
import { platform } from "@/platform";
import { NoteMetadata, PartialWithRequired } from "@/types/common";
import { Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { now } from "@/utils/SyncUtils";
import {
    DEFAULT_NOTE_CONTENT,
    DEFAULT_NOTE_SUMMARY,
    DEFAULT_NOTE_TITLE,
    objectWithout,
    pick,
} from "@/utils/dataCleaning";
import { retry } from "@/utils/genericUtils";
import { resolveClassSWRKey, updateClass } from "@/hooks/classes/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { fetchBookmarks } from "@/hooks/bookmarks/utils";

type NotesMetadataMap = Record<string, NoteMetadata>;

export const resolveNotesSWRKey = ({
    userId,
    folderId = null,
    classId = null,
    isEnabled = true,
}: {
    userId: string;
    folderId?: string | null;
    classId?: string | null;
    isEnabled?: boolean;
}) => {
    return isEnabled && userId ? ["notes", userId, folderId, classId] : null;
};

export const resolveNoteSWRKey = ({
    userId,
    noteId,
    isEnabled = true,
}: {
    userId: string;
    noteId?: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && noteId ? ["note", noteId, userId] : null;
};

export const createNewNote = async (initialFields: Partial<Note>, user: UserDetails) => {
    const input = { ...getDefaultNoteFields(user), ...initialFields };
    const note = await callCreateNote(input);
    if (note.classId) {
        mutate(resolveClassSWRKey({ classId: note.classId, userId: user.ID }));
    }
    await updateNoteInNoteList(note);
    return mutate(resolveNoteSWRKey({ noteId: note.noteId, userId: user.ID }), note, { revalidate: false });
};

export const getDefaultNoteFields = (user: UserDetails): Partial<Note> => {
    const defaultFields: Partial<Note> = {};

    defaultFields.noteId = uuidv4();
    defaultFields.userId = user.ID;

    defaultFields.title = DEFAULT_NOTE_TITLE;
    defaultFields.content = DEFAULT_NOTE_CONTENT;
    defaultFields.summary = DEFAULT_NOTE_SUMMARY;

    defaultFields.trash = false;

    defaultFields.schoolId = user.schoolId;
    defaultFields.grade = user.grade;

    defaultFields.updated = String(now());
    defaultFields.created = defaultFields.updated;

    return defaultFields;
};

const backendDelete = async (noteId: string, userId: string) => {
    return await retry(async () => {
        await deleteNoteAssociatedItems(noteId, userId);
        return callDeleteNote(noteId, userId);
    });
};

const deleteNoteAssociatedItems = async (noteId: string, userId: string) => {
    const associatedFlashcardSets = (await fetchFlashcardSetsMetaDataByNote(noteId)).map(f => f.flashcardSetId);
    await deleteFlashcardSets({ flashcardSetIds: associatedFlashcardSets, userId });
};

/**
 * Updates the truncated representation of the note in the note list
 */
export const updateNoteInNoteList = async (newNoteObj: Note) => {
    const { userId, folderId, classId } = newNoteObj;

    const relatedSWRKeys = [
        resolveNotesSWRKey({ userId }),
        ...(classId ? [resolveNotesSWRKey({ userId, classId })] : []),
        ...(folderId ? [resolveNotesSWRKey({ userId, folderId })] : []),
    ];

    await Promise.all(
        relatedSWRKeys.map(swrKey =>
            safeLocalMutate(swrKey, (oldNotes: NotesMetadataMap) => ({
                ...oldNotes,
                [newNoteObj.noteId]: objectWithout(newNoteObj, "content"),
            }))
        )
    );
};

/**
 * Saves a note both locally and on the backend
 */
export const saveNote = async (updates: PartialWithRequired<Note, "userId" | "noteId">): Promise<Note> => {
    updates.updated = String(now());
    const { userId, noteId } = updates;

    await mutate(
        ["note", noteId, userId],
        async (_oldCache: Note | undefined) => {
            const oldCache = _oldCache ?? (await callGetNote({ noteId }));
            return { ...oldCache, ...updates };
        },
        { revalidate: false }
    );

    const updatedNote = await callUpdateNote(updates);
    await updateNoteInNoteList(updatedNote);

    if (updatedNote.flashcardSetId && Object.keys(updates).some(key => SHARED_NOTE_FLASHCARD_SET_KEYS.includes(key))) {
        // backend will update the SHARED_NOTE_FLASHCARD_SET_KEYS keys of the flashcardSet
        // to follow those of the note, so we need to revalidate the cached flashcardSet:
        await mutate(["flashcard-set", updatedNote.flashcardSetId, userId]);
    }

    return updatedNote;
};

export const SHARED_NOTE_FLASHCARD_SET_KEYS = [
    "title",
    "tags",
    "public",
    "password",
    "schoolId",
    "courseId",
    "exam_v2",
    "examUnit",
    "examSection",
    "subject",
    "topic",
];

/**
 * Move a set of notes from home screen to a folder, or vice versa
 * @param noteIds
 * @param userId
 * @param destinationFolderId - null for home screen
 * @param sourceFolderId - null for home screen
 * @param optimisticUpdate - while moving file from navigation panel, the optimistic update is done manually because we're manually sorting the note inside the list
 */
export const moveNotes = async ({
    noteIds,
    userId,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
}: {
    noteIds: string[];
    userId: string | undefined;
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    await genericUpdateNotes({
        noteIds,
        userId,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: { folderId: destinationFolderId, classId: destinationClassId },
    });
};

export const trashNotes = async ({
    noteIds,
    userId,
    sourceFolderId,
    sourceClassId,
}: {
    noteIds: string[];
    userId: string;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    await updateNotesTrashState({
        noteIds,
        userId,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreNotes = async ({
    noteIds,
    userId,
    sourceFolderId,
}: {
    noteIds: string[];
    userId: string;
    sourceFolderId: string | null;
}) => {
    await updateNotesTrashState({
        noteIds,
        userId,
        sourceFolderId,
        inTrash: false,
        removeFromFolder: true,
    });
};

export const updateFolderNotesTrashState = async ({
    folderId,
    noteIds,
    userId,
    inTrash,
}: {
    folderId: string;
    noteIds: string[];
    userId: string;
    inTrash: boolean;
}) => {
    await updateNotesTrashState({ noteIds, userId, sourceFolderId: folderId, inTrash, removeFromFolder: false });
};

const updateNotesTrashState = async ({
    noteIds,
    userId,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    noteIds: string[];
    userId: string;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateNotes({
        noteIds,
        userId,
        sourceFolderId,
        // TODO: why destinationFolderId is set to sourceFolderId ?
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        sourceClassId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromClass && { classId: null }),
            ...(removeFromFolder && { folderId: null }),
        },
    });
};

export const genericUpdateNotes = async ({
    noteIds,
    userId,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    updatedFields,
}: {
    noteIds: string[];
    userId: string;
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    updatedFields: Partial<Note>;
}) => {
    Promise.all([
        mutate(
            resolveNotesSWRKey({ userId, folderId: sourceFolderId }),
            (oldNotes: NotesMetadataMap) => updateCache(oldNotes, noteIds, updatedFields),
            { revalidate: false }
        ),
        mutate(
            resolveNotesSWRKey({ userId, classId: sourceClassId }),
            (oldNotes: NotesMetadataMap) => updateCache(oldNotes, noteIds, updatedFields),
            { revalidate: false }
        ),
    ]);

    try {
        const updatedNotes = await Promise.all(noteIds.map(noteId => saveNote({ noteId, userId, ...updatedFields })));

        if (sourceFolderId !== destinationFolderId) {
            await mutate(
                resolveNotesSWRKey({ userId, folderId: destinationFolderId }),
                (oldNotes: NotesMetadataMap) => addToCache(oldNotes, updatedNotes),
                { revalidate: false }
            );
        }

        if (sourceClassId !== destinationClassId) {
            await mutate(
                resolveNotesSWRKey({ userId, classId: destinationClassId }),
                (oldNotes: NotesMetadataMap) => addToCache(oldNotes, updatedNotes),
                { revalidate: false }
            );

            if (sourceClassId) {
                const sourceClass = await callGetClass({ classId: sourceClassId });
                const updatedPinned = sourceClass.pinned.filter(itemId => !noteIds.includes(itemId));
                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId });
                const bookmarkedNotes = noteIds.filter(itemId => bookmarks.some(b => b.ID === itemId));
                if (bookmarkedNotes.length) {
                    const destinationClass = await callGetClass({ classId: destinationClassId });
                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedNotes];
                    await updateClass({ classId: destinationClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }
        }
    } catch {
        // TODO: shouldnt we revalidate per folder/class?
        await revalidateNotes({ noteIds, userId });
    }
};

export const deleteNotes = async ({ noteIds, userId }: { noteIds: string[]; userId: string }) => {
    await mutate(resolveNotesSWRKey({ userId }), (notes: NotesMetadataMap) => objectWithout(notes, ...noteIds), {
        revalidate: false,
    });
    await Promise.all(noteIds.map(noteId => mutate(["note", noteId, userId], null, { revalidate: false })));

    try {
        await Promise.all(noteIds.map(noteId => backendDelete(noteId, userId)));
    } catch {
        await revalidateNotes({ noteIds, userId });
    }
};

export const updateNotesPublicState = async ({
    noteIds,
    userId,
    sourceFolderId = null,
    isPublic,
    password = null,
}: {
    noteIds: string[];
    userId: string;
    isPublic: boolean;
    sourceFolderId: string | null;
    password?: string;
}) => {
    await mutate(
        resolveNotesSWRKey({ userId, folderId: sourceFolderId }),
        (oldNotes: NotesMetadataMap) => updateCache(oldNotes, noteIds, { public: isPublic, password }),
        { revalidate: false }
    );

    await Promise.all(noteIds.map(noteId => saveNote({ noteId, userId, public: isPublic, password })));
};

const revalidateNotes = async ({ noteIds, userId }: { noteIds: string[]; userId: string }) => {
    // TODO: also revalidate the note list cache? i.e. ["notes", userId, folderId?]
    await Promise.all(noteIds.map(noteId => mutate(["note", noteId, userId])));
};

const updateCache = (cache: NotesMetadataMap, noteIds: string[], updates: Partial<NoteMetadata>) => {
    const newCache = { ...cache };
    noteIds.forEach(noteId => {
        newCache[noteId] = { ...newCache[noteId], ...updates };
    });
    return newCache;
};

const addToCache = (cache: NotesMetadataMap, notes: NoteMetadata[]) => {
    const newCache = { ...cache };
    notes.forEach(note => (newCache[note.noteId] = note));
    return newCache;
};

export const duplicateNotes = (noteIds: string[], user: UserDetails, overrides?: Partial<Note>) => {
    return Promise.all(noteIds.map(noteId => duplicateNote(noteId, user, overrides)));
};

export const duplicateNote = async (baseNoteId: string, user: UserDetails, overrides: Partial<Note> = {}) => {
    const baseNote: Note = await callGetNote({ noteId: baseNoteId });
    const isNoteOwner = baseNote.userId === user.ID;

    const input = {
        ...getDefaultNoteFields(user),
        ...pick(baseNote, "content", "file", "importType", "tags", "subject", "topic"),
        title: baseNote.title + " (copy)",
        ...(isNoteOwner && { folderId: baseNote.folderId, classId: baseNote.classId }),
        ...overrides,
    };

    const newNote = await callCreateNote(input);
    await updateNoteInNoteList(newNote);

    if (newNote.classId) {
        mutate(resolveClassSWRKey({ classId: newNote.classId, userId: user.ID }));
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Note - Created", { noteId: newNote.noteId, duplicated: true });

    return newNote;
};
