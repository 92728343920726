import Node from "./Node";

export default class ListItem extends Node {
    get name() {
        return "list_item";
    }

    get defaultOptions() {
        return {
            includeDrag: true,
        };
    }

    get schema() {
        return {
            content: "paragraph block*",
            defining: true,
            draggable: this.options.includeDrag,
            parseDOM: [{ tag: "li" }],
            toDOM: () => [
                "li",
                this.options.includeDrag
                    ? {
                          class: this.options.includeDrag ? "drag" : "",
                      }
                    : {},
                0,
            ],
        };
    }

    toMarkdown(state, node) {
        state.renderContent(node);
    }

    parseMarkdown() {
        return { block: "list_item" };
    }
}
