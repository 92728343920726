import { imsizeRule } from "../rules/imsize";
import Node from "./Node";

export default class Image extends Node {
    get name() {
        return "image";
    }

    get schema() {
        return {
            inline: true,
            attrs: {
                src: {},
                alt: { default: null },
                title: { default: null },
                width: { default: null },
                height: { default: null },
            },
            content: "text*",
            marks: "",
            group: "inline",
            parseDOM: [
                {
                    tag: "div[class~=image]",
                    getAttrs: (dom: HTMLDivElement) => {
                        const img = dom.getElementsByTagName("img")[0];
                        return {
                            src: img?.getAttribute("src"),
                            alt: img?.getAttribute("alt"),
                            title: img?.getAttribute("title"),
                        };
                    },
                },
                {
                    tag: "img",
                    getAttrs: (dom: HTMLImageElement) => {
                        return {
                            src: dom.getAttribute("src"),
                            alt: dom.getAttribute("alt"),
                            title: dom.getAttribute("title"),
                        };
                    },
                },
            ],
            toDOM: node => {
                return [
                    "img",
                    {
                        ...node.attrs,
                        contentEditable: false,
                    },
                ];
            },
        };
    }

    toMarkdown(state, node) {
        let markdown =
            " ![" + state.esc((node.attrs.alt || "").replace("\n", "") || "") + "](" + state.esc(node.attrs.src);
        if (node.attrs.title) {
            markdown += ' "' + state.esc(node.attrs.title) + '"';
        }
        if (node.attrs.width && node.attrs.height) {
            markdown += " =" + node.attrs.width + "x" + node.attrs.height;
        }
        markdown += ")";
        state.write(markdown);
    }

    parseMarkdown() {
        return {
            node: "image",
            getAttrs: token => {
                return {
                    src: token.attrGet("src"),
                    alt: (token.children[0] && token.children[0].content) || null,
                    title: token.attrGet("title"),
                    width: token.attrGet("width") || null,
                    height: token.attrGet("height") || null,
                };
            },
        };
    }

    get rulePlugins() {
        return [imsizeRule];
    }
}
