/** Represents a icon size values for consistent styling. */
export const iconSizes = {
    /** @value 12 */
    XS: 12,

    /**
     * @value 16
     * @default
     * @recommended
     */
    SM_S: 14,

    /** @value 18 */
    SM: 18,

    /** @value 20 */
    MD_S: 20,

    /** @value 24 */
    MD: 24,

    /** @value 30 */
    MD_M: 30,

    /** @value 38 */
    LG: 38,

    /** @value 50 */
    XL: 50,
};

export const strokeWidth = {
    thin: 1,
    normal: 2,
    bold: 3,
};
