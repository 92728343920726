import { MarkSpec } from "../../../pm/model";
import Mark from "../Mark";
import { markRule } from "../../rules";

export class YellowBackground extends Mark {
    get name() {
        return "background_yellow";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "#FFFCCF",
                },
            },
            excludes: "background",
            group: "background",
            parseDOM: [
                {
                    tag: `mark[data-type="background-yellow"]`,
                },
            ],
            toDOM: () => [
                "mark",
                {
                    "data-color": "#FFFCCF",
                    style: "background-color: #FFFCCF; color: inherit",
                },
            ],
        };
    }

    get rulePlugins() {
        return [markRule({ delim: "{{", mark: "background_yellow" })];
    }

    get toMarkdown() {
        return {
            open: "{{",
            close: "{{",
            mixable: true,
            expelEnclosingWhitespace: true,
        };
    }

    parseMarkdown() {
        return { mark: "background_yellow" };
    }
}
