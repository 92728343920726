import { getHtmlFromContent } from "@knowt/editor/helpers/getHtmlFromContent";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { listFlashcardSetIdByNote } from "@/graphql/customQueries";
import {
    callBatchUpdateFlashcard,
    callCreateFlashcardSet,
    callDeleteFlashcardSet,
    callUpdateFlashcardSet,
    fetchFlashcardSet,
    flashcardSetWithFlashcardsData,
} from "@/hooks/flashcards/graphqlUtils";
import { resolveNoteSWRKey, saveNote, SHARED_NOTE_FLASHCARD_SET_KEYS } from "@/hooks/notes/utils";
import { safeLocalMutate } from "@/hooks/swr";
import { platform } from "@/platform";
import { Flashcard, FlashcardSet, FlashcardSide, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { listData, now } from "@/utils/SyncUtils";
import { asyncFilter, chunkArray } from "@/utils/arrayUtils";
import { deepScrapeEmptyFields, objectWithout, pick } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { ListFlashcardSetByUserNoContentQuery } from "@/graphql/customSchema";
import { resolveClassSWRKey, updateClass } from "@/hooks/classes/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { fetchBookmarks } from "@/hooks/bookmarks/utils";

const FLASHCARD_GOOD_QUALITY = 5;

export const resolveFlashcardSetsSWRKey = ({
    userId,
    folderId = null,
    classId = null,
    isEnabled = true,
}: {
    userId: string;
    folderId?: string;
    classId?: string;
    isEnabled?: boolean;
}) => {
    if (!isEnabled) return null;
    return ["flashcard-sets", userId, folderId, classId];
};

export const resolveFlashcardSetSWRKey = ({
    userId,
    flashcardSetId,
    isEnabled = true,
    loadingFlashcardSetId,
}: {
    userId: string;
    flashcardSetId?: string;
    loadingFlashcardSetId?: boolean;
    isEnabled?: boolean;
}) => {
    return !loadingFlashcardSetId && isEnabled ? ["flashcard-set", flashcardSetId, userId] : null;
};

export const MIN_FLASHCARDS_COUNT = 4;

export const completeToMinimumFlashcardsCount = async (flashcards: Flashcard[]) => {
    const flashcardsToAdd = await Promise.all(
        Array.from({ length: MIN_FLASHCARDS_COUNT - flashcards.length }, () => makeNewFlashcard())
    );

    return [...flashcards, ...flashcardsToAdd];
};

export const makeNoteFlashcardSet = async (note: Note, initialFlashcards: Flashcard[]): Promise<FlashcardSet> => {
    const { userId, noteId, classId } = note;

    const { stripedFlashcardSet, flashcards } = separateFlashcardSetFlashcards(
        deepScrapeEmptyFields(
            makeCreateFlashcardSetInitialInput({
                userId,
                flashcards: await completeToMinimumFlashcardsCount(initialFlashcards),
                overrides: {
                    noteId,
                    classId,
                    draft: true,
                    title: note.title,
                    public: note.public,
                    password: note.password,
                },
            })
        )
    );

    const createdFlashcardSet = await callCreateFlashcardSet(stripedFlashcardSet);

    const createdFlashcards = await callBatchUpdateFlashcard({
        userId,
        flashcardSetId: createdFlashcardSet.flashcardSetId,
        items: flashcards.map(flashcard => ({
            ...flashcard,
            userId,
            flashcardSetId: createdFlashcardSet.flashcardSetId,
        })),
    });

    await saveNote({ noteId: note.noteId, userId, flashcardSetId: createdFlashcardSet.flashcardSetId });

    const result = flashcardSetWithFlashcardsData(
        createdFlashcardSet,
        fromEntries(withHtmlFields({ flashcards: createdFlashcards }).map(f => [f.flashcardId, f]))
    );

    await mutate(resolveFlashcardSetSWRKey({ flashcardSetId: result.flashcardSetId, userId }), result, {
        revalidate: false,
    });

    if (result.classId) {
        mutate(resolveClassSWRKey({ classId: result.classId, userId }));
    }

    return result;
};

const separateFlashcardSetFlashcards = (flashcardSet: FlashcardSet) => {
    const flashcards = flashcardSet.flashcards;
    const stripedFlashcardSet = stripBaseFlashcardSet(flashcardSet);
    return { stripedFlashcardSet, flashcards };
};

export const stripBaseFlashcardSet = (flashcardSet: FlashcardSet): FlashcardSet => {
    return {
        ...flashcardSet,
        flashcards: flashcardSet.flashcards.map(fullFlashcardToBaseFlashcardSetFlashcard),
        size: flashcardSet.flashcards.length,
    };
};

export const generateFlashcards = (pairs: { term: string; definition: string }[]): Flashcard[] => {
    return pairs.map(({ term, definition }) =>
        makeNewFlashcard({
            term,
            definition,
        })
    );
};

export const makeStandaloneFlashcardSet = async ({
    userId,
    flashcards: initialFlashcards = [],
    ...overrides
}: {
    userId: string | undefined;
    flashcards?: Flashcard[];
} & Partial<FlashcardSet>): Promise<FlashcardSet> => {
    if (!userId) return null;

    const { stripedFlashcardSet, flashcards } = separateFlashcardSetFlashcards(
        deepScrapeEmptyFields(
            makeCreateFlashcardSetInitialInput({
                userId,
                flashcards: await completeToMinimumFlashcardsCount(initialFlashcards),
                overrides: { draft: true, ...overrides },
            })
        )
    );

    const flashcardsWithHtmlFields = withHtmlFields({ flashcards });

    const createdFlashcardSet = await callCreateFlashcardSet(stripedFlashcardSet);

    await Promise.all(
        chunkArray(flashcardsWithHtmlFields, 75).map(
            async chunk =>
                await callBatchUpdateFlashcard({
                    userId,
                    flashcardSetId: createdFlashcardSet.flashcardSetId,
                    items: chunk.map((flashcard: Flashcard) => ({
                        ...flashcard,
                        userId,
                        flashcardSetId: createdFlashcardSet.flashcardSetId,
                    })),
                })
        )
    ).catch(async error => {
        await deleteFlashcardSets({ flashcardSetIds: [createdFlashcardSet.flashcardSetId], userId });
        const { report } = await platform.analytics.logging();
        report(error, "makeStandaloneFlashcardSet", {});
        throw error;
    });

    const result = flashcardSetWithFlashcardsData(
        createdFlashcardSet,
        fromEntries(flashcardsWithHtmlFields.map(f => [f.flashcardId, f]))
    );

    await mutate(resolveFlashcardSetSWRKey({ flashcardSetId: result.flashcardSetId, userId }), result, {
        revalidate: false,
    });

    await addStandaloneFlashcardSetToTheCache(result);

    if (result.classId) {
        mutate(resolveClassSWRKey({ classId: result.classId, userId }));
    }

    return result;
};

export const scrapeEmptyFlashcards = async (flashcards: Flashcard[]): Promise<Flashcard[]> => {
    const nonEmptyFlashcards = await asyncFilter(flashcards, async flashcard => {
        const isEmpty = await isEmptyFlashcard(flashcard);
        return !isEmpty;
    });

    return nonEmptyFlashcards.map(flashcard => ({
        ...flashcard,
        term: flashcard.term ?? "",
        definition: flashcard.definition ?? "",
    }));
};

export const isEmptyFlashcard = async (flashcard: Flashcard) => {
    return (
        (await isFlashcardContentEmpty(flashcard.term)) &&
        (await isFlashcardContentEmpty(flashcard.definition)) &&
        !flashcard.image &&
        !flashcard.secondaryImage
    );
};

export const isFlashcardContentEmpty = async val => {
    const { getPlainTextFromContent } = await platform.dataCleaning();
    return getPlainTextFromContent({ content: val, type: "flashcard" }).trim() === "";
};

export const fullFlashcardToBaseFlashcardSetFlashcard = (flashcard: Flashcard) => {
    return pick(flashcard, "flashcardId");
};

const makeCreateFlashcardSetInitialInput = ({
    userId,
    flashcards,
    overrides,
}: {
    userId: string;
    flashcards: Omit<Flashcard, "flashcardId" | "flashcardSetId">[];
    overrides?: Partial<FlashcardSet>;
}) => {
    const flashcardSetId = uuidv4();

    return {
        userId,
        flashcardSetId,
        flashcards: flashcards.map(flashcard => ({
            flashcardId: uuidv4(),
            ...flashcard,
            flashcardSetId,
        })),
        position: 0,
        trash: false,
        draft: false,
        classPublic: false,
        sort: now(), // This field is used to differentiate between base sets and study sets
        created: now(),
        updated: now(),
        ...overrides,
    };
};

export const makeNewFlashcard = (overrides?: Partial<Flashcard>): Flashcard => {
    const term = overrides?.term ?? "";
    const definition = overrides?.definition ?? "";

    return {
        flashcardId: uuidv4(),
        term,
        definition,
        trash: false,
        edited: false,
        disabled: false,
        quality: FLASHCARD_GOOD_QUALITY,
        created: String(now()),
        updated: String(now()),
        ...overrides,
    };
};

export const fetchFlashcardSetsMetaDataByNote = async (noteId: string) => {
    return (await listData({
        listQuery: listFlashcardSetIdByNote,
        input: { noteId },
        queryName: "listFlashcardSetByNote",
    })) as ListFlashcardSetByUserNoContentQuery["listFlashcardSetByUser"]["items"];
};

export const addStandaloneFlashcardSetToTheCache = async (newFlashcardSet: FlashcardSet) => {
    const { userId, folderId, classId } = newFlashcardSet;
    const updater = (oldFlashcardSets: Record<string, FlashcardSet>) => addToCache(oldFlashcardSets, [newFlashcardSet]);

    const swrKeys = [
        resolveFlashcardSetsSWRKey({ userId }),
        ...(classId ? [resolveFlashcardSetsSWRKey({ userId, classId })] : []),
        ...(folderId ? [resolveFlashcardSetsSWRKey({ userId, folderId })] : []),
    ];

    await Promise.all(swrKeys.map(swrKey => safeLocalMutate(swrKey, updater)));
};

export const moveFlashcardSets = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
}: {
    flashcardSetIds: string[];
    userId: string | undefined;
    sourceFolderId?: string | null;
    destinationFolderId: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        userId,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: { folderId: destinationFolderId, classId: destinationClassId },
    });
};

export const trashFlashcardSets = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    sourceClassId,
}: {
    flashcardSetIds: string[];
    userId: string;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
}) => {
    await updateFlashcardSetsTrashState({
        flashcardSetIds,
        userId,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreFlashcardSets = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
}: {
    flashcardSetIds: string[];
    userId: string;
    sourceFolderId: string | null;
}) => {
    await updateFlashcardSetsTrashState({
        flashcardSetIds,
        userId,
        sourceFolderId,
        inTrash: false,
        removeFromFolder: true,
    });
};

const updateFlashcardSetsTrashState = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    flashcardSetIds: string[];
    userId: string;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        userId,
        sourceFolderId,
        sourceClassId,
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromFolder && { folderId: null }),
            ...(removeFromClass && { classId: null }),
        },
    });
};

export const updateFlashcardSetsPublicState = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    isPublic,
    password = null,
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        userId,
        sourceFolderId,
        destinationFolderId: sourceFolderId, // we're not changing the folder
        updatedFields: { public: isPublic, password },
    });
};

export const deleteFlashcardSets = async ({
    flashcardSetIds,
    userId,
}: {
    flashcardSetIds: string[];
    userId: string;
}) => {
    await safeLocalMutate(resolveFlashcardSetsSWRKey({ userId }), oldFlashcardSets =>
        objectWithout(oldFlashcardSets, ...flashcardSetIds)
    );

    await Promise.all(
        flashcardSetIds.map(flashcardSetId =>
            mutate(resolveFlashcardSetSWRKey({ flashcardSetId, userId }), null, { revalidate: false })
        )
    );

    try {
        await Promise.all(flashcardSetIds.map(flashcardSetId => callDeleteFlashcardSet(flashcardSetId, userId)));
    } catch {
        await revalidateStandaloneFlashcardSets({ flashcardSetIds, userId, sourceFolderId: null });
    }
};

export const genericUpdateFlashcardSets = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    updatedFields,
}: {
    flashcardSetIds: string[];
    userId: string;
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    updatedFields: Partial<FlashcardSet>;
}) => {
    await Promise.all([
        // mutate the source folder cache for faster UX
        mutate(
            resolveFlashcardSetsSWRKey({ userId, folderId: sourceFolderId }),
            async oldFlashcardSets => updateCache(oldFlashcardSets, flashcardSetIds, updatedFields),
            { revalidate: false }
        ),
        mutate(
            resolveFlashcardSetsSWRKey({ userId, classId: sourceClassId }),
            async oldFlashcardSets => updateCache(oldFlashcardSets, flashcardSetIds, updatedFields),
            { revalidate: false }
        ),
        ...flashcardSetIds.map(flashcardSetId =>
            mutate(
                resolveFlashcardSetSWRKey({ userId, flashcardSetId }),
                async oldFlashcardSet => (oldFlashcardSet ? { ...oldFlashcardSet, ...updatedFields } : null),
                { revalidate: false }
            )
        ),
    ]);

    try {
        const updatedFlashcardSets = await Promise.all(
            flashcardSetIds.map(flashcardSetId => saveFlashcardSet({ flashcardSetId, userId, updates: updatedFields }))
        );

        if (destinationFolderId !== sourceFolderId) {
            await safeLocalMutate(
                resolveFlashcardSetsSWRKey({ userId, folderId: destinationFolderId }),
                oldFlashcardSets => addToCache(oldFlashcardSets, updatedFlashcardSets)
            );
        }

        if (destinationClassId !== sourceClassId) {
            await safeLocalMutate(
                resolveFlashcardSetsSWRKey({ userId, classId: destinationClassId }),
                oldFlashcardSets => addToCache(oldFlashcardSets, updatedFlashcardSets)
            );

            if (sourceClassId) {
                const sourceClass = await callGetClass({ classId: sourceClassId });
                const updatedPinned = sourceClass.pinned.filter(itemId => !flashcardSetIds.includes(itemId));
                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId });
                const bookmarkedFlashcardSets = flashcardSetIds.filter(itemId => bookmarks.some(b => b.ID === itemId));
                if (bookmarkedFlashcardSets.length) {
                    const destinationClass = await callGetClass({ classId: destinationClassId });
                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedFlashcardSets];
                    await updateClass({ classId: destinationClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }
        }
    } catch {
        await revalidateStandaloneFlashcardSets({ flashcardSetIds, userId, sourceFolderId });
        await revalidateStandaloneFlashcardSets({ flashcardSetIds, userId, sourceClassId });
    }
};

export const saveFlashcardSet = async ({
    flashcardSetId,
    userId,
    updates,
}: {
    flashcardSetId: string;
    userId: string;
    updates: Partial<FlashcardSet>;
    updateInList?: boolean;
}) => {
    const newFlashcardSet = await mutate(
        resolveFlashcardSetSWRKey({ flashcardSetId, userId }),
        async (oldFlashcardSet: FlashcardSet) => {
            if (!oldFlashcardSet) oldFlashcardSet = await fetchFlashcardSet({ flashcardSetId });
            return { ...oldFlashcardSet, ...updates };
        },
        { revalidate: false }
    );

    await callUpdateFlashcardSet(flashcardSetId, stripBaseFlashcardSet(newFlashcardSet));
    await addStandaloneFlashcardSetToTheCache(newFlashcardSet);

    if (
        newFlashcardSet.flashcardSetId &&
        Object.keys(updates).some(key => SHARED_NOTE_FLASHCARD_SET_KEYS.includes(key))
    ) {
        // backend will update the SHARED_NOTE_FLASHCARD_SET_KEYS keys of the note
        // to follow those of the note, so we need to revalidate the cached note:
        await mutate(resolveNoteSWRKey({ noteId: newFlashcardSet.noteId, userId }));
    }

    return newFlashcardSet;
};

const revalidateStandaloneFlashcardSets = async ({
    flashcardSetIds,
    userId,
    sourceFolderId,
    sourceClassId,
}: {
    flashcardSetIds: string[];
    userId: string;
    sourceFolderId?: string;
    sourceClassId?: string;
}) => {
    await mutate(resolveFlashcardSetsSWRKey({ userId, folderId: sourceFolderId }));
    await mutate(resolveFlashcardSetsSWRKey({ userId, classId: sourceClassId }));
    await Promise.all(
        flashcardSetIds.map(flashcardSetId => mutate(resolveFlashcardSetSWRKey({ flashcardSetId, userId })))
    );
};

const updateCache = (
    cache: Record<string, FlashcardSet>,
    flashcardSetIds: string[],
    updatedFields: Partial<FlashcardSet>
) => {
    const newCache = { ...cache };
    flashcardSetIds.forEach(flashcardSetId => {
        newCache[flashcardSetId] = { ...newCache[flashcardSetId], ...updatedFields };
    });
    return newCache;
};

const addToCache = (cache: Record<string, FlashcardSet>, flashcardSets: FlashcardSet[]) => {
    const newCache = { ...cache };
    flashcardSets.forEach(flashcardSet => (newCache[flashcardSet.flashcardSetId] = flashcardSet));
    return newCache;
};

/**
 * Return a map of flashcardId: answerSide for when the answerSide is set to both in any learning mode
 * @returns {{[flashcardId]: FlashcardSide}}
 */
export const generateRandomAnswerSideMap = (currFlashcards: Flashcard[]) => {
    return currFlashcards.reduce(
        (accumulator, flashcard) => ({
            ...accumulator,
            [flashcard.flashcardId]: Math.random() > 0.3 ? FlashcardSide.TERM : FlashcardSide.DEFINITION,
        }),
        {}
    );
};

// just in case the fields where in md format instead of html (old flashcard sets)
export const withHtmlFields = ({ flashcards }: { flashcards: Flashcard[] }): Flashcard[] => {
    return flashcards?.map(flashcard => ({
        ...flashcard,
        term: getHtmlFromContent({ content: flashcard.term ?? "", type: "flashcard" }),
        definition: getHtmlFromContent({ content: flashcard.definition ?? "", type: "flashcard" }),
    }));
};

export const OPPOSITE_SIDE = {
    [FlashcardSide.TERM]: FlashcardSide.DEFINITION,
    [FlashcardSide.DEFINITION]: FlashcardSide.TERM,
};

/**
 * Get the opposite side of a flashcard
 * @param side
 * @returns {string}
 * @example
 * getOppositeFlashcardSide(FlashcardSide.TERM) // => 'DEFINITION'
 * getOppositeFlashcardSide(FlashcardSide.DEFINITION) // => 'TERM'
 */
export const getOppositeFlashcardSide = (side?: FlashcardSide) => OPPOSITE_SIDE[side];

export const duplicateFlashcardSets = (
    flashcardSetIds: string[],
    user: UserDetails,
    overrides: Partial<FlashcardSet>
) => {
    return Promise.all(flashcardSetIds.map(flashcardSetId => duplicateFlashcardSet(flashcardSetId, user, overrides)));
};
export const duplicateFlashcardSet = async (
    baseFlashcardSetId: string,
    user?: UserDetails,
    overrides: Partial<FlashcardSet> = {}
) => {
    const userId = user?.ID;
    if (!userId) throw new Error("User is not logged in");

    const baseFlashcardSet: FlashcardSet = await fetchFlashcardSet({
        flashcardSetId: baseFlashcardSetId,
    });

    const isOwner = baseFlashcardSet.userId === userId;

    const newFlashcardSetInput = {
        ...objectWithout(
            baseFlashcardSet,
            "views",
            "flashcardSetId",
            "trash",
            "public",
            "password",
            "noteId",
            "classPublic",
            "classId",
            "folderId",
            "mediaId",
            "draft",
            "created",
            "updated",
            "rating",
            "ratingCount"
        ),
        title: baseFlashcardSet.title + " (copy)",
        userId,
        schoolId: user.schoolId,
        grade: user.grade,
        ...(isOwner && {
            folderId: baseFlashcardSet.folderId,
            classId: baseFlashcardSet.classId,
        }),
        ...overrides,
    };

    const newFlashcardSet = await makeStandaloneFlashcardSet(newFlashcardSetInput);
    await addStandaloneFlashcardSetToTheCache(newFlashcardSet);

    if (newFlashcardSet.classId) {
        mutate(resolveClassSWRKey({ classId: newFlashcardSet.classId, userId }));
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Flashcard Set - Created", {
        userId,
        flashcardSetId: newFlashcardSet.flashcardSetId,
        duplicated: true,
    });

    return newFlashcardSet;
};

export const getMostRecentClassFlashcardSet = (classFlashcardSets?: Record<string, FlashcardSet> | null) => {
    if (!classFlashcardSets) return null;

    return Object.values(classFlashcardSets || {})
        .filter(flashcardSet => !flashcardSet.draft && flashcardSet.sections?.length !== 0)
        .sort(
            (a, b) =>
                Number(b?.addedAt || b.updated?.toString() || "0") - Number(a?.addedAt || a.updated?.toString() || "0")
        )?.[0];
};
