import { platform } from "@/platform";
import { fromEntries, retry } from "@/utils/genericUtils";
import { V6ClientSSRCookies } from "@aws-amplify/api-graphql";
import { generateClient } from "aws-amplify/api";
import { prettyPrint } from "./stringUtils";

export const now = () => Math.round(Date.now() / 1000);

type DebugLevel = "NONE" | "MILD" | "VERBOSE";
const DEBUG_LEVEL: DebugLevel = "MILD";

export enum GRAPHQL_AUTH_MODE {
    "API_KEY" = "apiKey",
    "AWS_IAM" = "iam",
    "OPENID_CONNECT" = "oidc",
    "AMAZON_COGNITO_USER_POOLS" = "userPool",
    "AWS_LAMBDA" = "lambda",
}

// Clean out the __typename field that appsync produces
const clearTypenames = <T extends GraphQLInput>(variables: T): T => {
    return {
        ...variables,
        input: deepClearTypenames(variables.input),
    };
};

// Clean out the __typename field that appsync produces
const deepClearTypenames = obj => {
    if (typeof obj !== "object") {
        return obj;
    }
    const newInput = { ...obj };
    delete newInput["__typename"];
    return fromEntries(
        Object.entries(newInput).map(([key, val]) => {
            if (Array.isArray(val) && val !== null) {
                // check if the object is an array
                return [key, val.map(x => deepClearTypenames(x))];
            } else if (typeof val === "object" && val !== null) {
                return [key, deepClearTypenames(val)];
            } else {
                return [key, val];
            }
        })
    );
};

const normalClient = generateClient();

export type ServerClientWithCookies = {
    client: V6ClientSSRCookies;
    sourceIp?: string;
    auth:
        | {
              authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
              authToken: string;
          }
        | {
              authMode: GRAPHQL_AUTH_MODE.AWS_IAM;
          };
};

type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
};

type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

type GraphQLInput = {
    input?: {
        [key: string]: string | number | boolean | object;
    };
};

type GraphQLListInput = {
    input?: {
        limit?: number;
        nextToken?: string;
        [key: string]: string | number | boolean | object;
    };
};

type GraphQLListOutput = {
    [key: string]: {
        items?: object[];
        nextToken?: string;
    };
};

export const client = {
    query: async <T extends GraphQLInput, K>({
        query,
        variables,
        serverClient = null,
    }: {
        query: GeneratedQuery<T, K>;
        // extends the input type to include the "input" key
        variables: T;
        serverClient?: ServerClientWithCookies;
    }) => {
        const { log, report } = await platform.analytics.logging();
        const queryName = query.split("(")[0].split(" ")[1];

        if (DEBUG_LEVEL !== "NONE") {
            log(
                `[QUERY - ${serverClient ? "Server" : "Client"}]`,
                queryName,
                DEBUG_LEVEL === "VERBOSE" ? variables.input : ""
            );
        }

        if (serverClient) {
            try {
                return await serverClient.client.graphql(
                    {
                        query,
                        variables,
                        ...serverClient.auth,
                    },
                    serverClient.sourceIp
                        ? {
                              cd2f8270d71f4dba8f0a58390132b149: serverClient.sourceIp,
                          }
                        : {}
                );
            } catch (e) {
                report(e, queryName);
                throw e;
            }
        } else {
            try {
                return await normalClient.graphql({
                    query,
                    variables,
                });
            } catch (e) {
                report(e, queryName);
                throw e;
            }
        }
    },
    mutate: async <T extends GraphQLInput, K>({
        mutation,
        variables: _variables,
        serverClient = null,
    }: {
        mutation: GeneratedMutation<T, K>;
        variables: T;
        serverClient?: ServerClientWithCookies;
    }) => {
        const { log, report } = await platform.analytics.logging();
        const mutationName = mutation.split("(")[0].split(" ")[1];
        const variables = clearTypenames(_variables);

        if (DEBUG_LEVEL !== "NONE") {
            log(
                `[MUTATE - ${serverClient ? "Server" : "Client"}]`,
                mutationName,
                DEBUG_LEVEL === "VERBOSE" ? variables.input : ""
            );
        }

        if (serverClient) {
            try {
                return await serverClient.client.graphql(
                    {
                        query: mutation,
                        variables,
                        ...serverClient.auth,
                    },
                    serverClient.sourceIp
                        ? {
                              cd2f8270d71f4dba8f0a58390132b149: serverClient.sourceIp,
                          }
                        : {}
                );
            } catch (e) {
                report(e, mutationName);
                throw e;
            }
        } else {
            try {
                return await normalClient.graphql({
                    query: mutation,
                    variables,
                });
            } catch (e) {
                report(e, mutationName);
                throw e;
            }
        }
    },
};

/***
 * Fetches items from an AppSync list query
 * @param listQuery: The GraphQl query
 * @param queryName: The name of the graphql query
 * @param input: Any input parameters
 * @param ignoreTrashed: If this is set to true, items with trash=true will be ignored
 * @param serverClient: server client to use from server side
 */
export const listData = <T extends GraphQLInput, K extends GraphQLListOutput>({
    listQuery,
    queryName,
    input = {},
    ignoreTrashed = true,
    serverClient,
}: {
    listQuery: GeneratedQuery<T, K>;
    // TODO: change to dataExtractor function
    queryName: string;
    input: T["input"];
    ignoreTrashed?: boolean;
    serverClient?: ServerClientWithCookies;
}) => {
    return retry(async () => {
        let items = await fetchListItems({ query: listQuery, name: queryName, input, serverClient });
        if (ignoreTrashed) items = items.filter(({ trash }) => !trash);

        return items;
    });
};

/***
 * Fetches grouped items from an AppSync list query
 * @param listQuery: The GraphQl query
 * @param queryName: The name of the graphql query
 * @param input: Any input parameters
 * @param groupingKey: The key containing an item's ID; input will be grouped by this ID
 * @param ignoreTrashed: If this is set to true, items with trash=true will be ignored
 * @param serverClient: server client to use from server side
 */
export const listGroupedData = <T extends GraphQLListInput, K extends GraphQLListOutput>({
    listQuery,
    queryName,
    input = {},
    groupingKey,
    ignoreTrashed = true,
    serverClient,
}: {
    listQuery: GeneratedQuery<T, K>;
    queryName: string;
    input: T["input"];
    groupingKey: string;
    ignoreTrashed?: boolean;
    serverClient?: ServerClientWithCookies;
}) => {
    return retry(async () => {
        let items = await fetchListItems({ query: listQuery, name: queryName, input, serverClient });
        if (ignoreTrashed) items = items.filter(({ trash }) => !trash);

        return groupItems(items, groupingKey);
    });
};

const fetchListItems = async <T extends GraphQLListInput, K extends GraphQLListOutput>({
    query,
    name,
    input,
    serverClient,
}: {
    query: GeneratedQuery<T, K>;
    name: string;
    input: T["input"];
    serverClient?: ServerClientWithCookies;
}) => {
    throwIfInputIsNotValid(input, name);

    const result = [];
    let nextToken = null;
    do {
        await client
            .query({
                query: query,
                variables: { input: { ...input, limit: 1000, ...(nextToken && { nextToken }) } as T["input"] } as T,
                serverClient,
            })
            .then(({ data }) => {
                nextToken = data[name].nextToken;
                result.push(...data[name].items);
            })
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, name, input);
                throw error;
            });
    } while (nextToken);

    return result;
};

export const groupItems = <ItemType>(items: ItemType[], groupingKey: string) => {
    return items.reduce(
        (acc, item) => ({
            ...acc,
            [item[groupingKey]]: item,
        }),
        {}
    );
};

const throwIfInputIsNotValid = (input, queryName) => {
    for (const [key, value] of Object.entries(input)) {
        if (value === null || value === undefined) {
            throw new Error(
                `Input to ${queryName} contains null/undefined value for ${key} with input: ${prettyPrint(input)}`
            );
        }
    }
};
