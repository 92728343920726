import { MarkSpec } from "../../../pm/model";
import Mark from "../Mark";
import { markRule } from "../../rules";

export class OrangeHighlight extends Mark {
    get name() {
        return "highlight_orange";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "red",
                },
            },
            excludes: "highlight",
            group: "highlight",
            parseDOM: [
                {
                    tag: "mark",
                    getAttrs: node => node.getAttribute("class") === "orange highlight",
                },
                {
                    style: "background-color",
                    getAttrs: value => !!value && value === "orange",
                },
            ],
            toDOM: () => [
                "mark",
                {
                    "data-color": "red",
                },
            ],
        };
    }

    get rulePlugins() {
        return [markRule({ delim: "@@", mark: "highlight_orange" })];
    }

    get toMarkdown() {
        return {
            open: "@@",
            close: "@@",
            mixable: true,
            expelEnclosingWhitespace: true,
        };
    }

    parseMarkdown() {
        return { mark: "highlight_orange" };
    }
}
