import { MarkSpec } from "../../../pm/model";
import Mark from "../Mark";
import { markRule } from "../../rules";

export class RedBackground extends Mark {
    get name() {
        return "background_red";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "#FFDBDB",
                },
            },
            excludes: "background",
            group: "background",
            parseDOM: [
                {
                    tag: `mark[data-type="background-red"]`,
                },
            ],
            toDOM: () => [
                "mark",
                {
                    "data-color": "#FFDBDB",
                    style: "background-color: #FFDBDB; color: inherit",
                },
            ],
        };
    }

    get rulePlugins() {
        return [markRule({ delim: "[[", mark: "background_red" })];
    }

    get toMarkdown() {
        return {
            open: "[[",
            close: "[[",
            mixable: true,
            expelEnclosingWhitespace: true,
        };
    }

    parseMarkdown() {
        return { mark: "background_red" };
    }
}
