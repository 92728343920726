import packageInfo from "@/package.json";

export const clientId = "82462445097-4dq5q3rq6beom77c5dos3mbltaa30cf8.apps.googleusercontent.com";
export const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
export const DRIVE_SCOPE = ["https://www.googleapis.com/auth/drive.readonly"];
export const CLASSROOM_SCOPE = [
    "https://www.googleapis.com/auth/classroom.courses.readonly",
    "https://www.googleapis.com/auth/classroom.rosters.readonly",
    "https://www.googleapis.com/auth/classroom.profile.emails",
];

export const GA4_MEASUREMENT_ID = "G-LMQQWFPDKY";
export const GOOGLE_ADS_MEASUREMENT_ID = "AW-483417008";
export const GA4_ADS_MEASUREMENT_ID = "G-6FQRHSG3RT";
export const GOOGLE_API_KEY = "AIzaSyDSz2NZR27oWchYXaa0k_JsDtH4-ExftNA";
export const enableAnalytics = true;

export const BASE_URL = !__DEV__ ? "https://test.knowt.com" : "http://localhost:3000";
export const ASSETS_URL = "https://s3.amazonaws.com/knowt.com";
export const DEVELOPMENT_BUILD = !__DEV__ && packageInfo.development;

export const redirectSignIn = `${BASE_URL}/`;
export const redirectSignOut = `${BASE_URL}/logout`;

export const oauth = {
    domain: "authdev.knowt.com",
    scopes: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn,
    redirectSignOut,
    responseType: "code",
};

export const STRIPE_PUBLISHABLE_KEY =
    "pk_test_51MHIDPKXJM8qxW7R72TcJZ15JXYLXkiZAMmMd4rUPbAPFHd45zpvWAlhwFLGdbp27QhOizvtURBGiE4snHgNRreG00GeWsxqj0";

export const CHROME_EXTENSION_URL =
    "https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm";
