import { bookmark, unbookmark } from "@/graphql/mutations";
import { listBookmarkByUserId } from "@/graphql/queries";
import { client, listData, ServerClientWithCookies } from "@/utils/SyncUtils";
import { platform } from "@/platform";
import { Bookmark } from "@/graphql/schema";

/**
 * Update the bookmarking state of a document
 * @param {Boolean} shouldBookmark - the new bookmarking state
 * @param {string} ID - the concerned document ID
 * @param {"NOTE" | "QUIZ" | "FLASHCARDSET"} type the type of document type
 * @return {Promise<*>}
 */
export const updateBookmarking = async (shouldBookmark, ID, type = "NOTE") => {
    return await client
        .mutate({
            // TODO: figure out conditional mutation
            mutation: shouldBookmark ? bookmark : unbookmark,
            variables: {
                input: {
                    ID,
                    type,
                },
            },
        })
        .then(async result => {
            const mixpanel = await platform.analytics.mixpanel();
            mixpanel.track(shouldBookmark ? "Bookmark" : "Unbookmark", { ID, type, value: shouldBookmark });
            return result;
        });
};

export const fetchBookmarks = async ({
    userId,
    serverClient,
}: {
    userId: string | null | undefined;
    serverClient?: ServerClientWithCookies;
}) => {
    if (!userId) return null;

    try {
        return (await listData({
            listQuery: listBookmarkByUserId,
            input: { userId },
            queryName: "listBookmarkByUserId",
            serverClient,
        })) as Bookmark[];
    } catch (e) {
        return null;
    }
};
