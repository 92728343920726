import { createClass, updateClass, joinClass, leaveClass, deleteClass } from "@/graphql/mutations";
import { Class, CreateClassInput, UpdateClassInput } from "@knowt/syncing/graphql/schema";
import { ServerClientWithCookies, client, listGroupedData, now } from "@/utils/SyncUtils";
import { retry } from "@/utils/genericUtils";
import { platform } from "@/platform";
import { getClass, listClasses, listEnrolledClasses } from "@/graphql/queries";

export const callCreateClass = async (initialFields: CreateClassInput) => {
    const course = await retry(async () => {
        const input = { ...initialFields };

        return client
            .mutate({
                mutation: createClass,
                variables: { input: input },
            })
            .then(({ data }) => data.createClass);
    }, 1);

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Class - Created", { classId: course.classId });

    return course;
};

export const callUpdateClass = async (initialFields: Omit<UpdateClassInput, "updated">) => {
    const course = await retry(async () => {
        const input = { updated: now().toString(), ...initialFields };

        return client
            .mutate({
                mutation: updateClass,
                variables: { input: input },
            })
            .then(({ data }) => data.updateClass);
    });

    return course;
};

export const callGetClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return await client
        .query({
            query: getClass,
            variables: {
                input: {
                    classId: classId,
                },
            },
            serverClient,
        })
        .then(({ data }) => data.getClass);
};

export const callListClasses = async ({ serverClient }: { serverClient?: ServerClientWithCookies }) => {
    return (await listGroupedData({
        listQuery: listClasses,
        groupingKey: "classId",
        input: {},
        queryName: "listClasses",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Class>;
};

export const callListEnrolledClasses = async ({ serverClient }: { serverClient?: ServerClientWithCookies }) => {
    return (await listGroupedData({
        listQuery: listEnrolledClasses,
        groupingKey: "classId",
        input: {},
        queryName: "listEnrolledClasses",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Class>;
};

export const callJoinClass = async ({
    classId,
    sections,
    serverClient,
}: {
    classId: string;
    sections: string[];
    serverClient?: ServerClientWithCookies;
}) => {
    return client
        .mutate({
            mutation: joinClass,
            variables: {
                input: { classId, sections },
            },
            serverClient,
        })
        .then(({ data }) => data.joinClass);
};

export const callLeaveClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return client
        .mutate({
            mutation: leaveClass,
            variables: {
                input: { classId },
            },
            serverClient,
        })
        .then(({ data }) => data.leaveClass);
};

export const callDeleteClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return client
        .mutate({
            mutation: deleteClass,
            variables: {
                input: { classId },
            },
            serverClient,
        })
        .then(({ data }) => data.deleteClass);
};
