import { emojiRule } from "../rules";
import Node from "./Node";

export default class Emoji extends Node {
    get name() {
        return "emoji";
    }

    get schema() {
        return {
            attrs: {
                style: {
                    default: "",
                },
                "data-name": {
                    default: undefined,
                },
            },
            inline: true,
            content: "text*",
            marks: "",
            group: "inline",
            selectable: true,
            draggable: true,
            parseDOM: [
                {
                    tag: "span.emoji",
                    preserveWhitespace: "full",
                    getAttrs: (dom: HTMLDivElement) => ({
                        "data-name": dom.dataset.name,
                    }),
                },
            ],
            toDOM: node => {
                return [
                    "span",
                    {
                        "data-type": "emoji",
                        "data-name": node.attrs["data-name"],
                    },
                ];
            },
        };
    }

    get rulePlugins() {
        return [emojiRule];
    }

    toMarkdown(state, node) {
        const name = node.attrs["data-name"];
        if (name) {
            state.write(`:${name}:`);
        }
    }

    parseMarkdown() {
        return {
            node: "emoji",
            getAttrs: tok => {
                return { "data-name": tok.markup.trim() };
            },
        };
    }
}
