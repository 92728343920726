import { underlineRule } from "../rules";
import Mark from "./Mark";

export default class Underline extends Mark {
    get name() {
        return "underline";
    }

    get schema() {
        return {
            parseDOM: [
                { tag: "u" },
                { tag: "underline" },
                {
                    style: "text-decoration",
                    getAttrs: value => value === "underline",
                },
            ],
            toDOM: () => ["u", 0],
        };
    }

    get rulePlugins() {
        return [underlineRule];
    }

    get toMarkdown() {
        return {
            open: "__",
            close: "__",
            mixable: true,
            expelEnclosingWhitespace: true,
            escape: false,
        };
    }

    parseMarkdown() {
        return { mark: "underline" };
    }
}
