import { fetchGetJSON } from "@/fetchFunctions/fetchWrappers";
import { makeStandaloneFlashcardSet, resolveFlashcardSetSWRKey } from "@/hooks/flashcards/utils";
import {
    callDeleteMedia,
    callDuplicateMedia,
    callEditMediaChapter,
    callGenerateMediaTranscription,
    callGetMedia,
    callUpdateMedia,
} from "@/hooks/media/graphqlUtils";
import { createNewNote, resolveNoteSWRKey } from "@/hooks/notes/utils";
import { platform } from "@/platform";
import { Embedding, Transcript, Utterance } from "@/types/common";
import { Flashcard, Media, MediaChapterInput, MediaType, UserDetails } from "@knowt/syncing/graphql/schema";
import { now } from "@/utils/SyncUtils";
import { objectWithout } from "@/utils/dataCleaning";
import { fileTypeFromMimeType } from "@/utils/fileTypeUtils";
import { parseStringTimestampToFloat, secondsToLargerUnit } from "@/utils/dateTimeUtils";
import { retry, wait } from "@/utils/genericUtils";
import { uploadMediaToS3 } from "@/utils/s3";
import { Dispatch, SetStateAction } from "react";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { AV_BUCKET, PDF_BUCKET } from "./constants";
import { updateClass } from "@/hooks/classes/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { fetchBookmarks } from "@/hooks/bookmarks/utils";

export const resolveMediaSWRKey = ({ mediaId, isEnabled = true }: { mediaId?: string; isEnabled?: boolean }) => {
    return isEnabled && mediaId ? ["media", mediaId] : null;
};

const getStaticUrlSection = ({ media }: { media: Media }) => {
    const bucket = media?.bucket;
    const mediaId = media?.mediaId;

    return `https://${bucket}.s3.amazonaws.com/${mediaId}`;
};

export const resolveListMediaSWRKey = ({
    userId,
    folderId = null,
    classId = null,
    isEnabled = true,
}: {
    userId: string;
    folderId?: string;
    classId?: string;
    isEnabled?: boolean;
}) => isEnabled && userId && ["media-by-userId", userId, folderId, classId];

export const resolveMediaChaptersSWRKey = ({ media }: { media: Media }) => {
    const chaptersUrl = media && [MediaType.AUDIO, MediaType.VIDEO].includes(media.type) && getChaptersUrl({ media });
    return ["media-chapters", chaptersUrl];
};

export const getMediaUrl = ({ media }: { media: Media }) => {
    const fileType = media?.fileType?.toLowerCase();

    return `${getStaticUrlSection({ media })}.${fileType}`;
};

export const getEmbeddingsUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-embeddings.json`;
};

export const getSuggestedQuestionsUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-questions.json`;
};

export const getSpriteSheetVTTUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-spritesheet.vtt`;
};

export const getTranscriptUrl = ({ media, preview }: { media: Media; preview?: boolean }) => {
    if (preview) {
        return `${getStaticUrlSection({ media })}-preview.json`;
    }

    return `${getStaticUrlSection({ media })}-transcription.json`;
};

export const getUtterancesUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-utterances.json`;
};

export const getSubtitlesUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-subtitles.vtt`;
};

export const getChaptersUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-chapters.vtt`;
};

export const fetchTranscript = async ({ transcriptUrl }: { transcriptUrl: string }): Promise<Transcript> => {
    if (!transcriptUrl) return null;
    return (await fetchGetJSON(transcriptUrl)).data;
};

export const fetchSuggestedQuestions = async ({
    suggestedQuestionsUrl,
}: {
    suggestedQuestionsUrl: string;
}): Promise<Transcript> => {
    if (!suggestedQuestionsUrl) return null;

    return await retry(
        async () => {
            return (await fetchGetJSON(suggestedQuestionsUrl)).data;
        },
        12,
        5000
    );
};

export const fetchUtterances = async ({ utterancesUrl }: { utterancesUrl: string }): Promise<Utterance> => {
    if (!utterancesUrl) return null;

    return await retry(
        async () => {
            return (await fetchGetJSON(utterancesUrl)).data;
        },
        12,
        5000
    );
};

export const fetchEmbeddings = async ({ embeddingsUrl }: { embeddingsUrl: string }): Promise<Embedding[]> => {
    if (!embeddingsUrl) return null;

    return (await fetchGetJSON(embeddingsUrl)).data;
};

export const getLowerResolutionVideoUrl = ({ media, resolution }: { media: Media; resolution: string }) => {
    return `https://${media.bucket}-smaller.s3.amazonaws.com/${media.mediaId}-${resolution}.mp4`;
};

export const convertChaptersVttToObject = (chaptersVtt: string) => {
    if (chaptersVtt.includes("<Code>NoSuchKey</Code>")) return null;

    const lines = chaptersVtt
        .trim()
        .split("\n")
        .filter(line => !line.includes("WEBVTT"));

    const chapters: MediaChapterInput[] = [];
    let currentChapter: MediaChapterInput = { start: null, end: null, title: null };

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.includes("-->")) {
            //if there are untitled chapters
            if (currentChapter.start !== null && currentChapter.end !== null) {
                chapters.push({ title: null, ...currentChapter });
                currentChapter = { start: null, end: null, title: null };
            }
            const [start, end] = line.split(" --> ");
            currentChapter["start"] = parseStringTimestampToFloat(start);
            currentChapter["end"] = parseStringTimestampToFloat(end);
        } else if (line) {
            currentChapter["title"] = line;
            chapters.push({ ...currentChapter });
            currentChapter = { start: null, end: null, title: null };
        }
    }

    return chapters;
};

export const fetchChapters = async ({ chaptersUrl }: { chaptersUrl: string }): Promise<MediaChapterInput[]> => {
    if (!chaptersUrl || chaptersUrl.includes("undefined")) return null;

    return await retry(
        async () => {
            return await fetch(chaptersUrl).then(async res => convertChaptersVttToObject(await res.text()));
        },
        12,
        5000
    );
};

export const fetchMediaWithRetries = async ({
    maxTry,
    delay,
    mediaId,
    retryCount = 0,
}: {
    maxTry: number;
    delay: number;
    mediaId: string;
    retryCount?: number;
}) => {
    const media = await callGetMedia({ mediaId: mediaId });
    if (media) return media;

    if (retryCount < maxTry) {
        await wait(delay);
        return fetchMediaWithRetries({ mediaId, maxTry, delay, retryCount: retryCount + 1 });
    } else {
        throw new Error(`Unable to fetch media after ${maxTry} retries.`);
    }
};

export const waitForMediaEntryCreationOnUpload = async ({ mediaId }) => {
    try {
        // poll for 2 minutes
        await fetchMediaWithRetries({ mediaId, maxTry: 30, delay: 1000 });
        return { mediaCreated: true };
    } catch {
        return { mediaCreated: false };
    }
};

export const verifyS3Presence = async ({ media }: { media: Media }) => {
    return await retry(
        async () => {
            return fetch(getMediaUrl({ media }));
        },
        12,
        5000
    );
};

export const getIsVideoOrAudio = (extension: string) => {
    return ["mp4", "mov", "m4a", "mkv", "m4a", "mp3", "wav", "ogg", "webm"].includes(extension);
};

export const uploadFile = async ({
    fileBlob,
    userId,
    folderId,
    classId,
    setUploadProgress,
    setUploadRemainingTime,
    setCurrentUploadJob,
    contentType,
}: {
    fileBlob: Blob;
    userId?: string;
    folderId?: string;
    classId?: string;
    setUploadProgress: Dispatch<SetStateAction<number>>;
    setCurrentUploadJob: Dispatch<SetStateAction<Promise<unknown>>>;
    setUploadRemainingTime: Dispatch<SetStateAction<string>>;
    contentType: string;
}) => {
    try {
        const id = uuidv4();
        const fileName = `${id}.${fileTypeFromMimeType(contentType)}`;
        const isVideoOrAudio = getIsVideoOrAudio(fileTypeFromMimeType(contentType));

        await uploadMediaToS3({
            userId,
            folderId,
            classId,
            s3BucketName: isVideoOrAudio ? AV_BUCKET : PDF_BUCKET,
            media: fileBlob,
            setCurrentUploadJob,
            fileName,
            contentType,
            progressCallback: ({ transferredBytes, totalBytes }) => {
                setUploadProgress(Math.round((transferredBytes * 100) / totalBytes));
            },
            remainingTimeCallback: remainingTimeInSeconds => {
                const { value, unit } = secondsToLargerUnit(remainingTimeInSeconds);
                const unitString = unit === "second" ? "s" : unit === "minute" ? "m" : "h";
                setUploadRemainingTime(value + unitString);
            },
        });

        return {
            id,
            bucket: isVideoOrAudio ? AV_BUCKET : PDF_BUCKET,
            contentType,
            cancelled: false,
        };
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "uploading_file", { userId, folderId, classId, contentType });
        return { cancelled: true };
    }
};

const updateCache = (cache: Record<string, Media>, mediaIds: string[], updatedFields: Partial<Media>) => {
    const newCache = { ...cache };
    mediaIds.forEach(mediaId => (newCache[mediaId] = { ...newCache[mediaId], ...updatedFields }));
    return newCache;
};

const addToCache = (cache: Record<string, Media>, medias: Media[]) => {
    const newCache = { ...cache };
    medias.forEach(media => (newCache[media.mediaId] = media));
    return newCache;
};

export const trashMedias = async ({
    mediaIds,
    userId,
    sourceFolderId,
    sourceClassId,
}: {
    mediaIds: string[];
    userId: string | undefined;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
}) => {
    await updateMediaTrashState({
        mediaIds,
        userId,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreMedias = async ({
    mediaIds,
    userId,
    sourceFolderId,
}: {
    mediaIds: string[];
    userId: string | undefined;
    sourceFolderId: string | null;
}) => {
    await updateMediaTrashState({ mediaIds, userId, sourceFolderId, inTrash: false, removeFromFolder: true });
};

const updateMediaTrashState = async ({
    mediaIds,
    userId,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    mediaIds: string[];
    userId: string | undefined;
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateMedias({
        mediaIds,
        userId,
        sourceFolderId,
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        sourceClassId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromFolder && { folderId: null }),
            ...(removeFromClass && { classId: null }),
        },
    });
};

export const moveMedias = async ({
    mediaIds,
    userId,
    sourceFolderId = null,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
}: {
    mediaIds: string[];
    userId: string | undefined;
    sourceFolderId: string | null | undefined;
    destinationFolderId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
}) => {
    await genericUpdateMedias({
        mediaIds,
        userId,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: { folderId: destinationFolderId, classId: destinationClassId },
    });
};

export const genericUpdateMedias = async ({
    userId,
    mediaIds,
    updatedFields,
    sourceFolderId = null,
    destinationFolderId = null,
    sourceClassId = null,
    destinationClassId = null,
}: {
    userId: string | undefined;
    mediaIds: string[];
    updatedFields: Partial<Media>;
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    if (!userId) return;

    await Promise.all([
        mutate(
            resolveListMediaSWRKey({ userId, folderId: sourceFolderId }),
            async oldMedias => updateCache(oldMedias, mediaIds, updatedFields),
            { revalidate: false }
        ),
        mutate(
            resolveListMediaSWRKey({ userId, classId: sourceClassId }),
            async oldMedias => updateCache(oldMedias, mediaIds, updatedFields),
            { revalidate: false }
        ),
    ]);

    try {
        const updatedMedias = await Promise.all(
            mediaIds.map(mediaId => saveMedia({ mediaId, userId, updates: updatedFields }))
        );

        if (sourceFolderId !== destinationFolderId) {
            await mutate(
                resolveListMediaSWRKey({ userId, folderId: destinationFolderId }),
                oldMedias => addToCache(oldMedias, updatedMedias),
                { revalidate: false }
            );
        }

        if (sourceClassId !== destinationClassId) {
            await mutate(
                resolveListMediaSWRKey({ userId, classId: destinationClassId }),
                oldMedias => addToCache(oldMedias, updatedMedias),
                { revalidate: false }
            );

            if (sourceClassId) {
                const sourceClass = await callGetClass({ classId: sourceClassId });
                const updatedPinned = sourceClass.pinned.filter(itemId => !mediaIds.includes(itemId));
                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId });
                const bookmarkedMedias = mediaIds.filter(itemId => bookmarks.some(b => b.ID === itemId));
                if (bookmarkedMedias.length) {
                    const destinationClass = await callGetClass({ classId: destinationClassId });
                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedMedias];
                    await updateClass({ classId: destinationClassId, userId, pinned: updatedPinned }, { ID: userId });
                }
            }
        }
    } catch {
        // TODO: we should revalidate per class/folder, no?
        await revalidateMedias({ userId });
    }
};

const saveMedia = async ({
    mediaId,
    userId,
    updates: _updates,
}: {
    mediaId: string;
    userId: string;
    updates: Partial<Media>;
}) => {
    const updates = { ..._updates, updated: now().toString() };

    await mutate(
        ["media", mediaId],
        async oldMedia => {
            if (!oldMedia) oldMedia = await callGetMedia({ mediaId });
            return { ...oldMedia, ...updates };
        },
        { revalidate: false }
    );

    const updatedMedia = await callUpdateMedia({ mediaId, userId, mediaInput: updates });

    await syncAttachedItemsCacheIfNeeded(updates, updatedMedia);

    return updatedMedia;
};

const syncAttachedItemsCacheIfNeeded = async (updates: Partial<Media>, updatedMedia: Media) => {
    const willBackendUpdateAttachedItems = ["public, password"].some(field => updates[field] !== undefined);

    if (willBackendUpdateAttachedItems) {
        const { userId, noteId, flashcardSetId } = updatedMedia;
        if (noteId) await mutate(resolveNoteSWRKey({ noteId, userId }));
        if (flashcardSetId) await mutate(resolveFlashcardSetSWRKey({ flashcardSetId, userId }));
    }
};

export const deleteMedias = async ({
    mediaIds,
    userId,
    folderId = null,
}: {
    mediaIds: string[];
    userId: string | undefined;
    folderId?: string | undefined;
}) => {
    if (!userId) return;

    await mutate(resolveListMediaSWRKey({ userId, folderId }), oldMedias => objectWithout(oldMedias, ...mediaIds), {
        revalidate: false,
    });

    try {
        await Promise.all(mediaIds.map(mediaId => callDeleteMedia({ mediaId, userId })));
    } catch {
        await revalidateMedias({ userId });
    }
};

export const revalidateMedias = async ({ userId, folderId = null }: { userId: string; folderId?: string | null }) => {
    await mutate(resolveListMediaSWRKey({ userId, folderId }));
};

const revalidateMediaChapters = async ({ media }: { media: Media }) => {
    await mutate(resolveMediaChaptersSWRKey({ media }));
};

export const editMediaChapter = async ({ mediaId, chapterIndex, chapterTitle }) => {
    const media = await callGetMedia({ mediaId: mediaId });

    await mutate(
        resolveMediaChaptersSWRKey({ media }),
        oldChapters => {
            const newChapters = [...oldChapters];
            newChapters[chapterIndex] = { ...newChapters[chapterIndex], title: chapterTitle };
            return newChapters;
        },
        { revalidate: false }
    );

    try {
        await callEditMediaChapter({ mediaId, chapterIndex, chapterTitle });
    } catch {
        await revalidateMediaChapters({ media });
    }
};

export const createMediaNote = async ({ media, user }: { media: Media; user: UserDetails | undefined }) => {
    if (!user) throw new Error("Not Logged in");
    if (media.noteId) throw new Error("note already exists");

    const newNoteId = uuidv4();
    await mutate(["media", media.mediaId], data => ({ ...data, noteId: newNoteId }), { revalidate: false });

    await createNewNote(
        {
            noteId: newNoteId,
            mediaId: media.mediaId,
            public: media.public,
            password: media.password,
            flashcardSetId: media.flashcardSetId,
            title: media.title,
        },
        user
    );

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Note - Created", {
        mediaId: media.mediaId,
        flashcardSetId: media.flashcardSetId,
        noteId: newNoteId,
        mediaType: media.type,
    });

    return await callUpdateMedia({
        mediaId: media.mediaId,
        userId: user.ID,
        mediaInput: { noteId: newNoteId },
    }).catch(async error => {
        await mutate(["media", media.mediaId]);
        throw error;
    });
};

export const createMediaFlashcardSet = async ({
    media,
    flashcards,
    userId,
}: {
    media: Media;
    flashcards?: Flashcard[];
    userId: string | undefined;
}) => {
    if (!userId) throw new Error("Not Logged in");
    if (media.flashcardSetId) throw new Error("flashcard set already exists");

    const newFlashcardSetId = uuidv4();
    await mutate(
        ["media", media.mediaId],
        data => ({
            ...data,
            flashcardSetId: newFlashcardSetId,
        }),
        { revalidate: false }
    );

    await makeStandaloneFlashcardSet({
        userId,
        flashcardSetId: newFlashcardSetId,
        flashcards: flashcards,
        title: media?.title,
        mediaId: media?.mediaId,
        draft: false,
        public: media.public,
        password: media.password,
        noteId: media.noteId,
    });

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Flashcard Set - Created", {
        mediaId: media.mediaId,
        flashcardSetId: newFlashcardSetId,
        noteId: media.noteId,
        mediaType: media.type,
    });

    return await callUpdateMedia({
        mediaId: media.mediaId,
        userId,
        mediaInput: { flashcardSetId: newFlashcardSetId },
    }).catch(async error => {
        await mutate(["media", media.mediaId]);
        throw error;
    });
};

export const createMediaTranscript = async ({ mediaId }) => {
    callGenerateMediaTranscription({ mediaId });

    //update cache
    await mutate(
        ["media", mediaId],
        oldData => ({
            ...oldData,
            updated: now().toString(),
            //so we show the generating indicators
            transcript: null,
        }),
        { revalidate: false }
    ); // Set the optimistic data
};

export const duplicateMedias = async (mediaIds: string[], userId: string, folderId?: string) => {
    await Promise.all(mediaIds.map(mediaId => duplicateMedia({ baseMediaId: mediaId, userId, folderId })));
};

export const duplicateMedia = async ({
    baseMediaId,
    userId,
    folderId,
    overrides,
}: {
    baseMediaId: string;
    userId: string;
    folderId?: string;
    overrides?: Partial<Media>;
}) => {
    const baseMedia = await callGetMedia({ mediaId: baseMediaId });
    const isOwner = baseMedia.userId === userId;

    const newMediaInput = {
        ...objectWithout(baseMedia, "mediaId", "flashcardSetId", "noteId", "rating", "classId", "folderId"),
        mediaId: uuidv4(),
        title: baseMedia.title + " (copy)",
        userId,
        created: now().toString(),
        updated: now().toString(),
        public: baseMedia.public,
        ...(isOwner && { folderId: baseMedia.folderId, classId: baseMedia.classId }),
        ...overrides,
    };

    await mutate(
        resolveListMediaSWRKey({ userId, folderId, classId: newMediaInput.classId }),
        oldMedias => ({ ...oldMedias, [newMediaInput.mediaId]: newMediaInput }),
        { revalidate: false }
    );

    await mutate(["media", newMediaInput.mediaId], newMediaInput, { revalidate: false });

    return callDuplicateMedia({ baseMediaId, newMediaId: newMediaInput.mediaId, folderId });
};

export const getContentForSelectedPdfPages = (newSelectedPDFPages: number[], transcript: Transcript) => {
    const uniquePages = [...new Set(newSelectedPDFPages)].sort((a, b) => a - b);

    if (transcript === undefined || (transcript && transcript?.length === 0)) {
        return;
    }

    const selectedPDFPageContentObjects = uniquePages.map(page => transcript[page - 1]);

    const stringifiedSelectedPDFPageContent = selectedPDFPageContentObjects
        ?.map(({ content }, index) => `\nPage ${uniquePages[index]}:\n${content}\n`)
        .join("\n")
        .trim();

    return stringifiedSelectedPDFPageContent;
};
