import { MarkSpec } from "../../pm/model";
import Extension from "../Extension";

export default abstract class Mark extends Extension {
    get type() {
        return "mark";
    }

    abstract get schema(): MarkSpec;

    get markdownToken(): string {
        return "";
    }

    get toMarkdown(): Record<string, any> {
        return {};
    }

    parseMarkdown() {
        return {};
    }
}
