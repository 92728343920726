import Node from "./Node";

export default class TableHeadCell extends Node {
    get name() {
        return "th";
    }

    get schema() {
        return {
            content: "paragraph+",
            tableRole: "header_cell",
            isolating: true,
            parseDOM: [{ tag: "th" }],
            toDOM(node) {
                return ["th", node.attrs.alignment ? { style: `text-align: ${node.attrs.alignment}` } : {}, 0];
            },
            attrs: {
                colspan: { default: 1 },
                rowspan: { default: 1 },
                alignment: { default: null },
            },
        };
    }

    toMarkdown() {
        // see: renderTable
    }

    parseMarkdown() {
        return {
            block: "th",
            getAttrs: tok => ({ alignment: tok.info }),
        };
    }
}
