import { mdToHtml } from "../md/parsing/parse";
import { isHtml } from "./isHtml";

type ContentType = "note" | "flashcard";

export const getHtmlFromContent = ({ content, type }: { content: string; type: ContentType }) => {
    if (!content) return "";
    if (isHtml(content)) return content;
    return mdToHtml(content, type);
};
