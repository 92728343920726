import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { KeyedMutator } from "swr";
import { StripeStatus } from "./subscriptionConstants";
import { AI_USAGE_LIMITS, LocalUser } from "./types";
import { fetchPostJSON } from "@/fetchFunctions/fetchWrappers";
import { fetchCurrentUserInfo } from "@/hooks/user/auth";
import { callVerifyStripeCheckout } from "@/hooks/user/graphqlUtils";
import { platform } from "@/platform";
import { AnyFunction } from "@/types/common";
import {
    DPAType,
    Organization,
    SignInType,
    StripeConnection,
    SubscriptionType,
    UserAIStats,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { wait } from "@/utils/genericUtils";
import { now } from "@/utils/SyncUtils";
import { populateCacheWithFallbackData } from "@/hooks/swr";
import { isUnderAge } from "./utils";
import { canShowAds } from "./subscriptions";
import { COUNTRY_CODES } from "@/utils/countries";

type CurrentUser = {
    loginInProgress: boolean;
    loggedOut: boolean;
    user?: UserDetails;
    organization?: Organization;
    international: boolean;
    isEnforcedDPA: boolean;
    isUnder13: boolean;
    hasReachedAIUsage: (type: keyof UserAIStats) => boolean;
    serverSyncTime?: number;
    signInType?: SignInType;
    userId?: string;
    viewerId?: string;
    isTeacher: boolean;
    isStudent: boolean;
    userName: string;
    isSocialSignIn: boolean;
    activeSubscription?: StripeConnection;
    isSubscriptionActive: boolean;
    canShowAds: boolean;
    isSubscriptionPastDue: boolean;
    subscriptionStatus?: StripeStatus;
    subscriptionType: SubscriptionType;
    subscriptionCancelDate?: number;
    isSubscriptionCancelling?: boolean;
    personalizedAds: boolean;
    mutate: KeyedMutator<any>;
    resubscribe: () => Promise<void>;
};

export const useCurrentUser = ({
    fallbackData,
}: {
    fallbackData?: LocalUser;
} = {}): CurrentUser => {
    const { data, error, mutate } = useSWRImmutable("getCurrentUser", fetchCurrentUserInfo, {
        fallbackData: fallbackData ?? undefined,
        use: [populateCacheWithFallbackData],
    });

    const { data: viewerId } = useSWRImmutable(
        (data || error) && ["viewerId", data?.user?.ID],
        async ([_, userId]) => userId ?? (await platform.anonymousViewerUid().then(({ get }) => get()))
    );

    const user = data?.user as UserDetails;
    const organization = data?.organization as Organization;

    const activeSubscription = useMemo(() => {
        // Active Subscription will be the first one in this list.
        return user?.subscriptions?.[0] ? ({ ...user?.subscriptions?.[0] } as StripeConnection) : undefined;
    }, [user]);

    const resubscribe = useCallback(async () => {
        const mixpanel = await platform.analytics.mixpanel();

        mixpanel.track("Resubscribe Button - Clicked", {
            subscriptionId: activeSubscription?.subscriptionId,
            priceId: activeSubscription?.priceId,
        });

        await fetchPostJSON("/api/subscription/update", {
            subscriptionId: activeSubscription?.subscriptionId,
            priceId: activeSubscription?.priceId,
        });
        await wait(1000);
        await callVerifyStripeCheckout(user.customerId);
        mutate();
    }, [activeSubscription, user]);

    const hasReachedAIUsage = useCallback(
        (type: keyof UserAIStats) => {
            const value = user?.ai?.[type] ?? 0;
            const usageLimit = AI_USAGE_LIMITS[user.subscriptionType ?? SubscriptionType.BASIC][type];

            return value >= usageLimit && (user?.ai?.curPeriodEnd || now()) > now();
        },
        [user?.ai, user?.subscriptionType]
    );

    return {
        mutate,
        loginInProgress: !data && !error,
        user,
        organization,
        international: COUNTRY_CODES.includes(user?.schoolId),
        isEnforcedDPA: organization?.dpa && organization.dpa.type !== DPAType.NONE,
        isUnder13: isUnderAge(user?.birthday, 13),
        hasReachedAIUsage,
        serverSyncTime: data?.serverSyncTime,
        userId: user?.ID ?? undefined,
        viewerId: user?.ID || viewerId,
        userName: user?.Name ?? "",
        isTeacher: user?.accountType?.toLowerCase() === "teacher",
        isStudent: user?.accountType?.toLowerCase() !== "teacher",
        loggedOut: !data || error?.status === 403,
        signInType: data?.signInType,
        isSocialSignIn: data?.signInType && data?.signInType !== SignInType.EMAIL,
        activeSubscription,
        subscriptionStatus: activeSubscription?.status as StripeStatus,
        subscriptionType: user?.subscriptionType || SubscriptionType.BASIC,
        isSubscriptionActive:
            activeSubscription?.status === StripeStatus.ACTIVE || activeSubscription?.status === StripeStatus.TRIALING,
        canShowAds: canShowAds({
            user,
            organization,
        }),
        isSubscriptionPastDue: activeSubscription?.status === StripeStatus.PAST_DUE,
        subscriptionCancelDate: activeSubscription?.current_period_end ?? undefined,
        isSubscriptionCancelling: !!activeSubscription?.cancel_at_period_end,
        personalizedAds: !!user?.personalizedAds,
        resubscribe,
    };
};

export const useIfUserSessionExpires = (cb: AnyFunction) => {
    const { user, userId, loginInProgress } = useCurrentUser();
    const cbRef = useRef(cb);
    const userRef = useRef<UserDetails | null>(null);

    useEffect(() => {
        cbRef.current = cb;
    });

    useEffect(() => {
        if (loginInProgress) return;
        if (user) {
            userRef.current = user;
        }
        if (userRef.current?.ID && !userId) {
            cbRef.current(userRef.current?.Email);
        }
    }, [userId, user, loginInProgress]);
};
