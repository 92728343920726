import { PluginSimple } from "markdown-it";
import { MarkdownParser } from "../pm/markdown";
import makeRules from "./helpers/makeRules";
import Extension from "./Extension";

export default class ExtensionManager {
    extensions;

    constructor(extensions: Extension[] = []) {
        this.extensions = extensions;
    }

    get nodes() {
        return this.extensions
            .filter(extension => extension.type === "node")
            .reduce(
                (nodes, node) => ({
                    ...nodes,
                    [node.name]: node.schema,
                }),
                {}
            );
    }

    parser({
        schema,
        rules,
        plugins,
    }: {
        schema: any;
        rules?: Record<string, any>;
        plugins?: PluginSimple[];
    }): MarkdownParser {
        const tokens: Record<string, any> = this.extensions
            .filter(extension => extension.type === "mark" || extension.type === "node")
            .reduce((nodes, extension) => {
                const md = extension.parseMarkdown();
                if (!md) return nodes;

                return {
                    ...nodes,
                    [extension.markdownToken || extension.name]: md,
                };
            }, {});

        return new MarkdownParser(schema, makeRules({ rules, plugins }), tokens);
    }

    get marks() {
        return this.extensions
            .filter(extension => extension.type === "mark")
            .reduce(
                (marks, { name, schema }) => ({
                    ...marks,
                    [name]: schema,
                }),
                {}
            );
    }

    get plugins() {
        return this.extensions
            .filter(extension => "plugins" in extension)
            .reduce((allPlugins, { plugins }) => [...allPlugins, ...plugins], []);
    }

    get rulePlugins() {
        return this.extensions
            .filter(extension => "rulePlugins" in extension)
            .reduce((allRulePlugins, { rulePlugins }) => [...allRulePlugins, ...rulePlugins], []);
    }
}
