import { checkboxRule } from "../rules";
import Node from "./Node";

export default class CheckboxItem extends Node {
    get name() {
        return "checkbox_item";
    }

    get defaultOptions() {
        return {
            includeDrag: true,
        };
    }

    get schema() {
        return {
            attrs: {
                checked: {
                    default: false,
                },
            },
            content: "paragraph block*",
            defining: true,
            draggable: this.options.includeDrag,
            parseDOM: [
                {
                    tag: "li[data-type='taskItem']",
                    getAttrs: dom => ({
                        checked: dom.getAttribute("data-checked") === "true",
                    }),
                },
            ],
            toDOM: node => {
                return [
                    "li",
                    {
                        "data-type": "taskItem",
                        "data-checked": node.attrs.checked,
                    },
                    ["div", 0],
                ];
            },
        };
    }

    get rulePlugins() {
        return [checkboxRule];
    }

    toMarkdown(state, node) {
        state.write(node.attrs.checked ? "[x] " : "[ ] ");
        state.renderContent(node);
    }

    parseMarkdown() {
        return {
            block: "checkbox_item",
            getAttrs: tok => ({
                checked: tok.attrGet("checked") ? true : undefined,
            }),
        };
    }
}
