import { MarkSpec } from "../../../pm/model";
import Mark from "../Mark";
import { markRule } from "../../rules";

export class BlueHighlight extends Mark {
    get name() {
        return "highlight_blue";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "blue",
                },
            },
            excludes: "highlight",
            group: "highlight",
            parseDOM: [
                {
                    tag: "mark",
                    getAttrs: node => node.getAttribute("class") === "blue highlight",
                },
                {
                    style: "background-color",
                    getAttrs: value => !!value && value === "blue",
                },
            ],
            toDOM: () => [
                "mark",
                {
                    "data-color": "blue",
                },
            ],
        };
    }

    get rulePlugins() {
        return [markRule({ delim: "^^", mark: "highlight_blue" })];
    }

    get toMarkdown() {
        return {
            open: "^^",
            close: "^^",
            mixable: true,
            expelEnclosingWhitespace: true,
        };
    }

    parseMarkdown() {
        return { mark: "highlight_blue" };
    }
}
