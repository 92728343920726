// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
import { PricingOption, StripeStatus, hasFreeTrial } from "./subscriptionConstants";
import { DPAType, SubscriptionType, UserDetails } from "@knowt/syncing/graphql/schema";
import { LocalUser } from "./types";

export const isFreeTrialAllowed = (lastTrialTime?: number | null) => {
    // if lastTrialTime is null, then the user has never had a trial.
    return !lastTrialTime;
};

export const getPricingCardProps = (pricingOption: PricingOption, user?: UserDetails) => {
    const subscriptionType = user?.subscriptionType ?? SubscriptionType.BASIC;
    const isSubscriptionCancelling = !!user?.subscriptions?.[0].cancel_at_period_end;
    const isTrialing = user?.subscriptions?.[0].status === StripeStatus.TRIALING;

    const isCurrentPlan = user
        ? pricingOption.subscriptionType.toLowerCase() !== SubscriptionType.BASIC.toLowerCase()
            ? pricingOption.subscriptionType.toLowerCase() === subscriptionType?.toLowerCase() &&
              user?.subscriptions?.[0]?.interval?.toLowerCase() === pricingOption.interval.toLowerCase()
            : subscriptionType === SubscriptionType.BASIC
        : pricingOption.subscriptionType.toLowerCase() === SubscriptionType.BASIC.toLowerCase();

    const planTypesInOrder = ["basic", "supporter", "pro", "limitless"];

    const isABetterPlan =
        user &&
        subscriptionType !== SubscriptionType.BASIC &&
        planTypesInOrder.indexOf(subscriptionType.toLowerCase()) <
            planTypesInOrder.indexOf(pricingOption.subscriptionType.toLowerCase());

    const isAWorsePlan =
        user &&
        subscriptionType !== SubscriptionType.BASIC &&
        planTypesInOrder.indexOf(subscriptionType.toLowerCase()) >
            planTypesInOrder.indexOf(pricingOption.subscriptionType.toLowerCase());

    const isTrialAllowed = hasFreeTrial(pricingOption.priceId) && isFreeTrialAllowed(user?.stripeTrialTime);

    const getButtonText = () => {
        if (isCurrentPlan) {
            return isSubscriptionCancelling ? "resubscribe" : isTrialing ? "already trialing" : "already on plan";
        } else if (isTrialAllowed) {
            return "start a free trial";
        } else if (isABetterPlan) {
            return "upgrade";
        } else if (isAWorsePlan) {
            return "downgrade";
        } else {
            return "select plan";
        }
    };

    return {
        isCurrentPlan,
        buttonText: getButtonText(),
    };
};

export const canShowAds = (serverUser: Pick<LocalUser, "organization" | "user">) =>
    serverUser.user &&
    serverUser.user?.subscriptionType === SubscriptionType.BASIC &&
    // is an organization without a DPA
    ((serverUser.organization &&
        (!serverUser.organization.dpa || serverUser.organization.dpa?.type === DPAType.NONE)) ||
        // is a teacher not affiliated with a school and has a class
        (serverUser.user?.schoolId === "NONE" && !!serverUser.user?.classes?.teacher?.length));
