import { MarkSpec } from "../../pm/model";
import Mark from "./Mark";

export default class Link extends Mark {
    get name() {
        return "link";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                href: {
                    default: "",
                },
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: "a[href]",
                    getAttrs: (dom: HTMLElement) => ({
                        href: dom.getAttribute("href"),
                    }),
                },
            ],
            toDOM: node => [
                "a",
                {
                    ...node.attrs,
                    rel: "noopener noreferrer nofollow",
                },
                0,
            ],
        };
    }

    get toMarkdown() {
        return {
            open(_state) {
                return "[";
            },
            close(state, mark) {
                return (
                    "](" +
                    state.esc(mark.attrs.href) +
                    (mark.attrs.title ? " " + state.quote(mark.attrs.title) : "") +
                    ")"
                );
            },
        };
    }

    parseMarkdown() {
        return {
            mark: "link",
            getAttrs: tok => ({
                href: tok.attrGet("href"),
                title: tok.attrGet("title") || null,
            }),
        };
    }
}
