import { mutate } from "swr";
import {
    deleteFlashcardSets,
    duplicateFlashcardSets,
    genericUpdateFlashcardSets,
    updateFlashcardSetsPublicState,
} from "../flashcards/utils";
import { deleteNotes, duplicateNotes, genericUpdateNotes, updateNotesPublicState } from "../notes/utils";
import { callListNotesByFolder } from "../notes/graphqlUtils";
import { callListStandaloneFlashcardSetByUserNoContent } from "@/hooks/flashcards/graphqlUtils";
import {
    callCreateFolder,
    callDeleteFolder,
    callGetFolder,
    callListFoldersByParent,
    callListFoldersByUser,
    callUpdateFolder,
} from "@/hooks/folders/graphqlUtils";
import { safeLocalMutate } from "@/hooks/swr";
import { FlashcardSet, Folder, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { objectWithout } from "@/utils/dataCleaning";
import { v4 as uuidv4 } from "uuid";

import { platform } from "@/platform";
import { callListMediaByFolder } from "../media/graphqlUtils";
import { deleteMedias, duplicateMedias, genericUpdateMedias } from "../media/utils";

type FoldersMap = Record<string, Folder>;

export const moveFolder = async ({
    folderId,
    userId,
    sourceFolderId = null,
    parentId,
    sourceClassId = null,
    destinationClassId,
    disableToast,
}: {
    folderId: string;
    userId: string | undefined;
    sourceFolderId: string | null;
    parentId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
    disableToast?: boolean;
}) => {
    const updates = { parentId, ...(sourceClassId !== destinationClassId && { classId: destinationClassId }) };

    const swrKeysToUpdates = [
        resolveFoldersSWRKey({ userId }),
        resolveNestedFoldersSWRKey({ userId, parentId: sourceFolderId }),
        resolveFoldersSWRKey({ userId, classId: sourceClassId }),
        resolveNestedFoldersSWRKey({ userId, parentId }),
        resolveFoldersSWRKey({ userId, classId: destinationClassId }),
    ];

    await Promise.all(
        swrKeysToUpdates.map(swrKey =>
            safeLocalMutate(swrKey, (oldFolders: FoldersMap) => ({
                ...oldFolders,
                [folderId]: { ...oldFolders[folderId], ...updates },
            }))
        )
    );

    if (destinationClassId === sourceClassId) {
        await callUpdateFolder(folderId, { userId, ...updates });
    } else {
        // we need to propagate the `classId: destinationClassId` update to all nested items
        await updateFolderAndNestedItemsFields({
            folderId,
            userId,
            parentId,
            topLevelFolderUpdates: updates,
            nestedFoldersUpdates: updates,
            itemsUpdates: updates,
        });
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Folder - Moved to Folder", { folderId });

    if (disableToast) {
        const toast = await platform.toast();
        toast.success("Folder moved!");
    }
};

export const renameFolder = async ({
    folderId,
    userId,
    newName,
}: {
    folderId: string;
    userId: string;
    newName: string;
}) => {
    const newFolders = await safeLocalMutate(resolveFoldersSWRKey({ userId }), async (oldFolders: FoldersMap) => ({
        ...oldFolders,
        [folderId]: { ...oldFolders[folderId], name: newName },
    }));

    if (newFolders[folderId].parentId) {
        await safeLocalMutate(
            resolveNestedFoldersSWRKey({ userId, parentId: newFolders[folderId].parentId }),
            async (oldFolders: FoldersMap) => ({
                ...oldFolders,
                [folderId]: { ...oldFolders[folderId], name: newName },
            })
        );
    }

    await mutate(resolveFolderSWRKey({ folderId }), newFolders[folderId], { revalidate: false });
    await callUpdateFolder(folderId, { userId, name: newName });
};

export const trashFolders = async ({
    folderIds,
    userId,
    parentId = null,
    sourceClassId = null,
}: {
    folderIds: string[];
    userId: string;
    parentId?: string | null;
    sourceClassId?: string | null;
}) => {
    await Promise.all(
        folderIds.map(
            async folderId =>
                await trashFolder({
                    folderId,
                    userId,
                    parentId,
                    sourceClassId,
                })
        )
    );

    // extra cache updater outside of the trashFolder function to fix individual cache update conflicts
    await safeLocalMutate(resolveFoldersSWRKey({ userId, classId: sourceClassId }), (oldFolders: FoldersMap) => ({
        ...oldFolders,
        ...folderIds
            .map(folderId => ({
                [folderId]: { ...oldFolders[folderId], trash: true },
            }))
            .reduce((acc, val) => ({ ...acc, ...val }), {}),
    }));
};
export const trashFolder = async ({
    folderId,
    userId,
    parentId = null,
    sourceClassId = null,
}: {
    folderId: string;
    userId: string;
    parentId: string | null;
    sourceClassId?: string | null;
}) =>
    await updateFolderTrashState({
        folderId,
        userId,
        parentId,
        putInTrash: true,
        removeFromParentFolder: true,
        removeFromClass: true,
        sourceClassId,
    });

export const restoreFolders = async ({ folderIds, userId }: { folderIds: string[]; userId: string }) => {
    Promise.all(folderIds.map(folderId => restoreFolder({ folderId, userId })));

    // extra cache updater outside of the restoreFolder function to fix individual cache update conflicts
    await safeLocalMutate(resolveFoldersSWRKey({ userId }), (oldFolders: FoldersMap) => ({
        ...oldFolders,
        ...folderIds
            .map(folderId => ({
                [folderId]: { ...oldFolders[folderId], trash: false },
            }))
            .reduce((acc, val) => ({ ...acc, ...val }), {}),
    }));
};

export const restoreFolder = async ({ folderId, userId }: { folderId: string; userId: string }) =>
    await updateFolderTrashState({ folderId, userId, putInTrash: false, removeFromParentFolder: true });

const updateFolderTrashState = async ({
    folderId,
    userId,
    putInTrash,
    parentId = null,
    removeFromParentFolder,
    removeFromClass,
    sourceClassId,
}: {
    folderId: string;
    userId: string;
    putInTrash: boolean;
    parentId?: string | null;
    removeFromParentFolder: boolean;
    removeFromClass?: boolean;
    sourceClassId?: string | null;
}) => {
    const topLevelFolderUpdates = {
        trash: putInTrash,
        ...(removeFromClass && { classId: null }),
        ...(removeFromParentFolder && { parentId: null }),
    };

    // inner items always preserve the nesting structure, hence we don't update the `parentId`
    const innerItemsUpdates = objectWithout(topLevelFolderUpdates, "parentId");

    await updateFolderAndNestedItemsFields({
        folderId,
        userId,
        parentId,
        sourceClassId,
        topLevelFolderUpdates,
        nestedFoldersUpdates: innerItemsUpdates,
        itemsUpdates: innerItemsUpdates,
    });
};

export const updateFolderAndNestedItemsFields = async ({
    folderId,
    userId,
    parentId,
    sourceClassId,
    topLevelFolderUpdates,
    nestedFoldersUpdates,
    itemsUpdates,
}: {
    folderId: string;
    userId: string;
    parentId?: string | null;
    sourceClassId?: string | null;
    topLevelFolderUpdates: Partial<Folder>;
    nestedFoldersUpdates: Partial<Folder>;
    itemsUpdates: Partial<Folder>;
}) => {
    await safeLocalMutate(resolveFoldersSWRKey({ userId, classId: sourceClassId }), (oldFolders: FoldersMap) => ({
        ...oldFolders,
        [folderId]: { ...oldFolders[folderId], ...topLevelFolderUpdates },
    }));

    if (parentId) {
        await safeLocalMutate(resolveNestedFoldersSWRKey({ userId, parentId }), (oldFolders: FoldersMap) => ({
            ...oldFolders,
            [folderId]: { ...oldFolders[folderId], ...topLevelFolderUpdates },
        }));
    }

    const nestedItems = await fetchFolderNestedItems({ userId, folderId });

    await Promise.all(
        nestedItems.folderIds.map(nestedFolderId =>
            updateFolderAndNestedItemsFields({
                folderId: nestedFolderId,
                userId,
                topLevelFolderUpdates: nestedFoldersUpdates,
                nestedFoldersUpdates,
                itemsUpdates,
            })
        )
    );

    await Promise.all([
        genericUpdateNotes({
            noteIds: nestedItems.noteIds,
            userId,
            updatedFields: itemsUpdates as undefined as Partial<Note>,
        }),
        genericUpdateFlashcardSets({
            flashcardSetIds: nestedItems.flashcardSetIds,
            userId,
            updatedFields: itemsUpdates as undefined as Partial<FlashcardSet>,
        }),
        genericUpdateMedias({
            mediaIds: nestedItems.mediaIds,
            userId,
            updatedFields: itemsUpdates as undefined as Partial<Media>,
        }),
    ]);

    await callUpdateFolder(folderId, { userId, ...topLevelFolderUpdates });
};

export const deleteFolder = async ({ folderId, userId }: { folderId: string; userId: string }) => {
    await safeLocalMutate(resolveFoldersSWRKey({ userId }), (folders: FoldersMap) => objectWithout(folders, folderId));

    const nestedItems = await fetchFolderNestedItems({ userId, folderId });

    await Promise.all(nestedItems.folderIds.map(nestedFolderId => deleteFolder({ folderId: nestedFolderId, userId })));

    await Promise.all([
        deleteNotes({ noteIds: nestedItems.noteIds, userId }),
        deleteFlashcardSets({ flashcardSetIds: nestedItems.folderIds, userId }),
        deleteMedias({ mediaIds: nestedItems.mediaIds, userId }),
    ]);

    await callDeleteFolder(folderId, userId);
};

export const deleteFolders = async ({ folderIds, userId }: { folderIds: string[]; userId: string }) => {
    await safeLocalMutate(resolveFoldersSWRKey({ userId }), (folders: FoldersMap) =>
        objectWithout(folders, ...folderIds)
    );

    await Promise.all(folderIds.map(folderId => deleteFolder({ folderId, userId })));
};

export const duplicateFolder = async ({
    user,
    folderId: baseFolderId,
    overrides,
}: {
    user: UserDetails;
    folderId: string;
    overrides?: Partial<Folder>;
}) => {
    const initialFolder = await callGetFolder({ folderId: baseFolderId });
    const isOwner = initialFolder.userId === user.ID;

    const newFolder = await callCreateFolder({
        ...objectWithout(initialFolder, "userId", "folderId", "name"),
        parentId: isOwner ? initialFolder.parentId : null,
        userId: user.ID,
        folderId: uuidv4(),
        name: `${initialFolder.name} (copy)`,
        ...overrides,
    });

    await addFolderToCache({ user, folder: newFolder });

    const nestedItems = await fetchFolderNestedItems({ userId: user.ID, folderId: baseFolderId });

    await Promise.all(
        nestedItems.folderIds.map(folderId =>
            duplicateFolder({
                user,
                folderId,
                overrides: {
                    parentId: newFolder.folderId,
                    public: newFolder.public,
                    password: newFolder.password,
                },
            })
        )
    );

    await Promise.all([
        duplicateNotes(nestedItems.noteIds, user, {
            folderId: newFolder.folderId,
            public: newFolder.public,
            password: newFolder.password,
        }),
        duplicateFlashcardSets(nestedItems.flashcardSetIds, user, {
            folderId: newFolder.folderId,
            public: newFolder.public,
            password: newFolder.password,
        }),
        duplicateMedias(nestedItems.mediaIds, user.ID, newFolder.folderId),
    ]);

    return newFolder;
};

const addFolderToCache = async ({ user, folder }: { user: UserDetails; folder: Folder }) => {
    if (folder.parentId) {
        await updateNestedFolderInFolderList({
            userId: user.ID,
            folderId: folder.folderId,
            parentId: folder.parentId,
            updatedFields: folder,
        });
    } else {
        await updateFolderInFolderList({
            userId: user.ID,
            folderId: folder.folderId,
            classId: folder.classId,
            updatedFields: folder,
        });
    }
};

const fetchFolderNestedItems = async ({ userId, folderId }: { userId: string; folderId: string }) => {
    const [foldersMap, notes, flashcardSetIds, mediaIds] = await Promise.all([
        callListFoldersByParent({ parentId: folderId }),
        callListNotesByFolder({ folderId }),
        fetchFolderFlashcardSetIds({ userId, folderId }),
        fetchFolderMediaIds({ folderId }),
    ]);

    return {
        folderIds: Object.keys(foldersMap),
        noteIds: Object.keys(notes),
        flashcardSetIds,
        mediaIds,
    };
};

const fetchFolderFlashcardSetIds = async ({ userId, folderId }: { userId: string; folderId: string }) => {
    const standaloneFlashcardSets = await callListStandaloneFlashcardSetByUserNoContent({ userId });
    if (!standaloneFlashcardSets) return [];

    return Object.values(standaloneFlashcardSets)
        .filter(flashcardSet => flashcardSet.folderId === folderId)
        .map(flashcardSet => flashcardSet.flashcardSetId);
};

const fetchFolderMediaIds = async ({ folderId }: { folderId: string }) => {
    const medias = await callListMediaByFolder({ folderId });
    return Object.keys(medias ?? {});
};

export const updateFolderInFolderList = async ({
    userId,
    folderId,
    classId,
    updatedFields,
}: {
    userId: string | undefined;
    folderId: string;
    classId?: string;
    updatedFields: Partial<Folder>;
}) => {
    const swrKeys = [resolveFoldersSWRKey({ userId }), ...(classId ? [resolveFoldersSWRKey({ userId, classId })] : [])];

    return Promise.all(
        swrKeys.map(swrKey =>
            safeLocalMutate(swrKey, (oldFolders: FoldersMap) => {
                return {
                    ...oldFolders,
                    [folderId]: { ...oldFolders[folderId], ...updatedFields },
                };
            })
        )
    );
};

export const updateFolderInNestedFolderList = async ({
    userId,
    parentId,
    newFolder,
}: {
    userId: string | undefined;
    parentId: string;
    newFolder: Folder;
}) => {
    return await safeLocalMutate(
        resolveNestedFoldersSWRKey({ userId, parentId }),
        (oldFolders: Record<string, Folder>) => {
            return {
                ...oldFolders,
                [newFolder.folderId]: newFolder,
            };
        }
    );
};

export const updateNestedFolderInFolderList = async ({
    userId,
    folderId,
    parentId,
    updatedFields,
}: {
    userId: string;
    folderId: string;
    parentId: string;
    updatedFields: Partial<Folder>;
}) => {
    return await safeLocalMutate(resolveNestedFoldersSWRKey({ userId, parentId }), (oldFolders: FoldersMap) => {
        return {
            ...oldFolders,
            [folderId]: { ...oldFolders[folderId], ...updatedFields },
        };
    });
};

export const updateFolderPublicState = async ({
    folderId,
    userId,
    parentId,
    isPublic,
    password = null,
}: {
    folderId: string;
    userId: string;
    parentId?: string;
    isPublic: boolean;
    password?: string;
}) => {
    await updateFolderInFolderList({
        userId,
        folderId,
        updatedFields: { public: isPublic, password },
    });

    if (parentId) {
        await updateNestedFolderInFolderList({
            userId,
            folderId,
            parentId,
            updatedFields: { public: isPublic, password },
        });
    }

    const { folderIds, noteIds, flashcardSetIds } = await fetchFolderNestedItems({ folderId, userId });

    await Promise.all(
        folderIds.map(nestedFolderId =>
            updateFolderPublicState({
                folderId: nestedFolderId,
                parentId: folderId,
                userId,
                isPublic,
                password,
            })
        )
    );

    await Promise.all([
        updateNotesPublicState({
            noteIds: noteIds,
            userId,
            isPublic,
            sourceFolderId: folderId,
            password,
        }),
        updateFlashcardSetsPublicState({
            flashcardSetIds: flashcardSetIds,
            userId,
            isPublic,
            sourceFolderId: folderId,
            password,
        }),
        // note: medias are always private
    ]);

    const updatedFolder = await callUpdateFolder(folderId, { userId, public: isPublic, password });
    await mutate(resolveFolderSWRKey({ folderId }), updatedFolder, { revalidate: false });

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Folder - Shared", { folderId, public: isPublic });
};

export const isFolderMoveValid = async ({
    destinationFolderId,
    selectedFolders,
    userId,
}: {
    destinationFolderId: string | null | undefined;
    selectedFolders: Record<string, Folder>;
    userId?: string | null;
}) => {
    if (!destinationFolderId) {
        return false;
    }

    if (selectedFolders[destinationFolderId]) {
        return false;
    }

    const allFolders = await callListFoldersByUser({ userId });

    const isParent = (folderId: string, compareId: string) => {
        const folder = allFolders[folderId];
        if (!folder?.parentId) {
            return false;
        }
        if (folder.parentId === compareId) {
            return true;
        }
        return isParent(folder.parentId, compareId);
    };

    for (const folder of Object.values(selectedFolders)) {
        if (isParent(destinationFolderId, folder.folderId)) {
            return false;
        }
    }

    return true;
};

export const resolveFoldersSWRKey = ({
    userId,
    classId = null,
    isEnabled = true,
}: {
    userId: string;
    classId?: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && userId ? ["folders", userId, classId] : null;
};

export const resolveFolderSWRKey = ({ folderId, isEnabled = true }: { folderId: string; isEnabled?: boolean }) => {
    return isEnabled && folderId ? ["folder", folderId] : null;
};

// TODO: consider removing this function, in favor of using `resolveFoldersSWRKey`
export const resolveNestedFoldersSWRKey = ({
    userId,
    parentId,
    isEnabled = true,
}: {
    userId: string;
    parentId: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && parentId ? ["nested-folders", userId, parentId] : null;
};
