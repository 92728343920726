import {
    Organization,
    SignInType,
    SubscriptionType,
    UserAIStatsHistory,
    UserDetails,
} from "@knowt/syncing/graphql/schema";

export const CACHED_USER_KEY = "cachedUser";
export const TEMP_CACHED_SIGN_IN_SOURCE_KEY = "tempCachedSignInSource";
export const SIGN_UP_TRACKED = "isSignUpTracked";

export const FREE_USAGE_LIMIT: Required<Omit<UserAIStatsHistory, "periodEnd" | "cost">> = {
    // KnowtAI
    prompts: 20,
    tokens: 20_000,

    // Videos
    videos: 2,
    mins: 80,
    vNotes: 2,
    vSets: 2,

    // PDFs
    pdfs: 2,
    pages: 80,
    pNotes: 2,
    pSets: 2,

    // Notes
    nTests: 5,
    nSets: 5,

    // Chats
    chats: 25,

    // Explain why I'm wrong
    explain: 50,
};

export const AI_USAGE_LIMITS: { [key in SubscriptionType]: Required<Omit<UserAIStatsHistory, "periodEnd" | "cost">> } =
    {
        [SubscriptionType.BASIC]: FREE_USAGE_LIMIT,
        [SubscriptionType.SUPPORTER]: {
            ...FREE_USAGE_LIMIT,

            // KnowtAI
            prompts: 100,
            tokens: 100_000,

            // Notes
            nTests: 30,
            nSets: 30,

            // Explain why I'm wrong
            explain: 2_000,
        },
        [SubscriptionType.PRO]: {
            // KnowtAI
            prompts: 1_000,
            tokens: 500_000,

            // Videos
            videos: 5,
            mins: 200,
            vNotes: 5,
            vSets: 5,

            // PDFs
            pdfs: 5,
            pages: 200,
            pNotes: 5,
            pSets: 5,

            // Notes
            nTests: 50,
            nSets: 50,

            // Chats
            chats: 200,

            // Explain why I'm wrong
            explain: 5_000,
        },
        [SubscriptionType.LIMITLESS]: {
            // Technically, there is no limit, but we should set a some sort of limit so that we don't get charged a ton
            // plus we know who is using it the most, or spamming us

            // KnowtAI
            prompts: 10_000,
            tokens: 1_000_000,

            // Videos
            videos: 100,
            mins: 4_000,
            vNotes: 100,
            vSets: 100,

            // PDFs
            pdfs: 100,
            pages: 4_000,
            pNotes: 100,
            pSets: 100,

            // Notes
            nTests: 500,
            nSets: 500,

            // Chats
            chats: 1_000,

            // Explain why I'm wrong
            explain: 20_000,
        },
    };

export type LocalUnauthUser = {
    user: null | undefined;
    organization: null | undefined;
    signInType: never;
    serverSyncTime: number;
};

export type LocalAuthUser = {
    user: UserDetails;
    organization: Organization | null | undefined;
    signInType: SignInType;
    serverSyncTime: number;
};

export type LocalUser = LocalAuthUser | LocalUnauthUser;

// from the backend, we stringify all the fields, so we need to parse them
export type ServerUserDetails = {
    __typename?: "ServerUserDetails";
    Email: string;
    ID?: string;
    Name?: string;
    accessPool?: string;
    accessPoolExpiry?: string;
    accountType?: string;
    ai?: string;
    alerts?: string;
    answerSide?: string;
    bio?: string;
    birthday?: string;
    cover?: string;
    created?: string;
    creator?: string;
    customerId?: string;
    deleteReq?: string;
    externalProvider?: string;
    grade?: string;
    hideSensitiveInfo?: string;
    lastLogIn?: string;
    lastNotified?: string;
    noteSidebarWidth?: string;
    numFollowers?: string;
    numFollowing?: string;
    personalizedAds?: string;
    pictureUrl?: string;
    privacyAck?: string;
    profileColor?: string;
    rating?: string;
    ratingCount?: string;
    recentlyViewed?: string;
    referral?: string;
    referralCode?: string;
    referralList?: string;
    schoolId?: string;
    socials?: string;
    stripeVerifyTime?: string;
    studentInfo?: string;
    subscriptionType?: string;
    subscriptions?: string;
    teacherInfo?: string;
    timeZone?: string;
    username?: string;
    verified?: string;
    views?: string;
    volunteer?: string;
};
