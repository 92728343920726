import Node from "./Node";

export default class OrderedList extends Node {
    get name() {
        return "ordered_list";
    }

    get schema() {
        return {
            attrs: {
                order: {
                    default: 1,
                },
            },
            content: "list_item+",
            group: "block",
            parseDOM: [
                {
                    tag: "ol",
                    getAttrs: (dom: HTMLOListElement) => ({
                        order: dom.hasAttribute("start") ? Number(dom.getAttribute("start") || "1", 10) : 1,
                    }),
                },
            ],
            toDOM: node => (node.attrs.order === 1 ? ["ol", 0] : ["ol", { start: node.attrs.order }, 0]),
        };
    }

    toMarkdown(state, node) {
        state.write("\n");

        const start = node.attrs.order !== undefined ? node.attrs.order : 1;
        const maxW = `${start + node.childCount - 1}`.length;
        const space = state.repeat(" ", maxW + 2);

        state.renderList(node, space, i => {
            const nStr = `${start + i}`;
            return state.repeat(" ", maxW - nStr.length) + nStr + ". ";
        });
    }

    parseMarkdown() {
        return {
            block: "ordered_list",
            getAttrs: tok => ({
                order: Number(tok.attrGet("start") || "1", 10),
            }),
        };
    }
}
