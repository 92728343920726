import { FileType } from "@knowt/syncing/graphql/schema";
export const AV_BUCKET = "knowt-video-streaming-dev";
export const PDF_BUCKET = "knowt-pdf-flow-dev";

export const PPT_FORMATS = [FileType.PPT, FileType.PPTX];
export const EXCEL_FORMATS = [FileType.XLS, FileType.XLSX];
export const PDF_FORMATS = [FileType.PDF];

export const VIDEO_FORMATS = [
    FileType.M4A,
    FileType.MP4,
    FileType.WEBM,
    FileType.MOV,
    FileType.MKV,
    FileType.MP3,
    FileType.WAV,
];
