import { embedRule } from "../rules";
import Node from "./Node";

export default class Embed extends Node {
    get name() {
        return "embed";
    }

    get schema() {
        return {
            content: "inline*",
            group: "block",
            atom: true,
            attrs: {
                href: {},
            },
            parseDOM: [
                {
                    tag: "iframe[class=embed]",
                    getAttrs: (dom: HTMLIFrameElement) => {
                        const { embeds } = this.editor.props;
                        const href = dom.getAttribute("src") || "";

                        if (embeds) {
                            for (const embed of embeds) {
                                const matches = embed.matcher(href);
                                if (matches) {
                                    return {
                                        href,
                                    };
                                }
                            }
                        }

                        return {};
                    },
                },
            ],
            toDOM: node => ["iframe", { class: "embed", src: node.attrs.href, contentEditable: false }, 0],
        };
    }

    get rulePlugins() {
        return [embedRule(this.options.embeds)];
    }

    toMarkdown(state, node) {
        state.ensureNewLine();
        state.write("[" + state.esc(node.attrs.href) + "](" + state.esc(node.attrs.href) + ")");
        state.write("\n\n");
    }

    parseMarkdown() {
        return {
            node: "embed",
            getAttrs: token => ({
                href: token.attrGet("href"),
            }),
        };
    }
}
