import { deepScrapeEmptyFields } from "@/utils/dataCleaning";
import { ServerClientWithCookies, client, listGroupedData, now } from "@/utils/SyncUtils";
import { getFolder, listFoldersByClass, listFoldersByParent, listFoldersByUser } from "@/graphql/queries";
import { createFolder, deleteFolder, updateFolder } from "@/graphql/mutations";
import { Folder } from "@knowt/syncing/graphql/schema";
import { retry } from "@/utils/genericUtils";
import { platform } from "@/platform";

export const callListFoldersByUser = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listFoldersByUser,
        groupingKey: "folderId",
        input: { userId },
        queryName: "listFoldersByUser",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, Folder>>;
};

export const callGetFolder = async ({
    folderId,
    serverClient,
    password,
}: {
    folderId: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    if (!folderId) return null;

    const input = { folderId, password };

    return client
        .query({
            query: getFolder,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.getFolder)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "getFolder", input);
            throw error;
        });
};

export const callListFoldersByParent = async ({
    parentId,
    serverClient,
}: {
    parentId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listFoldersByParent,
        groupingKey: "folderId",
        input: { parentId },
        queryName: "listFoldersByParent",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Folder>;
};

export const callListFoldersByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listFoldersByClass,
        groupingKey: "folderId",
        input: { classId },
        queryName: "listFoldersByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Folder>;
};

const cleanFolderInput = folderObj => {
    const {
        userId,
        folderId,
        parentId,
        name,
        color,
        description,
        created,
        updated,
        notesCount,
        trash,
        public: newPublic,
        classId,
        icon,
        password,
    } = folderObj;

    return deepScrapeEmptyFields(
        {
            userId,
            folderId,
            parentId,
            name,
            color,
            description,
            created,
            updated,
            notesCount,
            trash,
            public: newPublic,
            password,
            classId,
            icon,
        },
        ["parentId", "classId", "password"]
    );
};

export const callCreateFolder = async (initialFields: Partial<Folder>) => {
    const folder = await retry(async () => {
        const timestamp = now();

        const privacySettings = await getPrivacySettings(initialFields);
        const input = { created: timestamp, updated: timestamp, ...initialFields, ...privacySettings };

        return client
            .mutate({
                mutation: createFolder,
                variables: { input: cleanFolderInput(input) },
            })
            .then(({ data }) => data.createFolder);
    }, 1);

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Folder - Created", { folderId: folder.folderId });

    return folder;
};

const getPrivacySettings = async (initialFields: Partial<Folder>): Promise<Pick<Folder, "public" | "password">> => {
    if (initialFields.password !== undefined) return { public: false, password: initialFields.password };
    if (typeof initialFields.public === "boolean") return { public: initialFields.public, password: null };
    if (initialFields.parentId) {
        const parentFolder = await callGetFolder({ folderId: initialFields.parentId });
        return { public: parentFolder.public, password: parentFolder.password };
    }
    return { public: false, password: null };
};

export const callUpdateFolder = (folderId: string, updatedFields: Partial<Folder>) => {
    return retry(async () => {
        return await client
            .mutate({
                mutation: updateFolder,
                variables: { input: { folderId, updated: now(), ...cleanFolderInput(updatedFields) } },
            })
            .then(({ data }) => data.updateFolder);
    });
};

export const callDeleteFolder = (folderId, userId) => {
    return retry(() =>
        client.mutate({
            mutation: deleteFolder,
            variables: { input: { folderId, userId } },
        })
    );
};
