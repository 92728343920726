import Node from "./Node";

export default class CheckboxList extends Node {
    get name() {
        return "checkbox_list";
    }

    get schema() {
        return {
            group: "block",
            content: "checkbox_item+",
            toDOM: () => ["ul", { "data-type": "taskList" }, 0],
            parseDOM: [{ tag: "ul[data-type='taskList']" }],
        };
    }

    toMarkdown(state, node) {
        state.renderList(node, "  ", () => "- ");
    }

    parseMarkdown() {
        return { block: "checkbox_list" };
    }
}
