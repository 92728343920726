import DataCleaningInterface from "./interfaces/DataCleaning";
import FetchInterface from "./interfaces/Fetch";
import LoggingInterface from "./interfaces/analytics/Logging";
import MixpanelInterface from "./interfaces/analytics/Mixpanel";
import noop from "./utils/noop";
import AnonymousViewerUidInterface from "@/interfaces/AnonymousViewerUid";
import LocalKeyValueStoreInterface from "@/interfaces/LocalKeyValueStore";
import TimeZoneInterface from "@/interfaces/TimeZone";
import ToastInterface from "@/interfaces/Toast";

export type Config = {
    storage: () => Promise<LocalKeyValueStoreInterface>;
    toast: () => Promise<ToastInterface>;
    fetch: () => Promise<FetchInterface>;
    timezone: () => Promise<TimeZoneInterface>;
    anonymousViewerUid: () => Promise<AnonymousViewerUidInterface>;
    dataCleaning: () => Promise<DataCleaningInterface>;
    analytics: {
        mixpanel: () => Promise<MixpanelInterface>;
        logging: () => Promise<LoggingInterface>;
    };
    events?: () => Promise<{
        on?: (event: string, callback: () => void) => void;
        off?: (event: string, callback: () => void) => void;
    }>;
};

// to allow logging on server side without configuring platform
export const log = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.log(key, ...extras);
};

export const forceLog = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.log(key, ...extras);
};

export const warn = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.warn(key, ...extras);
};

export const forceWarn = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.warn(key, ...extras);
};

class Platform {
    public storage: Config["storage"] = () =>
        Promise.resolve({
            get: null,
            set: null,
            getWithExpiry: null,
            remove: null,
            setWithExpiry: null,
        });

    public toast: Config["toast"] = () =>
        Promise.resolve({
            error: null,
            success: null,
            loading: null,
            dismiss: null,
            remove: null,
        });

    public events?: Config["events"] = () => Promise.resolve({ on: null, off: null });
    public timezone: Config["timezone"] = () => Promise.resolve({ getLocalTZ: null });
    public fetch: Config["fetch"] = () => Promise.resolve(null);
    public anonymousViewerUid?: Config["anonymousViewerUid"] = () => Promise.resolve({ get: null });
    public analytics: Config["analytics"] = {
        mixpanel: () =>
            Promise.resolve({
                track: noop,
                identify: noop,
                alias: noop,
                people: {
                    set: noop,
                },
                reset: noop,
                init: noop,
            }),
        logging: () =>
            Promise.resolve({
                log,
                forceLog,
                warn,
                forceWarn,
                report: noop,
                onAppsyncError: noop,
            }),
    };

    public dataCleaning: Config["dataCleaning"] = () =>
        Promise.resolve({
            getHtmlFromContent: noop,
            getPlainTextFromContent: noop,
        });

    public configure(config: Config) {
        this.storage = config.storage;
        this.toast = config.toast;
        this.fetch = config.fetch;
        this.events = config.events;
        this.timezone = config.timezone;
        this.anonymousViewerUid = config.anonymousViewerUid;
        this.dataCleaning = config.dataCleaning;
        this.analytics = config.analytics;
    }
}

export const platform = new Platform();
