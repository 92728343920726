export const fetchGetJSON = async (url: string, headers?: { [key in string]: string }) => {
    try {
        return await fetch(url, {
            method: "get",
            headers: headers ? new Headers(headers) : undefined,
        }).then(async res => ({
            data: await res.json(),
            statusCode: res.status,
            statusText: res.statusText,
        }));
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
        throw err;
    }
};

export const POST_HEADERS: RequestInit = {
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
};

export const fetchPostJSON = async (url: string, data: unknown) => {
    try {
        // Default options are marked with *
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
            ...POST_HEADERS,
            headers: {
                "Content-Type": "application/json",
            },
        });

        return {
            data: await response.json(), // parses JSON response into native JavaScript objects,
            statusCode: response.status,
            statusText: response.statusText,
        };
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
        throw err;
    }
};
