import { Organization } from "./../../graphql/schema";
import { runESQueryFull } from "@/fetchFunctions/elasticsearch";
import {
    generateReferralCode,
    manuallyVerifyUser,
    registerNotificationToken,
    requestDeleteAccount,
    updateEmail,
    updateNotificationToken,
    updateUserDetails,
    updateUsername,
    verifyStripeCheckout,
} from "@/graphql/mutations";
import { getCurrentUserAndOrganization, isUsernameAvailable } from "@/graphql/queries";
import { ServerClientWithCookies, client, now } from "@/utils/SyncUtils";

import { platform } from "@/platform";
import {
    NotificationLevel,
    NotificationTokenOSType,
    NotificationTokenType,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { fromEntries } from "@/utils/genericUtils";
import { LocalAuthUser, LocalUnauthUser, LocalUser } from "./types";

export const callUpdateUserDetails = updates => {
    return client
        .mutate({
            mutation: updateUserDetails,
            variables: { input: updates },
        })
        .then(({ data }) => data.updateUserDetails);
};

export const generateLocalUser = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}) => {
    if (!user) {
        return generateUnauthUser();
    }

    return generateAuthUser({ user, organization });
};

export const generateAuthUser = ({
    user,
    organization,
}: {
    user: UserDetails;
    organization: Organization | null | undefined;
}) => {
    return {
        user,
        organization,
        serverSyncTime: now(),
    } as LocalAuthUser;
};

export const generateUnauthUser = () => {
    return {
        user: null,
        organization: null,
        serverSyncTime: now(),
    } as LocalUnauthUser;
};

export const getAuthenticatedUser = async ({
    forceStripeVerify = false,
    serverClient,
}: {
    forceStripeVerify?: boolean;
    serverClient?: ServerClientWithCookies;
} = {}): Promise<LocalUser> => {
    try {
        const { user, organization } = await client
            .query({
                query: getCurrentUserAndOrganization,
                variables: { input: { forceStripeVerify } },
                serverClient,
            })
            .then(({ data }) => data.getCurrentUserAndOrganization);

        if (!user) {
            return generateUnauthUser();
        }

        return generateAuthUser({ user, organization });
    } catch (e) {
        if (e.errors[0].errorType !== "Unauthorized") {
            // eslint-disable-next-line no-console
            console.log("ERROR FETCHING USER", e);
        }
        return generateUnauthUser();
    }
};

export const callUpdateUserEmail = ({ newEmail, password }) =>
    client
        .mutate({
            mutation: updateEmail,
            variables: { input: { newEmail: newEmail, password } },
        })
        .then(({ data }) => data.updateEmail);

export const callUpdateUsername = async (username: string) => {
    return await client.mutate({
        mutation: updateUsername,
        variables: { input: { username } },
    });
};

export const callIsUsernameAvailable = async username => {
    return await client
        .query({
            query: isUsernameAvailable,
            variables: { input: { username } },
        })
        .then(({ data }) => data.isUsernameAvailable);
};

export const isSomeBasicInfoMissing = (user: UserDetails) => {
    if (!user) return false;

    const basicInfo = ["accountType", "username", "birthday"];
    return basicInfo.some(info => !user[info]);
};

/**
 * Takes in a list of user IDs and returns an object of public data per userId
 * @param serverClient: Server client to be used from server side
 */
export const fetchUsersInfo = async ({
    userIds,
    serverClient,
}: {
    userIds: string[];
    serverClient?: ServerClientWithCookies;
}) => {
    if (!userIds || !userIds.length) {
        return {};
    }

    // Find IDs that still need to be sent via API request
    const usersInfo = (
        await runESQueryFull({
            queryFields: ["ID"],
            queryPhrase: userIds,
            returnFields: [
                "Name",
                "pictureUrl",
                "profileColor",
                "ID",
                "numFollowers",
                "numFollowing",
                "username",
                "bio",
                "accountType",
                "subscriptionType",
                "creator",
                "verified",
                "schoolId",
                "rating",
                "ratingCount",
                "hideSensitiveInfo",
                "socials",
                "lastLogIn",
                "cover",
            ],
            searchIndex: ["USER"],
            pagesize: 25,
            serverClient,
        })
    ).items as UserDetails[];

    return fromEntries(usersInfo.map(user => [user.ID, user])) as Record<string, UserDetails>;
};

export const callVerifyStripeCheckout = async (customerId: string, serverClient?: ServerClientWithCookies) => {
    return await client
        .mutate({
            mutation: verifyStripeCheckout,
            variables: {
                input: {
                    customerId,
                },
            },
            serverClient,
        })
        .then(({ data }) => data.verifyStripeCheckout)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "verifyStripeCheckout", { customerId });
            throw error;
        });
};

export const callRequestDeleteAccount = async ({ userId, category, followUp, reason, deleteReq }) => {
    const input = {
        userId,
        category,
        followUp,
        reason,
        deleteReq,
    };

    return await client
        .mutate({
            mutation: requestDeleteAccount,
            variables: { input },
        })
        .then(async () => {
            const mixpanel = await platform.analytics.mixpanel();
            mixpanel.track("Delete Account - Requested", {
                category,
                followUp,
                reason,
                isRequestDelete: deleteReq,
            });
            // TODO (App Router): server side can't call signout() since its in a file that imports client stuff
            // signOut();
        })
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "requestDeleteAccount", input);
            throw error;
        });
};

export const callRegisterNotificationToken = async ({
    userId,
    os,
    tokenId,
}: {
    userId: string;
    os: NotificationTokenOSType;
    tokenId: string;
}) => {
    const input = {
        userId,
        os,
        tokenId,
        active: 1,
        type: NotificationTokenType.PUSH,
        level: NotificationLevel.FULL,
        created: now(),
        updated: now(),
    };

    return await client
        .mutate({
            mutation: registerNotificationToken,
            variables: { input },
        })
        .then(async () => {
            const mixpanel = await platform.analytics.mixpanel();
            mixpanel.track("Register notification token - Requested", {
                userId,
                os,
            });
        })
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "registerNotificationToken", input);
            throw error;
        });
};

export const invalidatePushNotificationToken = async ({ userId, tokenId }: { userId: string; tokenId: string }) => {
    const input = {
        userId,
        tokenId,
        active: 0,
    };

    return await client
        .mutate({
            mutation: updateNotificationToken,
            variables: { input },
        })
        .then(async () => {
            const mixpanel = await platform.analytics.mixpanel();
            mixpanel.track("Invalidate notification token - Requested", {
                userId,
            });
        })
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "invalidatePushNotificationToken", input);
            throw error;
        });
};

export const callManuallyVerifyUser = async (username: string) => {
    return await client
        .mutate({
            mutation: manuallyVerifyUser,
            variables: { input: { username } },
        })
        .then(({ data }) => data.manuallyVerifyUser)
        .catch(() => {
            // so that authSafe() can catch this error and try again with a different username
            throw new Error("UserNotFoundException");
        });
};

export const callGenerateReferralCode = async () => {
    return await client
        .mutate({
            mutation: generateReferralCode,
            variables: {},
        })
        .then(({ data }) => data.generateReferralCode);
};
