import { SubscriptionType } from "@knowt/syncing/graphql/schema";

export enum StripeStatus {
    INCOMPLETE = "incomplete",
    ACTIVE = "active",
    PAST_DUE = "past_due",
    CANCELLED = "canceled",
    TRIALING = "trialing",
    UNPAID = "unpaid",
}

export const enum PRICE_ID {
    ADFREE_MONTHLY_2 = "price_1MYEghKXJM8qxW7RXFMYKJrN",
    ADFREE_YEARLY_10 = "price_1MYEghKXJM8qxW7RjOf8sVWh",
    ADFREE_YEARLY_12 = "price_1NlXXgKXJM8qxW7RTQdhKQeN",
    PRO_MONTHLY_3 = "price_1MpAckKXJM8qxW7R4mDjj3AY",
    PRO_MONTHLY_5 = "price_1NkQSvKXJM8qxW7RF9yW421Y",
    PRO_YEARLY_26 = "price_1MpAepKXJM8qxW7RNih8tRlD",
    PRO_YEARLY_36 = "price_1NkQS1KXJM8qxW7RVOsBZ30G",
    LIMITLESS_MONTHLY_8 = "price_1MpAfPKXJM8qxW7R5CYpcqkh",
    LIMITLESS_MONTHLY_10 = "price_1NkQTWKXJM8qxW7Rr1nSmbOh",
    LIMITLESS_MONTHLY_13 = "price_1O9qZoKXJM8qxW7RGJuSkxJE",
    LIMITLESS_YEARLY_120 = "price_1O9qZAKXJM8qxW7R2mKRUJeP",
}

export type PaymentFrequency = "Month" | "Year";

export type PricingOption = {
    type: "Basic" | "Ad Free" | "Pro" | "Limitless";
    subscriptionType: SubscriptionType;
    interval: PaymentFrequency;
    price: string;
    priceId?: PRICE_ID;
    oldPrice?: string;
    pricePerMonth?: string;
};

type OPTION =
    | "MONTHLY_BASIC"
    | "MONTHLY_ADFREE"
    | "ANNUALLY_ADFREE"
    | "MONTHLY_PRO"
    | "ANNUALLY_PRO"
    | "MONTHLY_LIMITLESS"
    | "ANNUALLY_LIMITLESS";

export const PRICING_OPTIONS: Record<OPTION, PricingOption> = {
    MONTHLY_BASIC: {
        type: "Basic",
        subscriptionType: SubscriptionType.BASIC,
        interval: "Month",
        price: "$0",
    },
    MONTHLY_ADFREE: {
        type: "Ad Free",
        subscriptionType: SubscriptionType.SUPPORTER,
        interval: "Month",
        price: "$1.99",
        priceId: PRICE_ID.ADFREE_MONTHLY_2,
    },
    ANNUALLY_ADFREE: {
        type: "Ad Free",
        subscriptionType: SubscriptionType.SUPPORTER,
        interval: "Year",
        price: "$11.88",
        pricePerMonth: "$0.99",
        oldPrice: "$1.99",
        priceId: PRICE_ID.ADFREE_YEARLY_12,
    },
    MONTHLY_PRO: {
        type: "Pro",
        subscriptionType: SubscriptionType.PRO,
        interval: "Month",
        price: "$4.99",
        priceId: PRICE_ID.PRO_MONTHLY_5,
    },
    ANNUALLY_PRO: {
        type: "Pro",
        subscriptionType: SubscriptionType.PRO,
        interval: "Year",
        price: "$35.88",
        pricePerMonth: "$2.99",
        oldPrice: "$4.99",
        priceId: PRICE_ID.PRO_YEARLY_36,
    },
    MONTHLY_LIMITLESS: {
        type: "Limitless",
        subscriptionType: SubscriptionType.LIMITLESS,
        interval: "Month",
        price: "$9.99",
        priceId: PRICE_ID.LIMITLESS_MONTHLY_10,
    },
    ANNUALLY_LIMITLESS: {
        type: "Limitless",
        subscriptionType: SubscriptionType.LIMITLESS,
        interval: "Year",
        price: "$119.88",
        pricePerMonth: "$9.99",
        oldPrice: "$12.99",
        priceId: PRICE_ID.LIMITLESS_YEARLY_120,
    },
};

export const hasFreeTrial = (priceId?: string) =>
    [
        PRICE_ID.ADFREE_YEARLY_12,
        PRICE_ID.PRO_YEARLY_36,
        PRICE_ID.LIMITLESS_MONTHLY_10,
        PRICE_ID.LIMITLESS_YEARLY_120,
    ].includes(priceId as PRICE_ID);
