const storage = {
    FEDERATE_AUTH_REDIRECT: "FEDERATE_AUTH_REDIRECT",
    forcefullyCloseExamSelectionPopup: (userId?: string) => {
        return "forceDontShowExamSelectionPopup-" + (userId ?? "");
    },
    SPACED_MODE_ENABLED_FOR_BELOW_30_DAYS: "SPACED_MODE_ENABLED_FOR_BELOW_30_DAYS",
    HOME_PAGE_IS_TOP_SECTION_COLLAPSABLE: "home-page-is-top-section-collapsable",
    HOME_PAGE_SORT_AND_DIRECTION: "home-page-sort-and-direction",
    HOME_PAGE_TAB: "home-page-tab",
    HOME_PAGE_FILES_TAB: "home-page-files-tab",
    CLASSES_PAGE_TAB: (classId: string) => `class-${classId}-page-tab`,
    CLASSES_PAGE_PROGRESS_FLASHCARD_SET_ID: (classId: string) => `class-${classId}-page-progress-flashcard-set`,
    CLASSES_PAGE_SORT_AND_DIRECTION: (classId: string) => `class-${classId}-page-sort-and-direction`,
    SEARCH_PAGE_SORT: "search-page-sort",
    SEARCH_PAGE_DIRECTION: "search-page-direction",
    SUBJECT_PAGE_SORT: "subject-page-sort",
    SUBJECT_PAGE_DIRECTION: "subject-page-direction",
    LEARN_MODE_PAGE_SURVEY_ANSWERED: "learn-mode-page-survey-answered",
    MEDIA_UPLOADED_DATA: "mediaUploadedData",
    MEDIA_UPLOADED_METADATA: "mediaUploadedMetadata",
    SKIP_INTRO_POPUPS: "skip-intro-popups",
    LANDING_PAGE_NOTE_DATA: "landingPageNoteData",
    LANDING_PAGE_QUIZLET_IMPORT_LINK: "landingPageQuizletImportLink",
    LANDING_PAGE_VIDEO_SUMMARIZE_LINK: "landingPageVideoSummarizeLink",
    MIGRATE_ACCOUNT_CONTENT: "migrateAccountContent",
};

export type MediaUploadedData =
    | {
          mediaId: string;
          extension: string;
          bucket: string;
      }
    | null
    | undefined;

export type MediaUploadedMetadata =
    | {
          title: string;
          description: string;
          subject: string;
          topic: string;
          exam_v2: string;
          examUnit: string;
          examSection: string;
      }
    | null
    | undefined;

export type LandingPageNoteData =
    | {
          title: string;
          content: string;
      }
    | null
    | undefined;

export type LandingPageQuizletImportLink =
    | {
          quizletUrl: string;
      }
    | null
    | undefined;

export type LandingPageVideoSummarizeLink =
    | {
          videoUrl: string;
      }
    | null
    | undefined;

export default storage;
