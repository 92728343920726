import { DOMSerializer, Schema } from "../../pm/model";
import ExtensionManager from "../ExtensionManager";
import { MarkdownParser } from "../../pm/markdown";
import Bold from "../marks/Bold";
import { BlueHighlight, GreenHighlight, OrangeHighlight, RedHighlight, YellowHighlight } from "../marks/highlights";
import Italic from "../marks/Italic";
import Strikethrough from "../marks/Strikethrough";
import Underline from "../marks/Underline";
import Link from "../marks/Link";
import Code from "../marks/Code";
import Doc from "../nodes/Doc";
import Text from "../nodes/Text";
import Paragraph from "../nodes/Paragraph";
import Heading from "../nodes/Heading";
import OrderedList from "../nodes/OrderedList";
import BulletList from "../nodes/BulletList";
import ListItem from "../nodes/ListItem";
import HardBreak from "../nodes/HardBreak";
import Blockquote from "../nodes/Blockquote";
import CodeBlock from "../nodes/CodeBlock";
import CodeFence from "../nodes/CodeFence";
import { serializeToHTML } from "../helpers/serializeToHtml";
import Emoji from "../nodes/Emoji";

class ParsingTools {
    private static instance: ParsingTools;

    readonly domSerializer: DOMSerializer;
    readonly markdownParser: MarkdownParser;

    private constructor() {
        const extensions = new ExtensionManager([
            new Doc(),
            new Paragraph(),
            new Text(),
            new Link(),
            new BulletList(),
            new OrderedList(),
            new ListItem({ includeDrag: false }),
            new Heading({ softToDOM: true }),
            new Blockquote(),
            new CodeBlock({ softToDOM: true }),
            new CodeFence(),
            new OrangeHighlight(),
            new YellowHighlight(),
            new BlueHighlight(),
            new GreenHighlight(),
            new RedHighlight(),
            new Underline(),
            new Strikethrough(),
            new Code(),
            new Bold(),
            new Italic(),
            new HardBreak(),
            new Emoji(),
        ]);

        const schema = new Schema({ nodes: extensions.nodes, marks: extensions.marks });

        this.domSerializer = DOMSerializer.fromSchema(schema);
        this.markdownParser = extensions.parser({ schema, plugins: extensions.rulePlugins });
    }

    public static getInstance(): ParsingTools {
        ParsingTools.instance ||= new ParsingTools();
        return ParsingTools.instance;
    }
}

export const flashcardMdToHtml = (text: string) => {
    const doc = parseFlashcardMd(text, ParsingTools.getInstance().markdownParser);
    return serializeToHTML({ doc, domSerializer: ParsingTools.getInstance().domSerializer });
};

export const parseFlashcardMd = (md: string, mdParser: MarkdownParser) => {
    try {
        return mdParser.parse(md);
    } catch (error) {
        return mdParser.parse(normalizeFlashcardText(md));
    }
};

/**
 * Because our flashcard editor does not support all markdown nodes,
 * it will raise an error everytime it tries to parse a node that is not present in our schema,
 * so to prevent errors, we escape unsupported markdown syntax.
 */
const normalizeFlashcardText = (text: string) => {
    if (!text) return "";

    return text
        .split("\n")
        .map(line => cleanFlashcardSpecialChars(line.trim()) + "\n")
        .join("");
};

const cleanFlashcardSpecialChars = (rawText: string) => {
    if (!rawText) return "";

    const text = rawText
        // insert a backslash where each occurrence of these special characters occur
        .replace(/(#+)|(>+)|(`)|(~{3,})|(_{3,})|(\*{3,})|(-{3,})|(=+)|(-\s-)|(~~\s)|(__\s)|(\*\*\s)|(--\s)+/g, "\\$&")
        // same concept for things that look like an image
        .replace(/(!\[)+/g, "!\\[");

    if (text.startsWith("[") || text === "-") {
        return "\\" + text;
    }

    return text;
};
