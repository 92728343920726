import { MarkSpec } from "../../pm/model";
import Mark from "./Mark";

export default class Bold extends Mark {
    get name() {
        return "strong";
    }

    get schema(): MarkSpec {
        return {
            parseDOM: [
                { tag: "b" },
                { tag: "strong" },
                { style: "font-style", getAttrs: value => value === "bold" },
                {
                    style: "font-weight",
                    getAttrs: value => ["700", "800", "900", "bold", "bolder"].includes(value),
                },
            ],
            toDOM: () => ["strong"],
        };
    }

    get toMarkdown() {
        return {
            open: "**",
            close: "**",
            mixable: true,
            expelEnclosingWhitespace: true,
            escape: false,
        };
    }

    parseMarkdown() {
        return { mark: "strong" };
    }
}
