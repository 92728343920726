import { platform } from "@/platform";
import { TIME } from "@/utils/dateTimeUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { fetchAuthSession } from "aws-amplify/auth";
import { mutate } from "swr";
import { callGenerateReferralCode, callUpdateUserDetails, callUpdateUsername } from "./graphqlUtils";
import { CACHED_USER_KEY, LocalAuthUser } from "./types";

export const amplifyAuthOverride = {
    API: {
        GraphQL: {
            headers: async () => {
                const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();

                if (!idToken) return {};
                return {
                    Authorization: idToken,
                };
            },
        },
    },
};

export const updateUserUsername = async (username: string) => {
    await callUpdateUsername(username);
    const storage = await platform.storage();
    await storage.remove(CACHED_USER_KEY);

    await mutate("getCurrentUser");
};

export const updateUserInfo = async (updateInfo: Partial<UserDetails>) => {
    const user = await callUpdateUserDetails(updateInfo).catch(async error => {
        const { report } = await platform.analytics.logging();
        report(error, "updateUserDetails", { updateInfo });
        throw error;
    });

    // optimistic update
    mutate("getCurrentUser", async (data: LocalAuthUser) => ({
        ...data,
        user,
    }));

    const storage = await platform.storage();
    await storage.remove(CACHED_USER_KEY);

    return user;
};

export const isUnderAge = (dateOfBirth: string | Date | null | undefined, age: number) => {
    if (!dateOfBirth) return false;

    const curr = new Date();
    const date = dateOfBirth instanceof Date ? dateOfBirth : new Date(dateOfBirth);
    const diff = curr.getTime() - date.getTime();
    return diff < age * TIME.YEAR;
};

export const getUpdateUsernameErrorMessage = async error => {
    if (/profanity/i.test(error.message) || /bad words/i.test(error.message)) {
        return "Bad word detected in this username. Please remove it and try again.";
    } else if (/exists/i.test(error.message)) {
        return "Username in use!";
    } else if (/disallowed_word/i.test(error.message)) {
        return "Username can only contain letters (a-z), numbers (0-9), and periods (.).";
    } else if (/3 letters/i.test(error.message)) {
        return "Username minimum length is 3";
    } else if (/lower case/i.test(error.message)) {
        return "Username can only contain lowercase letters";
    }

    const { report } = await platform.analytics.logging();
    report(error, "getUpdateUsernameErrorMessage");
    return "Something went wrong. Please try again.";
};

export const generateReferralCode = async () => {
    const user = await callGenerateReferralCode();

    mutate(
        "getCurrentUser",
        async (data: LocalAuthUser) => ({
            ...data,
            user,
        }),
        {
            revalidate: false,
        }
    );
};
