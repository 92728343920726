import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { themeColors } from "@/utils/themeColors";

const ConditionalTooltip = ({
    children,
    tooltip,
    placement = "top",
}: {
    children: React.ReactNode;
    tooltip?: string;
    placement?: "top" | "bottom" | "left" | "right";
}) => {
    if (!tooltip) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }
    return (
        <Tooltip
            title={tooltip}
            placement={placement}
            TransitionComponent={Zoom}
            arrow
            slotProps={{
                tooltip: {
                    sx: {
                        color: themeColors.neutralBlack,
                        backgroundColor: themeColors.neutral1,
                        fontSize: "1.2rem",
                    },
                },
            }}>
            {children}
        </Tooltip>
    );
};

export default ConditionalTooltip;
