import ExtensionManager from "../ExtensionManager";
import Doc from "../nodes/Doc";
import Text from "../nodes/Text";
import HardBreak from "../nodes/HardBreak";
import Paragraph from "../nodes/Paragraph";
import Blockquote from "../nodes/Blockquote";
import Emoji from "../nodes/Emoji";
import BulletList from "../nodes/BulletList";
import CodeBlock from "../nodes/CodeBlock";
import CodeFence from "../nodes/CodeFence";
import CheckboxList from "../nodes/CheckboxList";
import OrderedList from "../nodes/OrderedList";
import CheckboxItem from "../nodes/CheckboxItem";
import Embed from "../nodes/Embed";
import ListItem from "../nodes/ListItem";
import Notice from "../nodes/Notice";
import Heading from "../nodes/Heading";
import HorizontalRule from "../nodes/HorizontalRule";
import Image from "../nodes/Image";
import Table from "../nodes/Table";
import TableCell from "../nodes/TableCell";
import TableHeadCell from "../nodes/TableHeadCell";
import TableRow from "../nodes/TableRow";
import {
    BlueBackground,
    RedBackground,
    OrangeBackground,
    YellowBackground,
    GreenBackground,
} from "../marks/backgrounds";
import { BlueHighlight, RedHighlight, OrangeHighlight, YellowHighlight, GreenHighlight } from "../marks/highlights";
import Underline from "../marks/Underline";
import Strikethrough from "../marks/Strikethrough";
import Bold from "../marks/Bold";
import Italic from "../marks/Italic";
import Link from "../marks/Link";
import Code from "../marks/Code";
import { DOMSerializer, Schema } from "../../pm/model";
import { serializeToHTML } from "../helpers/serializeToHtml";
import { MarkdownParser } from "../../pm/markdown";

class ParsingTools {
    private static instance: ParsingTools;

    readonly domSerializer: DOMSerializer;
    readonly markdownParser: MarkdownParser;

    private constructor() {
        const extensions = new ExtensionManager([
            new Doc(),
            new Text(),
            new HardBreak(),
            new Paragraph(),
            new Blockquote(),
            new Emoji(),
            new BulletList(),
            new CodeBlock(),
            new CodeFence(),
            new CheckboxList(),
            new OrderedList(),
            new CheckboxItem(),
            new Embed(),
            new ListItem(),
            new Notice(),
            new Heading(),
            new HorizontalRule(),
            new Image(),
            new Table(),
            new TableCell(),
            new TableHeadCell(),
            new TableRow(),
            new BlueBackground(),
            new RedBackground(),
            new OrangeBackground(),
            new YellowBackground(),
            new GreenBackground(),
            new OrangeHighlight(),
            new YellowHighlight(),
            new BlueHighlight(),
            new GreenHighlight(),
            new RedHighlight(),
            new Underline(),
            new Strikethrough(),
            new Code(),
            new Bold(),
            new Italic(),
            new Link(),
        ]);

        const schema = new Schema({ nodes: extensions.nodes, marks: extensions.marks });

        this.domSerializer = DOMSerializer.fromSchema(schema);
        this.markdownParser = extensions.parser({ schema, plugins: extensions.rulePlugins });
    }

    static getInstance() {
        ParsingTools.instance ||= new ParsingTools();
        return ParsingTools.instance;
    }
}

const parseMarkdown = (markdown: string) => {
    return ParsingTools.getInstance().markdownParser.parse(markdown);
};

export const noteMdToHtml = (content: string) => {
    const doc = parseMarkdown(content);
    return serializeToHTML({ doc, domSerializer: ParsingTools.getInstance().domSerializer });
};
