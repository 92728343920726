import { noticeRule } from "../rules";
import Node from "./Node";

export default class Notice extends Node {
    get name() {
        return "container_notice";
    }

    get rulePlugins() {
        return [noticeRule];
    }

    get schema() {
        return {
            attrs: {
                style: {
                    default: "info",
                },
            },
            content: "block+",
            group: "block",
            defining: true,
            draggable: true,
            parseDOM: [
                {
                    tag: "div.notice-block",
                    preserveWhitespace: "full",
                    contentElement: "div:last-child",
                    getAttrs: (dom: HTMLDivElement) => ({
                        style: dom.className.includes("tip")
                            ? "tip"
                            : dom.className.includes("warning")
                            ? "warning"
                            : undefined,
                    }),
                },
            ],
            toDOM: node => {
                return ["div", { class: `notice-block ${node.attrs.style}` }, "div", ["div", { class: "content" }, 0]];
            },
        };
    }

    toMarkdown(state, node) {
        state.write("\n:::" + (node.attrs.style || "info") + "\n");
        state.renderContent(node);
        state.ensureNewLine();
        state.write(":::");
        state.closeBlock(node);
    }

    parseMarkdown() {
        return {
            block: "container_notice",
            getAttrs: tok => ({ style: tok.info }),
        };
    }
}
