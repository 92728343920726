/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./schema";
type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
};

export const createNote = /* GraphQL */ `mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    textbookId
    chapterId
    views
    verified
    classPublic
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.CreateNoteMutationVariables, APITypes.CreateNoteMutation>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    textbookId
    chapterId
    views
    verified
    classPublic
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.DeleteNoteMutationVariables, APITypes.DeleteNoteMutation>;
export const updateNote = /* GraphQL */ `mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    textbookId
    chapterId
    views
    verified
    classPublic
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.UpdateNoteMutationVariables, APITypes.UpdateNoteMutation>;
export const generateMediaTranscription =
    /* GraphQL */ `mutation GenerateMediaTranscription($input: GenerateMediaTranscriptionInput!) {
  generateMediaTranscription(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<
        APITypes.GenerateMediaTranscriptionMutationVariables,
        APITypes.GenerateMediaTranscriptionMutation
    >;
export const createMediaWithTranscription = /* GraphQL */ `mutation CreateMediaWithTranscription(
  $input: CreateMediaWithTranscriptionInput!
) {
  createMediaWithTranscription(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<
    APITypes.CreateMediaWithTranscriptionMutationVariables,
    APITypes.CreateMediaWithTranscriptionMutation
>;
export const importFromLink = /* GraphQL */ `mutation ImportFromLink($input: ImportFromLinkInput!) {
  importFromLink(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.ImportFromLinkMutationVariables, APITypes.ImportFromLinkMutation>;
export const duplicateMedia = /* GraphQL */ `mutation DuplicateMedia($input: DuplicateMediaInput!) {
  duplicateMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.DuplicateMediaMutationVariables, APITypes.DuplicateMediaMutation>;
export const updateMedia = /* GraphQL */ `mutation UpdateMedia($input: UpdateMediaInput!) {
  updateMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.UpdateMediaMutationVariables, APITypes.UpdateMediaMutation>;
export const deleteMedia = /* GraphQL */ `mutation DeleteMedia($input: DeleteMediaInput!) {
  deleteMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.DeleteMediaMutationVariables, APITypes.DeleteMediaMutation>;
export const createFlashcardSet = /* GraphQL */ `mutation CreateFlashcardSet($input: CreateFlashcardSetInput!) {
  createFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardSetMutationVariables, APITypes.CreateFlashcardSetMutation>;
export const updateFlashcardSet = /* GraphQL */ `mutation UpdateFlashcardSet($input: UpdateFlashcardSetInput!) {
  updateFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.UpdateFlashcardSetMutationVariables, APITypes.UpdateFlashcardSetMutation>;
export const deleteFlashcardSet = /* GraphQL */ `mutation DeleteFlashcardSet($input: DeleteFlashcardSetInput!) {
  deleteFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.DeleteFlashcardSetMutationVariables, APITypes.DeleteFlashcardSetMutation>;
export const createFlashcard = /* GraphQL */ `mutation CreateFlashcard($input: FlashcardInput!) {
  createFlashcard(input: $input) {
    flashcardId
    flashcardSetId
    userId
    term
    termAudio
    definition
    definitionAudio
    distractorIds
    questionType
    isCorrect
    draft
    trash
    disabled
    image
    secondaryImage
    edited
    created
    updated
    twoSided
    schedule
    quality
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardMutationVariables, APITypes.CreateFlashcardMutation>;
export const batchUpdateFlashcard = /* GraphQL */ `mutation BatchUpdateFlashcard($input: FlashcardListInput!) {
  batchUpdateFlashcard(input: $input) {
    items {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.BatchUpdateFlashcardMutationVariables, APITypes.BatchUpdateFlashcardMutation>;
export const deleteFlashcard = /* GraphQL */ `mutation DeleteFlashcard($input: FlashcardInput!) {
  deleteFlashcard(input: $input) {
    flashcardId
    flashcardSetId
    userId
    term
    termAudio
    definition
    definitionAudio
    distractorIds
    questionType
    isCorrect
    draft
    trash
    disabled
    image
    secondaryImage
    edited
    created
    updated
    twoSided
    schedule
    quality
  }
}
` as GeneratedMutation<APITypes.DeleteFlashcardMutationVariables, APITypes.DeleteFlashcardMutation>;
export const markAsRead = /* GraphQL */ `mutation MarkAsRead($input: NotificationInput!) {
  markAsRead(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.MarkAsReadMutationVariables, APITypes.MarkAsReadMutation>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification($input: NotificationInput!) {
  deleteNotification(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.DeleteNotificationMutationVariables, APITypes.DeleteNotificationMutation>;
export const updateUserDetails = /* GraphQL */ `mutation UpdateUserDetails($input: UserDetailsInput!) {
  updateUserDetails(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.UpdateUserDetailsMutationVariables, APITypes.UpdateUserDetailsMutation>;
export const follow = /* GraphQL */ `mutation Follow($input: FollowInput!) {
  follow(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.FollowMutationVariables, APITypes.FollowMutation>;
export const unfollow = /* GraphQL */ `mutation Unfollow($input: UnfollowInput!) {
  unfollow(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.UnfollowMutationVariables, APITypes.UnfollowMutation>;
export const removeFollower = /* GraphQL */ `mutation RemoveFollower($input: RemoveFollowerInput!) {
  removeFollower(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.RemoveFollowerMutationVariables, APITypes.RemoveFollowerMutation>;
export const updateUsername = /* GraphQL */ `mutation UpdateUsername($input: UpdateUsernameInput!) {
  updateUsername(input: $input) {
    userId
    pictureUrl
    Name
    accountType
    username
    Email
  }
}
` as GeneratedMutation<APITypes.UpdateUsernameMutationVariables, APITypes.UpdateUsernameMutation>;
export const view = /* GraphQL */ `mutation View($input: ViewInput!) {
  view(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.ViewMutationVariables, APITypes.ViewMutation>;
export const bookmark = /* GraphQL */ `mutation Bookmark($input: CreateBookmarkInput!) {
  bookmark(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.BookmarkMutationVariables, APITypes.BookmarkMutation>;
export const unbookmark = /* GraphQL */ `mutation Unbookmark($input: DeleteBookmarkInput!) {
  unbookmark(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.UnbookmarkMutationVariables, APITypes.UnbookmarkMutation>;
export const createSchool = /* GraphQL */ `mutation CreateSchool($input: SchoolInput) {
  createSchool(input: $input) {
    schoolId
    name
    city
    state
    country
    type
  }
}
` as GeneratedMutation<APITypes.CreateSchoolMutationVariables, APITypes.CreateSchoolMutation>;
export const createCourse = /* GraphQL */ `mutation CreateCourse($input: CreateCourseInput) {
  createCourse(input: $input) {
    courseId
    schoolId
    name
    description
    subject
    topic
  }
}
` as GeneratedMutation<APITypes.CreateCourseMutationVariables, APITypes.CreateCourseMutation>;
export const createFolder = /* GraphQL */ `mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.CreateFolderMutationVariables, APITypes.CreateFolderMutation>;
export const updateFolder = /* GraphQL */ `mutation UpdateFolder($input: UpdateFolderInput!) {
  updateFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.UpdateFolderMutationVariables, APITypes.UpdateFolderMutation>;
export const deleteFolder = /* GraphQL */ `mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.DeleteFolderMutationVariables, APITypes.DeleteFolderMutation>;
export const createRating = /* GraphQL */ `mutation CreateRating($input: CreateRatingInput!) {
  createRating(input: $input) {
    noteId
    userId
    noteOwnerId
    message
    rating
  }
}
` as GeneratedMutation<APITypes.CreateRatingMutationVariables, APITypes.CreateRatingMutation>;
export const updateRating = /* GraphQL */ `mutation UpdateRating($input: UpdateRatingInput!) {
  updateRating(input: $input) {
    noteId
    userId
    noteOwnerId
    message
    rating
  }
}
` as GeneratedMutation<APITypes.UpdateRatingMutationVariables, APITypes.UpdateRatingMutation>;
export const createFlashcardSetViewer =
    /* GraphQL */ `mutation CreateFlashcardSetViewer($input: FlashcardSetViewerInput!) {
  createFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardSetViewerMutationVariables, APITypes.CreateFlashcardSetViewerMutation>;
export const updateFlashcardSetViewer =
    /* GraphQL */ `mutation UpdateFlashcardSetViewer($input: FlashcardSetViewerInput!) {
  updateFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedMutation<APITypes.UpdateFlashcardSetViewerMutationVariables, APITypes.UpdateFlashcardSetViewerMutation>;
export const migrateFlashcardSet = /* GraphQL */ `mutation MigrateFlashcardSet($input: GetFlashcardSetInput!) {
  migrateFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.MigrateFlashcardSetMutationVariables, APITypes.MigrateFlashcardSetMutation>;
export const migrateUserFlashcardSets = /* GraphQL */ `mutation MigrateUserFlashcardSets($input: UserIdInput!) {
  migrateUserFlashcardSets(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedMutation<APITypes.MigrateUserFlashcardSetsMutationVariables, APITypes.MigrateUserFlashcardSetsMutation>;
export const verifyStripeCheckout = /* GraphQL */ `mutation VerifyStripeCheckout($input: StripeConnectionInput!) {
  verifyStripeCheckout(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.VerifyStripeCheckoutMutationVariables, APITypes.VerifyStripeCheckoutMutation>;
export const requestDeleteAccount = /* GraphQL */ `mutation RequestDeleteAccount($input: DeleteAccountRequestInput!) {
  requestDeleteAccount(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.RequestDeleteAccountMutationVariables, APITypes.RequestDeleteAccountMutation>;
export const registerNotificationToken =
    /* GraphQL */ `mutation RegisterNotificationToken($input: CreateNotificationTokenInput!) {
  registerNotificationToken(input: $input) {
    userId
    tokenId
    active
    type
    created
    updated
    os
    level
  }
}
` as GeneratedMutation<APITypes.RegisterNotificationTokenMutationVariables, APITypes.RegisterNotificationTokenMutation>;
export const updateNotificationToken =
    /* GraphQL */ `mutation UpdateNotificationToken($input: UpdateNotificationTokenInput!) {
  updateNotificationToken(input: $input) {
    userId
    tokenId
    active
    type
    created
    updated
    os
    level
  }
}
` as GeneratedMutation<APITypes.UpdateNotificationTokenMutationVariables, APITypes.UpdateNotificationTokenMutation>;
export const updateNotificationTokenType = /* GraphQL */ `mutation UpdateNotificationTokenType(
  $input: UpdateNotificationTokenTypeInput!
) {
  updateNotificationTokenType(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<
    APITypes.UpdateNotificationTokenTypeMutationVariables,
    APITypes.UpdateNotificationTokenTypeMutation
>;
export const deleteNotificationToken =
    /* GraphQL */ `mutation DeleteNotificationToken($input: DeleteNotificationTokenInput!) {
  deleteNotificationToken(input: $input) {
    userId
    tokenId
    active
    type
    created
    updated
    os
    level
  }
}
` as GeneratedMutation<APITypes.DeleteNotificationTokenMutationVariables, APITypes.DeleteNotificationTokenMutation>;
export const sendImmediateNotification =
    /* GraphQL */ `mutation SendImmediateNotification($input: SendImmediateNotificationInput!) {
  sendImmediateNotification(input: $input) {
    userId
    notificationId
    read
    trash
    timestamp
    scheduledAt
    color
    image
    title
    message
    action
    level
    usage
  }
}
` as GeneratedMutation<APITypes.SendImmediateNotificationMutationVariables, APITypes.SendImmediateNotificationMutation>;
export const sendScheduledNotification =
    /* GraphQL */ `mutation SendScheduledNotification($input: SendScheduledNotificationInput!) {
  sendScheduledNotification(input: $input) {
    created
    updated
    eventTime
    eventId
    userId
    notifType
    usage
    itemId
    itemType
    completed
    color
    image
    title
    message
    action
    level
  }
}
` as GeneratedMutation<APITypes.SendScheduledNotificationMutationVariables, APITypes.SendScheduledNotificationMutation>;
export const cancelScheduledNotification = /* GraphQL */ `mutation CancelScheduledNotification(
  $input: CancelScheduledNotificationInput!
) {
  cancelScheduledNotification(input: $input) {
    created
    updated
    eventTime
    eventId
    userId
    notifType
    usage
    itemId
    itemType
    completed
    color
    image
    title
    message
    action
    level
  }
}
` as GeneratedMutation<
    APITypes.CancelScheduledNotificationMutationVariables,
    APITypes.CancelScheduledNotificationMutation
>;
export const detectLanguage = /* GraphQL */ `mutation DetectLanguage($input: TextToSpeechInput!) {
  detectLanguage(input: $input) {
    language
    confidence
  }
}
` as GeneratedMutation<APITypes.DetectLanguageMutationVariables, APITypes.DetectLanguageMutation>;
export const textToSpeech = /* GraphQL */ `mutation TextToSpeech($input: TextToSpeechInput!) {
  textToSpeech(input: $input) {
    url
  }
}
` as GeneratedMutation<APITypes.TextToSpeechMutationVariables, APITypes.TextToSpeechMutation>;
export const deleteAudio = /* GraphQL */ `mutation DeleteAudio($input: TextToSpeechInput!) {
  deleteAudio(input: $input) {
    url
  }
}
` as GeneratedMutation<APITypes.DeleteAudioMutationVariables, APITypes.DeleteAudioMutation>;
export const createPassword = /* GraphQL */ `mutation CreatePassword($input: PasswordInput!) {
  createPassword(input: $input) {
    password
    type
    id
  }
}
` as GeneratedMutation<APITypes.CreatePasswordMutationVariables, APITypes.CreatePasswordMutation>;
export const checkPassword = /* GraphQL */ `mutation CheckPassword($input: PasswordInput!) {
  checkPassword(input: $input)
}
` as GeneratedMutation<APITypes.CheckPasswordMutationVariables, APITypes.CheckPasswordMutation>;
export const editChapter = /* GraphQL */ `mutation EditChapter($input: EditChapterInput!) {
  editChapter(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.EditChapterMutationVariables, APITypes.EditChapterMutation>;
export const createBlog = /* GraphQL */ `mutation CreateBlog($input: BlogInput) {
  createBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.CreateBlogMutationVariables, APITypes.CreateBlogMutation>;
export const updateBlog = /* GraphQL */ `mutation UpdateBlog($input: BlogInput) {
  updateBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.UpdateBlogMutationVariables, APITypes.UpdateBlogMutation>;
export const deleteBlog = /* GraphQL */ `mutation DeleteBlog($input: BlogInput) {
  deleteBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.DeleteBlogMutationVariables, APITypes.DeleteBlogMutation>;
export const startFlashcardSetStudySessionRound = /* GraphQL */ `mutation StartFlashcardSetStudySessionRound(
  $input: FlashcardSetStudySessionInput!
) {
  startFlashcardSetStudySessionRound(input: $input) {
    studySession {
      userId
      itemId
      type
      created
      updated
      examDate
      newCards
      nextDue
      nextNewDue
      know
      dKnow
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sort
          review
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      syncTime
      title
    }
    questions {
      flashcardId
      distractors
      questionType
      answerSide
      buttons
      tfIsCorrect
      progress
      spacing {
        AGAIN
        HARD
        GOOD
        EASY
      }
    }
  }
}
` as GeneratedMutation<
    APITypes.StartFlashcardSetStudySessionRoundMutationVariables,
    APITypes.StartFlashcardSetStudySessionRoundMutation
>;
export const gradeFlashcardSetStudySession = /* GraphQL */ `mutation GradeFlashcardSetStudySession(
  $input: GradeFlashcardSetStudySessionInput!
) {
  gradeFlashcardSetStudySession(input: $input) {
    studySession {
      userId
      itemId
      type
      created
      updated
      examDate
      newCards
      nextDue
      nextNewDue
      know
      dKnow
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sort
          review
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      syncTime
      title
    }
    studyStates {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      nextDue
      difficulty
      interval
      eFactor
      sort
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
  }
}
` as GeneratedMutation<
    APITypes.GradeFlashcardSetStudySessionMutationVariables,
    APITypes.GradeFlashcardSetStudySessionMutation
>;
export const clearFlashcardSetStudySession = /* GraphQL */ `mutation ClearFlashcardSetStudySession(
  $input: ClearFlashcardSetStudySessionInput!
) {
  clearFlashcardSetStudySession(input: $input) {
    userId
    itemId
    type
    created
    updated
    examDate
    newCards
    nextDue
    nextNewDue
    know
    dKnow
    settings {
      LEARN {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        length
      }
      TEST {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        length
      }
      SPACED {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        cardsPerSession
        intervals
      }
      MATCH {
        grid
        starred
        length
      }
      REVIEW {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        sort
        review
      }
    }
    progress {
      NEW
      LEARNING
      REVIEWING
      MASTERED
    }
    syncTime
    title
  }
}
` as GeneratedMutation<
    APITypes.ClearFlashcardSetStudySessionMutationVariables,
    APITypes.ClearFlashcardSetStudySessionMutation
>;
export const submitAICompletion = /* GraphQL */ `mutation SubmitAICompletion($input: SubmitAICompletionInput) {
  submitAICompletion(input: $input) {
    userId
    timestamp
    noteId
    flashcardSetId
    flashcardId
    itemId
    itemType
    id
    input
    messages {
      role
      content
    }
    output
    inputTokens
    outputTokens
    subject
    topic
    type
    timeTaken
  }
}
` as GeneratedMutation<APITypes.SubmitAICompletionMutationVariables, APITypes.SubmitAICompletionMutation>;
export const updateEmail = /* GraphQL */ `mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.UpdateEmailMutationVariables, APITypes.UpdateEmailMutation>;
export const manuallyVerifyUser = /* GraphQL */ `mutation ManuallyVerifyUser($input: ManuallyVerifyUserInput!) {
  manuallyVerifyUser(input: $input)
}
` as GeneratedMutation<APITypes.ManuallyVerifyUserMutationVariables, APITypes.ManuallyVerifyUserMutation>;
export const createClass = /* GraphQL */ `mutation CreateClass($input: CreateClassInput!) {
  createClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedMutation<APITypes.CreateClassMutationVariables, APITypes.CreateClassMutation>;
export const updateClass = /* GraphQL */ `mutation UpdateClass($input: UpdateClassInput!) {
  updateClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedMutation<APITypes.UpdateClassMutationVariables, APITypes.UpdateClassMutation>;
export const deleteClass = /* GraphQL */ `mutation DeleteClass($input: DeleteClassInput!) {
  deleteClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedMutation<APITypes.DeleteClassMutationVariables, APITypes.DeleteClassMutation>;
export const addMediaS3UserTag = /* GraphQL */ `mutation AddMediaS3UserTag($input: AddMediaS3UserTagInput!) {
  addMediaS3UserTag(input: $input)
}
` as GeneratedMutation<APITypes.AddMediaS3UserTagMutationVariables, APITypes.AddMediaS3UserTagMutation>;
export const joinClass = /* GraphQL */ `mutation JoinClass($input: JoinClassInput!) {
  joinClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedMutation<APITypes.JoinClassMutationVariables, APITypes.JoinClassMutation>;
export const leaveClass = /* GraphQL */ `mutation LeaveClass($input: LeaveClassInput!) {
  leaveClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedMutation<APITypes.LeaveClassMutationVariables, APITypes.LeaveClassMutation>;
export const generateReferralCode = /* GraphQL */ `mutation GenerateReferralCode {
  generateReferralCode {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.GenerateReferralCodeMutationVariables, APITypes.GenerateReferralCodeMutation>;
export const createEmbeddings = /* GraphQL */ `mutation CreateEmbeddings($input: CreateEmbeddingsInput!) {
  createEmbeddings(input: $input)
}
` as GeneratedMutation<APITypes.CreateEmbeddingsMutationVariables, APITypes.CreateEmbeddingsMutation>;
export const createExamMCQSession = /* GraphQL */ `mutation CreateExamMCQSession($input: CreateExamMCQSessionInput!) {
  createExamMCQSession(input: $input) {
    items {
      exam
      questionId
      difficulty
      choices
      question
      reasons
      rating
      ratingCount
      examUnit
      examSection
      noteId
      flashcardSetId
      subject
      topic
      created
      updated
      url
      prompt
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.CreateExamMCQSessionMutationVariables, APITypes.CreateExamMCQSessionMutation>;
export const rateExamMCQ = /* GraphQL */ `mutation RateExamMCQ($input: RateExamMCQInput!) {
  rateExamMCQ(input: $input) {
    exam
    questionId
    difficulty
    choices
    question
    reasons
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
  }
}
` as GeneratedMutation<APITypes.RateExamMCQMutationVariables, APITypes.RateExamMCQMutation>;
export const rateExamFRQ = /* GraphQL */ `mutation RateExamFRQ($input: RateExamFRQInput!) {
  rateExamFRQ(input: $input) {
    exam
    questionId
    difficulty
    question
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
    stimuli
    name
    parts {
      question
      rating
      ratingCount
    }
    rubric {
      guidelines
      points
    }
    starred
  }
}
` as GeneratedMutation<APITypes.RateExamFRQMutationVariables, APITypes.RateExamFRQMutation>;
export const referOrganization = /* GraphQL */ `mutation ReferOrganization($input: ReferOrganizationInput!) {
  referOrganization(input: $input) {
    org
    schoolId
    created
    updated
    schools
    country
    state
    students
    teachers
    paywall {
      count
      lastSent
    }
    aliases
    stage
    referrals {
      userId
      created
      sent
      type
      email
    }
    dpa {
      name
      email
      link
      type
    }
  }
}
` as GeneratedMutation<APITypes.ReferOrganizationMutationVariables, APITypes.ReferOrganizationMutation>;
export const selectSchool = /* GraphQL */ `mutation SelectSchool($input: SelectSchoolInput!) {
  selectSchool(input: $input) {
    user {
      Email
      org
      accountType
      ID
      timeZone
      referral
      Name
      pictureUrl
      username
      recentlyViewed {
        ID
        type
        time
        count
      }
      views
      birthday
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        interval
        status
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      personalizedAds
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          cost
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
    }
    organization {
      org
      schoolId
      created
      updated
      schools
      country
      state
      students
      teachers
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
    }
  }
}
` as GeneratedMutation<APITypes.SelectSchoolMutationVariables, APITypes.SelectSchoolMutation>;
export const migrateAccountContent =
    /* GraphQL */ `mutation MigrateAccountContent($input: MigrateAccountContentInput!) {
  migrateAccountContent(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedMutation<APITypes.MigrateAccountContentMutationVariables, APITypes.MigrateAccountContentMutation>;
export const trackOrganizationPaywall =
    /* GraphQL */ `mutation TrackOrganizationPaywall($input: TrackOrganizationPaywallInput!) {
  trackOrganizationPaywall(input: $input) {
    org
    schoolId
    created
    updated
    schools
    country
    state
    students
    teachers
    paywall {
      count
      lastSent
    }
    aliases
    stage
    referrals {
      userId
      created
      sent
      type
      email
    }
    dpa {
      name
      email
      link
      type
    }
  }
}
` as GeneratedMutation<APITypes.TrackOrganizationPaywallMutationVariables, APITypes.TrackOrganizationPaywallMutation>;
export const _unusedSchoolApi = /* GraphQL */ `mutation _unusedSchoolApi {
  _unusedSchoolApi {
    type
    schoolId
    name
    country
    state
    city
    updated
    address {
      streetOne
      streetTwo
      city
      county
      state
      zip
      country
    }
    mailing {
      streetOne
      streetTwo
      city
      county
      state
      zip
      country
    }
    population {
      teachers
      ft
      pt
    }
    aliases
    gradesLow
    gradesHigh
    website
    phone
  }
}
` as GeneratedMutation<APITypes._unusedSchoolApiMutationVariables, APITypes._unusedSchoolApiMutation>;
