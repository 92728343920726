import { MarkSpec } from "../../pm/model";
import Mark from "./Mark";

export default class Italic extends Mark {
    get name() {
        return "em";
    }

    get schema(): MarkSpec {
        return {
            parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style", getAttrs: value => value === "italic" }],
            toDOM: () => ["em"],
        };
    }

    get toMarkdown() {
        return {
            open: "*",
            close: "*",
            mixable: true,
            expelEnclosingWhitespace: true,
            escape: false,
        };
    }

    parseMarkdown() {
        return { mark: "em" };
    }
}
