import { getNoteMetadata, listNotesNoContent } from "@/graphql/customQueries";
import { createNote, deleteNote, updateNote } from "@/graphql/mutations";
import { getNote, listNotes, listNotesByClass, listNotesByFolder } from "@/graphql/queries";
import { platform } from "@/platform";
import { NoteMetadata, PartialWithRequired } from "@/types/common";
import { Note } from "@knowt/syncing/graphql/schema";
import { ServerClientWithCookies, client, listGroupedData, now } from "@/utils/SyncUtils";
import {
    DEFAULT_NOTE_CONTENT,
    DEFAULT_NOTE_SUMMARY,
    DEFAULT_NOTE_TITLE,
    scrapeEmptyFields,
} from "@/utils/dataCleaning";
import { fromEntries, retry } from "@/utils/genericUtils";
import { callGetFolder } from "@/hooks/folders/graphqlUtils";
import { callGetMedia } from "@/hooks/media/graphqlUtils";
import { callGetRawFlashcardSet } from "@/hooks/flashcards/graphqlUtils";

// Takes in a note object and ensures that it has all necessary data
export const fillInNoteData = (note: Note): Note | null => {
    if (!note) return null;

    note.title = note.title || DEFAULT_NOTE_TITLE;
    note.content = note.content || DEFAULT_NOTE_CONTENT;
    note.summary = note.content.slice(0, 300) || DEFAULT_NOTE_SUMMARY;
    note.trash = !!note.trash;
    note.public = !!note.public;
    note.classPublic = !!note.classPublic;
    note.updated = note.updated || String(now());
    note.created = note.created || note.updated;

    return note;
};

export const callGetNote = async ({
    noteId,
    serverClient,
    password,
}: {
    noteId?: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    try {
        const { data } = await client.query({
            query: getNote,
            variables: { input: scrapeEmptyFields({ noteId, password }) },
            serverClient,
        });

        return fillInNoteData(data.getNote);
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "getNote", scrapeEmptyFields({ noteId, password }));
        throw error;
    }
};

export const callListNotesNoContent = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listNotesNoContent,
        groupingKey: "noteId",
        input: { userId },
        queryName: "listNotes",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const fetchNoteMetadata = async ({ noteId }: { noteId: string }): Promise<NoteMetadata | null> => {
    try {
        const { data } = await client.query({
            query: getNoteMetadata,
            variables: { input: { noteId } },
        });

        return data.getNote;
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "getNote", { noteId });
        return null;
    }
};

export const fetchNotesMetadata = async (noteIds: string[]): Promise<Record<string, NoteMetadata>> => {
    return fromEntries(
        (await Promise.all(noteIds.map(noteId => fetchNoteMetadata({ noteId }))))
            .filter(Boolean)
            .map(note => [note.noteId, note])
    );
};

export const callListNotesWithContent = async (userId: string | null | undefined) => {
    if (!userId) return null;

    const notesWithContent = (await listGroupedData({
        listQuery: listNotes,
        groupingKey: "noteId",
        input: { userId },
        queryName: "listNotes",
        ignoreTrashed: false,
    })) as Record<string, Note>;

    return fromEntries(Object.values(notesWithContent).map(note => [note.noteId, note.content]));
};

export const callListNotesByFolder = async ({
    folderId,
    serverClient,
}: {
    folderId: string;
    serverClient?: ServerClientWithCookies;
}): Promise<Record<string, Note>> => {
    if (!folderId) return {};

    return (await listGroupedData({
        listQuery: listNotesByFolder,
        groupingKey: "noteId",
        input: { folderId },
        queryName: "listNotesByFolder",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const callListNotesByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}): Promise<Record<string, Note>> => {
    if (!classId) return {};

    return (await listGroupedData({
        listQuery: listNotesByClass,
        groupingKey: "noteId",
        input: { classId },
        queryName: "listNotesByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const callCreateNote = async (initialFields: Partial<Note>) => {
    return await retry(async () => {
        const privacySettings = await getPrivacySettings(initialFields);
        const input = scrapeEmptyFields({ ...initialFields, ...privacySettings });
        try {
            const { data } = await client.mutate({
                mutation: createNote,
                variables: { input },
            });

            return data.createNote as Note;
        } catch (error) {
            const { report } = await platform.analytics.logging();
            report(error, "createNote", input);
            throw error;
        }
    });
};

const getPrivacySettings = async (initialFields: Partial<Note>): Promise<Pick<Note, "public" | "password">> => {
    if (initialFields.password !== undefined) return { public: false, password: initialFields.password };
    if (typeof initialFields.public === "boolean") return { public: initialFields.public, password: null };
    if (initialFields.mediaId) {
        const media = await callGetMedia({ mediaId: initialFields.mediaId });
        return { public: media.public, password: media.password };
    }
    if (initialFields.flashcardSetId) {
        const rawFlashcardSet = await callGetRawFlashcardSet({ flashcardSetId: initialFields.flashcardSetId });
        return { public: rawFlashcardSet.public, password: rawFlashcardSet.password };
    }
    if (initialFields.folderId) {
        const parentFolder = await callGetFolder({ folderId: initialFields.folderId });
        return { public: parentFolder.public, password: parentFolder.password };
    }
    return { public: false, password: null };
};

export const callUpdateNote = async (updates: PartialWithRequired<Note, "userId" | "noteId">) => {
    return await retry(async () => {
        try {
            const data = await client.mutate({
                mutation: updateNote,
                variables: { input: updates },
            });

            return data.data.updateNote;
        } catch (error) {
            const { report } = await platform.analytics.logging();
            report(error, "updateNote", updates);
            throw error;
        }
    });
};

export const callDeleteNote = async (noteId: string, userId: string) => {
    try {
        return await client.mutate({
            mutation: deleteNote,
            variables: { input: { noteId, userId } },
        });
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "deleteNote", { noteId });
        throw error;
    }
};
