/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateNoteInput = {
    userId: string;
    noteId: string;
    mediaId?: string | null;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export enum ImportType {
    WEB_SCRAPING = "WEB_SCRAPING",
    IMAGE = "IMAGE",
    PDF = "PDF",
    PPT = "PPT",
    WORD = "WORD",
    TXT = "TXT",
    DRIVE_PDF = "DRIVE_PDF",
    DRIVE_PPT = "DRIVE_PPT",
    DRIVE_WORD = "DRIVE_WORD",
    DRIVE_TXT = "DRIVE_TXT",
}

export type EmojiIconInput = {
    emoji?: string | null;
    skinTone?: string | null;
};

export type Note = {
    __typename: "Note";
    userId: string;
    noteId: string;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    mediaId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    textbookId?: string | null;
    chapterId?: string | null;
    views?: number | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIcon | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type EmojiIcon = {
    __typename: "EmojiIcon";
    emoji?: string | null;
    skinTone?: string | null;
};

export type DeleteNoteInput = {
    userId: string;
    noteId: string;
};

export type UpdateNoteInput = {
    userId: string;
    noteId: string;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type GenerateMediaTranscriptionInput = {
    mediaId: string;
};

export type Media = {
    __typename: "Media";
    userId: string;
    mediaId: string;
    created: string;
    updated: string;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public: boolean;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type: MediaType;
    fileType: FileType;
    pages?: number | null;
    length?: number | null;
    bucket: string;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIcon | null;
    rating?: number | null;
    ratingCount?: number | null;
    transcript?: TranscriptEnum | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    textbookId?: string | null;
    chapterId?: string | null;
    views?: number | null;
    jwt?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export enum MediaType {
    AUDIO = "AUDIO",
    VIDEO = "VIDEO",
    PDF = "PDF",
    DOCX = "DOCX",
    PPTX = "PPTX",
    XLSX = "XLSX",
    IMAGE = "IMAGE",
}

export enum FileType {
    MP3 = "MP3",
    MP4 = "MP4",
    MKV = "MKV",
    MOV = "MOV",
    WAV = "WAV",
    WEBM = "WEBM",
    PDF = "PDF",
    M4A = "M4A",
    DOCX = "DOCX",
    DOC = "DOC",
    PPTX = "PPTX",
    PPT = "PPT",
    XLSX = "XLSX",
    XLS = "XLS",
}

export enum TranscriptEnum {
    FULL = "FULL",
    FREE = "FREE",
    MIN = "MIN",
    NONE = "NONE",
}

export type CreateMediaWithTranscriptionInput = {
    transcript: Array<TranscriptSentenceInput | null>;
    length: number;
    chapters?: Array<MediaChapterInput | null> | null;
    userId: string;
    mediaId: string;
    created: string;
    updated: string;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public: boolean;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type: MediaType;
    fileType: FileType;
    bucket: string;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    verified?: boolean | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type TranscriptSentenceInput = {
    content?: string | null;
    start?: number | null;
    end?: number | null;
    speaker?: string | null;
};

export type MediaChapterInput = {
    title: string;
    start: number;
    end: number;
};

export type ImportFromLinkInput = {
    link: string;
};

export type DuplicateMediaInput = {
    baseMediaId: string;
    mediaId: string;
    folderId?: string | null;
};

export type UpdateMediaInput = {
    userId: string;
    mediaId: string;
    created?: string | null;
    updated?: string | null;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public?: boolean | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type?: MediaType | null;
    fileType?: FileType | null;
    bucket?: string | null;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    transcript?: TranscriptEnum | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type DeleteMediaInput = {
    userId: string;
    mediaId: string;
};

export type CreateFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
    mediaId?: string | null;
    sort?: number | null;
    noteId?: string | null;
    position?: number | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    title?: string | null;
    description?: string | null;
    draft?: boolean | null;
    size?: number | null;
    starred?: Array<string | null> | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    answerSide?: FlashcardSide | null;
    flashcards: Array<FlashcardInput | null>;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    classPublic?: boolean | null;
    subject?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export enum FlashcardStudyFrom {
    ALL = "ALL",
    STARRED = "STARRED",
}

export enum FlashcardSide {
    TERM = "TERM",
    DEFINITION = "DEFINITION",
    BOTH = "BOTH",
}

export type FlashcardInput = {
    flashcardId: string;
    flashcardSetId?: string | null;
    userId?: string | null;
    term?: string | null;
    termAudio?: string | null;
    definition?: string | null;
    definitionAudio?: string | null;
    distractorIds?: Array<string | null> | null;
    questionType?: QuestionType | null;
    isCorrect?: boolean | null;
    disabled?: boolean | null;
    draft?: boolean | null;
    trash?: boolean | null;
    image?: string | null;
    secondaryImage?: string | null;
    edited?: boolean | null;
    created?: string | null;
    updated?: string | null;
    twoSided?: boolean | null;
    schedule?: string | null;
    quality?: number | null;
};

export enum QuestionType {
    MULTI = "MULTI",
    FILL_BLANK = "FILL_BLANK",
    MATCHING = "MATCHING",
    WRITING = "WRITING",
    NONE = "NONE",
    HIST_ORDERING = "HIST_ORDERING",
    MULTI_BLANK = "MULTI_BLANK",
    TF = "TF",
}

export enum Language {
    SPANISH = "SPANISH",
    ENGLISH = "ENGLISH",
    FRENCH = "FRENCH",
    GERMAN = "GERMAN",
    ITALIAN = "ITALIAN",
    LATIN = "LATIN",
    RUSSIAN = "RUSSIAN",
    CHEMISTRY = "CHEMISTRY",
    CHINESE = "CHINESE",
    CHINESE_TRADITIONAL = "CHINESE_TRADITIONAL",
    CHINESE_PINYIN = "CHINESE_PINYIN",
    MATH = "MATH",
    JAPANESE = "JAPANESE",
    JAPANESE_ROMAJI = "JAPANESE_ROMAJI",
    KOREAN = "KOREAN",
    IRISH = "IRISH",
    DUTCH = "DUTCH",
    VIETNAMESE = "VIETNAMESE",
    AFRIKAANS = "AFRIKAANS",
    AKAN = "AKAN",
    AKKADIAN = "AKKADIAN",
    ALBANIAN = "ALBANIAN",
    AMHARIC = "AMHARIC",
    ANGLO_SAXON = "ANGLO_SAXON",
    ARABIC = "ARABIC",
    ARMENIAN = "ARMENIAN",
    AZERBAIJAN = "AZERBAIJAN",
    BASQUE = "BASQUE",
    BELARUSIAN = "BELARUSIAN",
    BENGALI = "BENGALI",
    BIHARI = "BIHARI",
    BOSNIAN = "BOSNIAN",
    BRETON = "BRETON",
    BULGARIAN = "BULGARIAN",
    BURMESE = "BURMESE",
    CATALAN = "CATALAN",
    CEBUANO = "CEBUANO",
    CHAMORRA = "CHAMORRA",
    CHEROKEE = "CHEROKEE",
    CHOCTAW = "CHOCTAW",
    COPTIC = "COPTIC",
    CORSICAN = "CORSICAN",
    CROATIAN = "CROATIAN",
    CZECH = "CZECH",
    DANISH = "DANISH",
    DENE = "DENE",
    DHIVEHI = "DHIVEHI",
    DHOLUO = "DHOLUO",
    ESPERANTO = "ESPERANTO",
    ESTONIAN = "ESTONIAN",
    FAROESE = "FAROESE",
    FILIPINO = "FILIPINO",
    FINNISH = "FINNISH",
    FINMISH = "FINMISH",
    FULA = "FULA",
    GAELIC = "GAELIC",
    GALICIAN = "GALICIAN",
    GEORGIAN = "GEORGIAN",
    GOTHIC = "GOTHIC",
    GREEK = "GREEK",
    GUARANI = "GUARANI",
    GUJARATI = "GUJARATI",
    HAIDA = "HAIDA",
    HAITIAN_CREOLE = "HAITIAN_CREOLE",
    HAUSA = "HAUSA",
    HAWAIIAN = "HAWAIIAN",
    HEBREW = "HEBREW",
    HINDI = "HINDI",
    HMONG = "HMONG",
    HUNGARIAN = "HUNGARIAN",
    ICELANDIC = "ICELANDIC",
    IGBO = "IGBO",
    ILONGGO = "ILONGGO",
    INDONESIAN = "INDONESIAN",
    INTERNATIONAL_PHONETIC_ALPHABET = "INTERNATIONAL_PHONETIC_ALPHABET",
    INUKTITUT = "INUKTITUT",
    JAVANESE = "JAVANESE",
    JOLA_FONYI = "JOLA_FONYI",
    KANNADA = "KANNADA",
    KAZAKH = "KAZAKH",
    KHMER = "KHMER",
    KIKONGA = "KIKONGA",
    KINYARWANDA = "KINYARWANDA",
    KIOWA = "KIOWA",
    KONKOW = "KONKOW",
    KURDISH = "KURDISH",
    KYRGYZ = "KYRGYZ",
    LAKOTA = "LAKOTA",
    LAO = "LAO",
    LATVIAN = "LATVIAN",
    LENAPE = "LENAPE",
    LINGALA = "LINGALA",
    LITHUANIAN = "LITHUANIAN",
    LUBA_KASAI = "LUBA_KASAI",
    LUXEMBOURGISH = "LUXEMBOURGISH",
    MACEDONIAN = "MACEDONIAN",
    MALAGASY = "MALAGASY",
    MALAY = "MALAY",
    MALAYALAM = "MALAYALAM",
    MALTESE = "MALTESE",
    MANDINKA = "MANDINKA",
    MAORI = "MAORI",
    MAORI_COOK_ISLAND = "MAORI_COOK_ISLAND",
    MARATHI = "MARATHI",
    MARSHALLESE = "MARSHALLESE",
    MOHAWK = "MOHAWK",
    MONGOLIAN = "MONGOLIAN",
    NAHUATL = "NAHUATL",
    NAVAJO = "NAVAJO",
    NEPALI = "NEPALI",
    NORWEGIAN = "NORWEGIAN",
    OCCITAN = "OCCITAN",
    OJIBWE = "OJIBWE",
    ORIYA = "ORIYA",
    OROMO = "OROMO",
    OTHER = "OTHER",
    PALI = "PALI",
    PASHTO = "PASHTO",
    PERSIAN = "PERSIAN",
    POLISH = "POLISH",
    PORTUGUESE = "PORTUGUESE",
    PUNJABI = "PUNJABI",
    QUECHUA = "QUECHUA",
    ROMANSH = "ROMANSH",
    ROMANIAN = "ROMANIAN",
    SAMOAN = "SAMOAN",
    SANSKRIT = "SANSKRIT",
    SENECA = "SENECA",
    SERBIAN = "SERBIAN",
    SGAW_KAREN = "SGAW_KAREN",
    SHAN = "SHAN",
    SINDHI = "SINDHI",
    SINHALESE = "SINHALESE",
    SLOVAK = "SLOVAK",
    SLOVENIAN = "SLOVENIAN",
    SOMALI = "SOMALI",
    SUNDANESE = "SUNDANESE",
    SWAHILI = "SWAHILI",
    SWEDISH = "SWEDISH",
    SYRIAC = "SYRIAC",
    TAGALOG = "TAGALOG",
    TAJIK = "TAJIK",
    TAMIL = "TAMIL",
    TATAR = "TATAR",
    TELUGU = "TELUGU",
    TETUM = "TETUM",
    THAI = "THAI",
    TIBETAN = "TIBETAN",
    TIGRINYA = "TIGRINYA",
    TOHONA_O_ODHAM = "TOHONA_O_ODHAM",
    TONGA = "TONGA",
    TRIKI = "TRIKI",
    TURKISH = "TURKISH",
    TUVAN = "TUVAN",
    UIGHUR = "UIGHUR",
    UKRAINIAN = "UKRAINIAN",
    URDU = "URDU",
    UZBEK = "UZBEK",
    WELSH = "WELSH",
    FRISIAN = "FRISIAN",
    WINNEBAGO = "WINNEBAGO",
    WOLOF = "WOLOF",
    XHOSA = "XHOSA",
    YIDDISH = "YIDDISH",
    YORUBA = "YORUBA",
    ZULU = "ZULU",
}

export type FlashcardSet = {
    __typename: "FlashcardSet";
    userId: string;
    flashcardSetId: string;
    sort?: number | null;
    noteId?: string | null;
    mediaId?: string | null;
    position?: number | null;
    title?: string | null;
    description?: string | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    draft?: boolean | null;
    starred?: Array<string | null> | null;
    size?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    answerSide?: FlashcardSide | null;
    shuffled?: boolean | null;
    flashcards?: Array<Flashcard | null> | null;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    views?: number | null;
    classPublic?: boolean | null;
    subject?: string | null;
    textbookId?: string | null;
    chapterId?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIcon | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type Flashcard = {
    __typename: "Flashcard";
    flashcardId: string;
    flashcardSetId?: string | null;
    userId?: string | null;
    term?: string | null;
    termAudio?: string | null;
    definition?: string | null;
    definitionAudio?: string | null;
    distractorIds?: Array<string | null> | null;
    questionType?: QuestionType | null;
    isCorrect?: boolean | null;
    draft?: boolean | null;
    trash?: boolean | null;
    disabled?: boolean | null;
    image?: string | null;
    secondaryImage?: string | null;
    edited?: boolean | null;
    created?: string | null;
    updated?: string | null;
    twoSided?: boolean | null;
    schedule?: string | null;
    quality?: number | null;
};

export type UpdateFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
    sort?: number | null;
    mediaId?: string | null;
    noteId?: string | null;
    position?: number | null;
    title?: string | null;
    description?: string | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    draft?: boolean | null;
    size?: number | null;
    starred?: Array<string | null> | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    answerSide?: FlashcardSide | null;
    flashcards?: Array<FlashcardInput | null> | null;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    classPublic?: boolean | null;
    subject?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    courseId?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type DeleteFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
};

export type FlashcardListInput = {
    userId: string;
    items?: Array<FlashcardInput | null> | null;
};

export type FlashcardConnection = {
    __typename: "FlashcardConnection";
    items: Array<Flashcard>;
    nextToken?: string | null;
};

export type NotificationInput = {
    notificationId: string;
};

export type GenericReturnType = {
    __typename: "GenericReturnType";
    status?: number | null;
    body?: string | null;
};

export type UserDetailsInput = {
    accountType?: string | null;
    userId?: string | null;
    timeZone?: string | null;
    referral?: string | null;
    Name?: string | null;
    hideSensitiveInfo?: boolean | null;
    answerSide?: FlashcardSide | null;
    pictureUrl?: string | null;
    birthday?: string | null;
    noteSidebarWidth?: number | null;
    refFrom_v2?: string | null;
    verified?: boolean | null;
    bio?: string | null;
    socials?: SocialsInput | null;
    cover?: string | null;
    grade?: string | null;
    alerts?: string | null;
    schoolId?: string | null;
    personalizedAds?: boolean | null;
    deleteReq?: boolean | null;
    profileColor?: string | null;
    privacyAck?: boolean | null;
    examHistory?: string | null;
    invites?: number | null;
    stripeTrialTime?: number | null;
    cancelPromoTime?: number | null;
};

export type SocialsInput = {
    ig?: string | null;
    twitter?: string | null;
    tiktok?: string | null;
    in?: string | null;
    link?: string | null;
    spotify?: string | null;
};

export type UserDetails = {
    __typename: "UserDetails";
    Email: string;
    org?: string | null;
    accountType?: string | null;
    ID?: string | null;
    timeZone?: string | null;
    referral?: string | null;
    Name?: string | null;
    pictureUrl?: string | null;
    username?: string | null;
    recentlyViewed?: Array<View | null> | null;
    views?: number | null;
    birthday?: string | null;
    hideSensitiveInfo?: boolean | null;
    noteSidebarWidth?: number | null;
    created?: number | null;
    lastLogIn?: number | null;
    lastNotified?: number | null;
    answerSide?: FlashcardSide | null;
    numFollowers?: number | null;
    numFollowing?: number | null;
    ref_v2?: string | null;
    refFrom_v2?: string | null;
    refList_v2?: Array<string> | null;
    verified?: boolean | null;
    bio?: string | null;
    socials?: Socials | null;
    cover?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    customerId?: string | null;
    subscriptions?: Array<StripeConnection> | null;
    alerts?: string | null;
    subscriptionType?: SubscriptionType | null;
    stripeVerifyTime?: number | null;
    stripeTrialTime?: number | null;
    cancelPromoTime?: number | null;
    personalizedAds?: boolean | null;
    deleteReq?: boolean | null;
    ai?: UserAIStats | null;
    profileColor?: string | null;
    privacyAck?: boolean | null;
    blogger?: boolean | null;
    examHistory?: string | null;
    usernameChange?: number | null;
    signInType?: SignInType | null;
    invites?: number | null;
    classes?: ClassInfo | null;
    migKey?: string | null;
};

export type View = {
    __typename: "View";
    ID: string;
    type?: ItemType | null;
    time?: number | null;
    count?: number | null;
};

export enum ItemType {
    NOTE = "NOTE",
    FLASHCARDSET = "FLASHCARDSET",
    FOLDER = "FOLDER",
    CLASS = "CLASS",
    TEXTBOOK = "TEXTBOOK",
    CHAPTER = "CHAPTER",
    SECTION = "SECTION",
    USER = "USER",
    SCHOOL = "SCHOOL",
    COURSE = "COURSE",
    MEDIA = "MEDIA",
    VERIFIED_SCHOOL = "VERIFIED_SCHOOL",
}

export type Socials = {
    __typename: "Socials";
    ig?: string | null;
    twitter?: string | null;
    tiktok?: string | null;
    in?: string | null;
    link?: string | null;
    spotify?: string | null;
};

export type StripeConnection = {
    __typename: "StripeConnection";
    subscriptionId: string;
    customerId: string;
    priceId: string;
    productId: string;
    created?: number | null;
    current_period_end?: number | null;
    cancel_at_period_end?: boolean | null;
    interval?: string | null;
    status?: string | null;
};

export enum SubscriptionType {
    BASIC = "BASIC",
    SUPPORTER = "SUPPORTER",
    PRO = "PRO",
    LIMITLESS = "LIMITLESS",
}

export type UserAIStats = {
    __typename: "UserAIStats";
    curPrompts?: number | null;
    curPeriodEnd?: number | null;
    curTokens?: number | null;
    videos?: number | null;
    vNotes?: number | null;
    vSets?: number | null;
    mins?: number | null;
    pdfs?: number | null;
    pNotes?: number | null;
    pSets?: number | null;
    pages?: number | null;
    nTests?: number | null;
    nSets?: number | null;
    chats?: number | null;
    explain?: number | null;
    cost?: number | null;
    history?: Array<UserAIStatsHistory | null> | null;
};

export type UserAIStatsHistory = {
    __typename: "UserAIStatsHistory";
    periodEnd?: number | null;
    prompts?: number | null;
    tokens?: number | null;
    videos?: number | null;
    vNotes?: number | null;
    vSets?: number | null;
    mins?: number | null;
    pdfs?: number | null;
    pNotes?: number | null;
    pSets?: number | null;
    pages?: number | null;
    nTests?: number | null;
    nSets?: number | null;
    chats?: number | null;
    explain?: number | null;
    cost?: number | null;
};

export enum SignInType {
    GOOGLE = "GOOGLE",
    FACEBOOK = "FACEBOOK",
    APPLE = "APPLE",
    EMAIL = "EMAIL",
}

export type ClassInfo = {
    __typename: "ClassInfo";
    enrolled: Array<ClassEnrollment>;
    teacher: Array<string>;
    invited: Array<ClassEnrollment>;
};

export type ClassEnrollment = {
    __typename: "ClassEnrollment";
    teacherId: string;
    classId: string;
    role: ClassRole;
    sections: Array<string>;
};

export enum ClassRole {
    ADMIN = "ADMIN",
    TEACHER = "TEACHER",
    STUDENT = "STUDENT",
}

export type FollowInput = {
    userId: string;
};

export type Follower = {
    __typename: "Follower";
    userId?: string | null;
    followerId?: string | null;
};

export type UnfollowInput = {
    userId: string;
};

export type RemoveFollowerInput = {
    userId: string;
};

export type UpdateUsernameInput = {
    username: string;
};

export type User = {
    __typename: "User";
    userId: string;
    pictureUrl?: string | null;
    Name?: string | null;
    accountType?: string | null;
    username?: string | null;
    Email?: string | null;
};

export type ViewInput = {
    ID: string;
    type: ItemType;
    userId?: string | null;
};

export type CreateBookmarkInput = {
    ID: string;
    type: ItemType;
};

export type DeleteBookmarkInput = {
    ID: string;
    type: ItemType;
};

export type SchoolInput = {
    schoolId: string;
    name?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    type?: SchoolType | null;
};

export enum SchoolType {
    ELEMENTARY = "ELEMENTARY",
    SECONDARY = "SECONDARY",
    UNIVERSITY = "UNIVERSITY",
    MIDDLE = "MIDDLE",
    HIGH = "HIGH",
    PRIMARY = "PRIMARY",
}

export type School = {
    __typename: "School";
    schoolId: string;
    name?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    type?: SchoolType | null;
};

export type CreateCourseInput = {
    courseId: string;
    schoolId: string;
    name?: string | null;
    description?: string | null;
    subject?: string | null;
    topic?: string | null;
};

export type Course = {
    __typename: "Course";
    courseId: string;
    schoolId: string;
    name?: string | null;
    description?: string | null;
    subject?: string | null;
    topic?: string | null;
};

export type CreateFolderInput = {
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type Folder = {
    __typename: "Folder";
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    color?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIcon | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type UpdateFolderInput = {
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type DeleteFolderInput = {
    userId: string;
    folderId: string;
};

export type CreateRatingInput = {
    noteId: string;
    userId: string;
    noteOwnerId: string;
    message?: string | null;
    rating: number;
};

export type Rating = {
    __typename: "Rating";
    noteId: string;
    userId: string;
    noteOwnerId: string;
    message?: string | null;
    rating?: number | null;
};

export type UpdateRatingInput = {
    noteId: string;
    userId: string;
    noteOwnerId?: string | null;
    message?: string | null;
    rating?: number | null;
};

export type FlashcardSetViewerInput = {
    userId: string;
    flashcardSetId: string;
    position?: number | null;
    starred?: Array<string | null> | null;
    length?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    studiedFlashcards?: Array<string | null> | null;
    order?: Array<string | null> | null;
    shuffled?: boolean | null;
    lastViewedFlashcard?: string | null;
    answerSide?: FlashcardSide | null;
    reviewMode?: FlashcardReviewMode | null;
};

export enum FlashcardReviewMode {
    STANDARD = "STANDARD",
    WRITING = "WRITING",
}

export type FlashcardSetViewer = {
    __typename: "FlashcardSetViewer";
    userId: string;
    flashcardSetId: string;
    position?: number | null;
    starred?: Array<string | null> | null;
    length?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    studiedFlashcards?: Array<string | null> | null;
    order?: Array<string | null> | null;
    shuffled?: boolean | null;
    lastViewedFlashcard?: string | null;
    answerSide?: FlashcardSide | null;
    reviewMode?: FlashcardReviewMode | null;
};

export type GetFlashcardSetInput = {
    flashcardSetId: string;
    password?: string | null;
};

export type UserIdInput = {
    userId: string;
};

export type StripeConnectionInput = {
    customerId: string;
};

export type DeleteAccountRequestInput = {
    userId: string;
    category?: string | null;
    followUp?: string | null;
    reason?: string | null;
    deleteReq?: boolean | null;
};

export type CreateNotificationTokenInput = {
    userId: string;
    tokenId: string;
    active: number;
    os: NotificationTokenOSType;
    type: NotificationTokenType;
    level?: NotificationLevel | null;
    created: number;
    updated: number;
};

export enum NotificationTokenOSType {
    IOS = "IOS",
    ANDROID = "ANDROID",
    WEB = "WEB",
}

export enum NotificationTokenType {
    EMAIL = "EMAIL",
    PUSH = "PUSH",
}

export enum NotificationLevel {
    OFF = "OFF",
    IMPORTANT = "IMPORTANT",
    FULL = "FULL",
}

export type NotificationToken = {
    __typename: "NotificationToken";
    userId: string;
    tokenId: string;
    active: number;
    type: NotificationTokenType;
    created: number;
    updated: number;
    os: NotificationTokenOSType;
    level: NotificationLevel;
};

export type UpdateNotificationTokenInput = {
    userId: string;
    tokenId: string;
    active?: number | null;
    os?: NotificationTokenOSType | null;
    type?: NotificationTokenType | null;
    level?: NotificationLevel | null;
    created?: number | null;
    updated?: number | null;
};

export type UpdateNotificationTokenTypeInput = {
    type: NotificationTokenType;
    level: NotificationLevel;
};

export type DeleteNotificationTokenInput = {
    tokenId: string;
};

export type SendImmediateNotificationInput = {
    userId: string;
    itemId?: string | null;
    itemType?: ItemType | null;
    notifType?: ScheduledEventEnum | null;
    usage: EventUsage;
    color?: string | null;
    image?: string | null;
    title?: string | null;
    message?: string | null;
    action?: string | null;
    level?: NotificationLevel | null;
};

export enum ScheduledEventEnum {
    EMAIL = "EMAIL",
    PUSH = "PUSH",
    ALL = "ALL",
}

export enum EventUsage {
    SPACED_REPETITION = "SPACED_REPETITION",
    RATING = "RATING",
    FOLLOW = "FOLLOW",
    WELCOME_EMAIL = "WELCOME_EMAIL",
    CREATED_WITHOUT_STUDY = "CREATED_WITHOUT_STUDY",
    INACTIVE_ONE_WEEK = "INACTIVE_ONE_WEEK",
    INACTIVE_ONE_MONTH = "INACTIVE_ONE_MONTH",
    EXAM_TOMORROW = "EXAM_TOMORROW",
    SPACED_REPETITION_READY = "SPACED_REPETITION_READY",
    LEARN_MODE_REMINDER = "LEARN_MODE_REMINDER",
    SCRIPT_EMAIL = "SCRIPT_EMAIL",
}

export type Notification = {
    __typename: "Notification";
    userId: string;
    notificationId: string;
    read: number;
    trash: number;
    timestamp: string;
    scheduledAt: string;
    color?: string | null;
    image?: string | null;
    title?: string | null;
    message?: string | null;
    action?: string | null;
    level?: NotificationLevel | null;
    usage: EventUsage;
};

export type BaseNotification = {
    __typename: "BaseNotification";
    color?: string | null;
    image?: string | null;
    title?: string | null;
    message?: string | null;
    action?: string | null;
    level?: NotificationLevel | null;
};

export type ScheduledEvent = {
    __typename: "ScheduledEvent";
    created: string;
    updated: string;
    eventTime: number;
    eventId: string;
    userId: string;
    notifType: ScheduledEventEnum;
    usage: EventUsage;
    itemId?: string | null;
    itemType?: ItemType | null;
    completed?: boolean | null;
    color?: string | null;
    image?: string | null;
    title?: string | null;
    message?: string | null;
    action?: string | null;
    level?: NotificationLevel | null;
};

export type SendScheduledNotificationInput = {
    delay: number;
    userId: string;
    itemId?: string | null;
    itemType?: ItemType | null;
    notifType?: ScheduledEventEnum | null;
    usage: EventUsage;
    color?: string | null;
    image?: string | null;
    title?: string | null;
    message?: string | null;
    action?: string | null;
    level?: NotificationLevel | null;
};

export type CancelScheduledNotificationInput = {
    userId: string;
    usage: EventUsage;
    itemId?: string | null;
    itemType?: ItemType | null;
};

export type TextToSpeechInput = {
    text: string;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    side?: FlashcardSide | null;
    voice?: VoiceInput | null;
};

export type VoiceInput = {
    language: Language;
    name?: string | null;
    speakingRate?: number | null;
    pitch?: number | null;
};

export type LanguageResponse = {
    __typename: "LanguageResponse";
    language?: Language | null;
    confidence?: number | null;
};

export type TextToSpeechResponse = {
    __typename: "TextToSpeechResponse";
    url: string;
};

export type PasswordInput = {
    password: string;
    type: ItemType;
    id: string;
};

export type Password = {
    __typename: "Password";
    password: string;
    type: ItemType;
    id: string;
};

export type EditChapterInput = {
    mediaId: string;
    bucket: string;
    idx: number;
    title?: string | null;
};

export type BlogInput = {
    blogId: string;
    title: string;
    slug: string;
    content: string;
    summary?: string | null;
    created: string;
    updated: string;
    tags?: string | null;
    coverImage?: BlogCoverImageInput | null;
    metadata?: BlogSEOMetadataInput | null;
    draft?: boolean | null;
    trash?: boolean | null;
    pinned?: number | null;
};

export type BlogCoverImageInput = {
    url?: string | null;
    width?: number | null;
    height?: number | null;
};

export type BlogSEOMetadataInput = {
    title?: string | null;
    description?: string | null;
};

export type Blog = {
    __typename: "Blog";
    userId: string;
    blogId: string;
    title: string;
    slug: string;
    content: string;
    summary?: string | null;
    created: string;
    updated: string;
    tags?: string | null;
    coverImage?: BlogCoverImage | null;
    metadata?: BlogSEOMetadata | null;
    draft?: boolean | null;
    trash?: boolean | null;
    type?: string | null;
    pinned?: number | null;
};

export type BlogCoverImage = {
    __typename: "BlogCoverImage";
    url?: string | null;
    width?: number | null;
    height?: number | null;
};

export type BlogSEOMetadata = {
    __typename: "BlogSEOMetadata";
    title?: string | null;
    description?: string | null;
};

export type FlashcardSetStudySessionInput = {
    flashcardSetId: string;
    examDate?: string | null;
    type: StudySessionType;
    settings?: StudySessionSettingsInput | null;
    studyFrom?: StudySessionProgressEnum | null;
};

export enum StudySessionType {
    LEARN = "LEARN",
    MATCH = "MATCH",
    SPACED = "SPACED",
    REVIEW = "REVIEW",
    TEST = "TEST",
}

export type StudySessionSettingsInput = {
    LEARN?: LearnStudySessionSettingInput | null;
    TEST?: TestStudySessionSettingInput | null;
    SPACED?: SpacedStudySessionSettingInput | null;
    MATCH?: MatchStudySessionSettingInput | null;
    REVIEW?: ReviewStudySessionSettingInput | null;
};

export type LearnStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
};

export type TestStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
};

export type MatchStudySessionSettingInput = {
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    sort?: boolean | null;
    reset?: boolean | null;
    review?: boolean | null;
};

export enum StudySessionProgressEnum {
    NEW = "NEW",
    LEARNING = "LEARNING",
    REVIEWING = "REVIEWING",
    MASTERED = "MASTERED",
}

export type FlashcardSetStudySessionConnection = {
    __typename: "FlashcardSetStudySessionConnection";
    studySession: StudySession;
    questions: Array<FlashcardSetStudySessionQuestion>;
};

export type StudySession = {
    __typename: "StudySession";
    userId: string;
    itemId: string;
    type: ItemType;
    created: string;
    updated: string;
    examDate?: string | null;
    newCards: number;
    nextDue: string;
    nextNewDue: string;
    know?: number | null;
    dKnow?: number | null;
    settings: StudySessionSettings;
    progress: StudySessionProgress;
    syncTime: string;
    title?: string | null;
};

export type StudySessionSettings = {
    __typename: "StudySessionSettings";
    LEARN?: LearnStudySessionSetting | null;
    TEST?: TestStudySessionSetting | null;
    SPACED?: SpacedStudySessionSetting | null;
    MATCH?: MatchStudySessionSetting | null;
    REVIEW?: ReviewStudySessionSetting | null;
};

export type LearnStudySessionSetting = {
    __typename: "LearnStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
};

export type TestStudySessionSetting = {
    __typename: "TestStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedStudySessionSetting = {
    __typename: "SpacedStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
};

export type MatchStudySessionSetting = {
    __typename: "MatchStudySessionSetting";
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewStudySessionSetting = {
    __typename: "ReviewStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    sort?: boolean | null;
    review?: boolean | null;
};

export type StudySessionProgress = {
    __typename: "StudySessionProgress";
    NEW: number;
    LEARNING: number;
    REVIEWING: number;
    MASTERED: number;
};

export type FlashcardSetStudySessionQuestion = {
    __typename: "FlashcardSetStudySessionQuestion";
    flashcardId: string;
    distractors: Array<string>;
    questionType: QuestionType;
    answerSide: FlashcardSide;
    buttons?: Array<number> | null;
    tfIsCorrect: boolean;
    progress: StudySessionProgressEnum;
    spacing?: SpacingIntervals | null;
};

export type SpacingIntervals = {
    __typename: "SpacingIntervals";
    AGAIN: number;
    HARD: number;
    GOOD: number;
    EASY: number;
};

export type GradeFlashcardSetStudySessionInput = {
    flashcardSetId: string;
    questions: Array<GradeFlashcardSetStudySessionQuestionInput>;
};

export type GradeFlashcardSetStudySessionQuestionInput = {
    flashcardId: string;
    selectedFlashcardId?: string | null;
    side: FlashcardSide;
    timestamp: number;
    timeTaken: number;
    mode: StudySessionType;
    questionType: QuestionType;
    button?: SpacedRepetitionButton | null;
    isCorrect: boolean;
    answer: string;
};

export enum SpacedRepetitionButton {
    AGAIN = "AGAIN",
    HARD = "HARD",
    GOOD = "GOOD",
    EASY = "EASY",
}

export type FlashcardSetStudySessionRound = {
    __typename: "FlashcardSetStudySessionRound";
    studySession: StudySession;
    studyStates: Array<FlashcardStudyState>;
};

export type FlashcardStudyState = {
    __typename: "FlashcardStudyState";
    userId: string;
    flashcardId: string;
    flashcardSetId: string;
    created: string;
    updated: string;
    distractors: Array<string>;
    mastery: number;
    lastStudied: string;
    blank?: boolean | null;
    nextDue: string;
    difficulty?: number | null;
    interval: number;
    eFactor: number;
    sort?: boolean | null;
    progress: StudySessionProgressEnum;
    history: Array<FlashcardStudyStateHistory>;
};

export type FlashcardStudyStateHistory = {
    __typename: "FlashcardStudyStateHistory";
    timestamp: number;
    timeTaken: number;
    mode: StudySessionType;
    questionType: QuestionType;
    selectedFlashcardId?: string | null;
    side: FlashcardSide;
    button?: SpacedRepetitionButton | null;
    isCorrect: boolean;
    answer: string;
};

export type ClearFlashcardSetStudySessionInput = {
    itemId: string;
};

export type SubmitAICompletionInput = {
    timestamp?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    itemId?: string | null;
    itemType?: ItemType | null;
    id?: string | null;
    input: string;
    messages?: Array<AIMessageInput | null> | null;
    output: string;
    inputTokens?: number | null;
    outputTokens?: number | null;
    subject?: string | null;
    topic?: string | null;
    type?: AICompletionType | null;
    timeTaken?: number | null;
};

export type AIMessageInput = {
    role?: string | null;
    content?: string | null;
};

export enum AICompletionType {
    GENERIC_FLASHCARD = "GENERIC_FLASHCARD",
    GENERIC_NOTE = "GENERIC_NOTE",
    LIST = "LIST",
    KEY_INFO = "KEY_INFO",
    HIST_EVENT = "HIST_EVENT",
    EXAMPLE = "EXAMPLE",
    FORMULA = "FORMULA",
    SIMPLE_FLASHCARD = "SIMPLE_FLASHCARD",
    FILL_BLANK_FLASHCARD = "FILL_BLANK_FLASHCARD",
    QUESTION_FLASHCARD = "QUESTION_FLASHCARD",
    TRANSLATE_FLASHCARD = "TRANSLATE_FLASHCARD",
    MIND_MAP = "MIND_MAP",
    ESSAY_OUTLINE = "ESSAY_OUTLINE",
    OUTLINE = "OUTLINE",
    BRAINSTORM = "BRAINSTORM",
    BOOK_SUM = "BOOK_SUM",
    CREATE_NOTE = "CREATE_NOTE",
    PROS_CONS = "PROS_CONS",
    COVER_LETTER = "COVER_LETTER",
    NEWSPAPER_ARTICLE = "NEWSPAPER_ARTICLE",
    COLD_EMAIL = "COLD_EMAIL",
    DEBATE_ARGUMENT = "DEBATE_ARGUMENT",
    RESEARCH_QUESTIONS = "RESEARCH_QUESTIONS",
    MNEMONICS = "MNEMONICS",
    KEY_VOCABULARY = "KEY_VOCABULARY",
    SENTENCE_TO_FLASHCARD = "SENTENCE_TO_FLASHCARD",
    SENTENCE_TO_QCARD = "SENTENCE_TO_QCARD",
    SENTENCE_TO_FIBCARD = "SENTENCE_TO_FIBCARD",
    ELI5 = "ELI5",
    SUMMARIZE = "SUMMARIZE",
    TRANSLATE = "TRANSLATE",
    CREATE_STUDY_PLAN = "CREATE_STUDY_PLAN",
    CONTINUE_WRITING = "CONTINUE_WRITING",
    MAKE_LONGER = "MAKE_LONGER",
    MAKE_SHORTER = "MAKE_SHORTER",
    SIMPLIFY_LANGUAGE = "SIMPLIFY_LANGUAGE",
    FIX_SPELLING_GRAMMAR = "FIX_SPELLING_GRAMMAR",
    IMPROVE_WRITING = "IMPROVE_WRITING",
    ADD_MORE_DETAILS = "ADD_MORE_DETAILS",
    FIND_QUOTES = "FIND_QUOTES",
    VIDEO_QUESTIONS = "VIDEO_QUESTIONS",
    PDF_QUESTIONS = "PDF_QUESTIONS",
    CHAT = "CHAT",
    NOTE_TEST = "NOTE_TEST",
    NOTE_FLASHCARDS = "NOTE_FLASHCARDS",
    VIDEO_NOTES = "VIDEO_NOTES",
    PDF_NOTES = "PDF_NOTES",
    VIDEO_FLASHCARDS = "VIDEO_FLASHCARDS",
    PDF_FLASHCARDS = "PDF_FLASHCARDS",
    FORMAT_TRANSCRIPT = "FORMAT_TRANSCRIPT",
    DETECT_LANGUAGE = "DETECT_LANGUAGE",
    EXPLAIN_WHY_IM_WRONG = "EXPLAIN_WHY_IM_WRONG",
}

export type AICompletion = {
    __typename: "AICompletion";
    userId: string;
    timestamp?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    itemId?: string | null;
    itemType?: ItemType | null;
    id?: string | null;
    input: string;
    messages?: Array<AIMessage | null> | null;
    output: string;
    inputTokens?: number | null;
    outputTokens?: number | null;
    subject?: string | null;
    topic?: string | null;
    type?: AICompletionType | null;
    timeTaken?: number | null;
};

export type AIMessage = {
    __typename: "AIMessage";
    role?: string | null;
    content?: string | null;
};

export type UpdateEmailInput = {
    newEmail: string;
    password: string;
};

export type ManuallyVerifyUserInput = {
    username: string;
};

export type CreateClassInput = {
    userId: string;
    sections: Array<ClassSectionInput>;
    members: Array<ClassMemberInput>;
    pinned: Array<string>;
    created: string;
    updated: string;
    name: string;
    description?: string | null;
    timezone?: string | null;
    schoolId?: string | null;
    courseId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIconInput | null;
    color: string;
    cover?: string | null;
    googleClassId?: string | null;
};

export type ClassSectionInput = {
    name: string;
    id: string;
    color: string;
    timings?: Array<ClassTimingsInput | null> | null;
};

export type ClassTimingsInput = {
    start?: string | null;
    end?: string | null;
    days?: Array<string | null> | null;
};

export type ClassMemberInput = {
    userId: string;
    role: ClassRole;
    sections: Array<string>;
    pending?: boolean | null;
};

export type Class = {
    __typename: "Class";
    userId: string;
    classId: string;
    sections: Array<ClassSection>;
    members: Array<ClassMemberWithDetails>;
    pinned: Array<string>;
    created: string;
    updated: string;
    name: string;
    description?: string | null;
    timings?: Array<ClassTimings> | null;
    timezone?: string | null;
    org?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIcon | null;
    color: string;
    cover?: string | null;
    googleClassId?: string | null;
    fileCount?: number | null;
};

export type ClassSection = {
    __typename: "ClassSection";
    name: string;
    id: string;
    color: string;
    timings?: Array<ClassTimings | null> | null;
};

export type ClassTimings = {
    __typename: "ClassTimings";
    start?: string | null;
    end?: string | null;
    days?: Array<string | null> | null;
};

export type ClassMemberWithDetails = {
    __typename: "ClassMemberWithDetails";
    userId: string;
    role: ClassRole;
    sections: Array<string>;
    pending?: boolean | null;
    Name?: string | null;
    pictureUrl?: string | null;
    username?: string | null;
    dpa?: boolean | null;
    lastLogIn?: number | null;
};

export type UpdateClassInput = {
    classId: string;
    sections?: Array<ClassSectionInput> | null;
    members?: Array<ClassMemberInput> | null;
    pinned?: Array<string> | null;
    updated: string;
    name?: string | null;
    description?: string | null;
    timezone?: string | null;
    schoolId?: string | null;
    courseId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    cover?: string | null;
    googleClassId?: string | null;
    fileCount?: number | null;
};

export type DeleteClassInput = {
    classId: string;
};

export type AddMediaS3UserTagInput = {
    bucket: string;
    mediaId: string;
};

export type JoinClassInput = {
    classId: string;
    sections: Array<string>;
};

export type LeaveClassInput = {
    classId: string;
};

export type CreateEmbeddingsInput = {
    input: string;
};

export type CreateExamMCQSessionInput = {
    exam: string;
    examUnit?: string | null;
    difficulty: ExamDifficulty;
    length: number;
};

export enum ExamDifficulty {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
    EXTREME = "EXTREME",
    RANDOM = "RANDOM",
}

export type ExamMCQConnection = {
    __typename: "ExamMCQConnection";
    items: Array<ExamMCQ>;
    nextToken?: string | null;
};

export type ExamMCQ = {
    __typename: "ExamMCQ";
    exam: string;
    questionId: string;
    difficulty: ExamDifficulty;
    choices: Array<string>;
    question: string;
    reasons: Array<string>;
    rating: number;
    ratingCount: number;
    examUnit: string;
    examSection?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    subject?: string | null;
    topic?: string | null;
    created: string;
    updated: string;
    url?: string | null;
    prompt: ExamPromptType;
};

export enum ExamPromptType {
    CONCEPTUAL = "CONCEPTUAL",
    CONCEPTUAL_HISTORY = "CONCEPTUAL_HISTORY",
    EXAMPLE = "EXAMPLE",
    ALL_ARE_TRUE_EXCEPT = "ALL_ARE_TRUE_EXCEPT",
    CAUSE = "CAUSE",
    EFFECT = "EFFECT",
    TRENDS = "TRENDS",
    CHANGE_IN_HISTORY = "CHANGE_IN_HISTORY",
    COMPARE_AND_CONTRAST = "COMPARE_AND_CONTRAST",
    ARGUMENT_FOR_SUPPORTER = "ARGUMENT_FOR_SUPPORTER",
    SUPPORTER_FOR_ARGUMENT = "SUPPORTER_FOR_ARGUMENT",
    SUPPORTING_EVIDENCE = "SUPPORTING_EVIDENCE",
    CONFLICTING_EVIDENCE = "CONFLICTING_EVIDENCE",
    STIMULUS_SPEECH = "STIMULUS_SPEECH",
    STIMULUS_TEXT = "STIMULUS_TEXT",
    STIMULUS_HISTORIAN = "STIMULUS_HISTORIAN",
    EQUATION = "EQUATION",
    EXPERIMENT = "EXPERIMENT",
    REAL_WORLD = "REAL_WORLD",
    EVIDENCE_FOR_CONCLUSION = "EVIDENCE_FOR_CONCLUSION",
    CONCLUSION_FOR_EVIDENCE = "CONCLUSION_FOR_EVIDENCE",
    RANKING = "RANKING",
    EXP_WRONG_1 = "EXP_WRONG_1",
    EXP_WRONG_2 = "EXP_WRONG_2",
    EVENT_EFFECT = "EVENT_EFFECT",
    EVENT_CAUSE = "EVENT_CAUSE",
    COUNTRY_CHOICE = "COUNTRY_CHOICE",
    CHART_CHANGE = "CHART_CHANGE",
    ECON_SIM = "ECON_SIM",
    ECON_DIF = "ECON_DIF",
    ECON_RELATIONSHIP = "ECON_RELATIONSHIP",
    ECON_EQUATION = "ECON_EQUATION",
    TWO_PRONGED = "TWO_PRONGED",
    SAQ_HISTORIAN_DISAGREE = "SAQ_HISTORIAN_DISAGREE",
    SAQ_CAUSE_AND_EFFECT = "SAQ_CAUSE_AND_EFFECT",
    SAQ_PRIMARY_SOURCE = "SAQ_PRIMARY_SOURCE",
    SAQ_CONTINUITY_AND_CHANGE = "SAQ_CONTINUITY_AND_CHANGE",
    SAQ_SIMILARITY_AND_DIFFERENCE = "SAQ_SIMILARITY_AND_DIFFERENCE",
    SAQ_CONTINUITY_AND_CHANGE_NO_STIMULUS = "SAQ_CONTINUITY_AND_CHANGE_NO_STIMULUS",
    LEQ_CAUSATION = "LEQ_CAUSATION",
    LEQ_RELATIVE_CAUSES = "LEQ_RELATIVE_CAUSES",
    LEQ_CHANGES_IN_HISTORY = "LEQ_CHANGES_IN_HISTORY",
    DBQ_CAUSATION = "DBQ_CAUSATION",
    DBQ_RELATIVE_CAUSES = "DBQ_RELATIVE_CAUSES",
    DBQ_CHANGES_IN_HISTORY = "DBQ_CHANGES_IN_HISTORY",
    SCOTUS_COMPARISON = "SCOTUS_COMPARISON",
    DATA_STRUCTURES = "DATA_STRUCTURES",
    PROGRAMMING = "PROGRAMMING",
    CONVERSIONS = "CONVERSIONS",
    EVALUATIONS = "EVALUATIONS",
    IMPACTS = "IMPACTS",
    CONCEPT_RELATIONS = "CONCEPT_RELATIONS",
    CS_JOBS = "CS_JOBS",
    DATA_ANALYSIS = "DATA_ANALYSIS",
    EVALUATE_CODE = "EVALUATE_CODE",
    WHAT_IS_CONTAINED = "WHAT_IS_CONTAINED",
    OOP = "OOP",
    CODE_PURPOSE = "CODE_PURPOSE",
}

export type RateExamMCQInput = {
    questionId: string;
    rating: number;
};

export type RateExamFRQInput = {
    questionId: string;
    partIdx: number;
    rating: number;
};

export type ExamFRQ = {
    __typename: "ExamFRQ";
    exam: string;
    questionId: string;
    difficulty: ExamDifficulty;
    question: string;
    rating: number;
    ratingCount: number;
    examUnit: string;
    examSection?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    subject?: string | null;
    topic?: string | null;
    created: string;
    updated: string;
    url?: string | null;
    prompt: ExamPromptType;
    stimuli?: Array<string> | null;
    name: string;
    parts: Array<ExamFRQPart>;
    rubric: Array<ExamFRQRubricPart>;
    starred: boolean;
};

export type ExamFRQPart = {
    __typename: "ExamFRQPart";
    question: string;
    rating: number;
    ratingCount: number;
};

export type ExamFRQRubricPart = {
    __typename: "ExamFRQRubricPart";
    guidelines: string;
    points: number;
};

export type ReferOrganizationInput = {
    sent: boolean;
    type?: string | null;
    email?: string | null;
};

export type Organization = {
    __typename: "Organization";
    org: string;
    schoolId: string;
    created: string;
    updated: string;
    schools: Array<string>;
    country?: string | null;
    state?: string | null;
    students: number;
    teachers: number;
    paywall?: OrganizationPaywallInfo | null;
    aliases?: Array<string> | null;
    stage: OrganizationStage;
    referrals: Array<OrganizationReferral>;
    dpa?: OrganizationDPA | null;
};

export type OrganizationPaywallInfo = {
    __typename: "OrganizationPaywallInfo";
    count: number;
    lastSent: number;
};

export enum OrganizationStage {
    NONE = "NONE",
    EMAIL_SENT = "EMAIL_SENT",
    MEETING_SET = "MEETING_SET",
    MEETING_COMPLETED = "MEETING_COMPLETED",
    DPA_SIGNED = "DPA_SIGNED",
    SUBSCRIBED = "SUBSCRIBED",
}

export type OrganizationReferral = {
    __typename: "OrganizationReferral";
    userId: string;
    created: string;
    sent: boolean;
    type: string;
    email: string;
};

export type OrganizationDPA = {
    __typename: "OrganizationDPA";
    name?: string | null;
    email?: string | null;
    link?: string | null;
    type: DPAType;
};

export enum DPAType {
    USE = "USE",
    DISCLOSE = "DISCLOSE",
    NONE = "NONE",
}

export type SelectSchoolInput = {
    schoolId: string;
    schoolRole?: SchoolRole | null;
};

export enum SchoolRole {
    TEACHER = "TEACHER",
    IT_ADMIN = "IT_ADMIN",
    ADMIN = "ADMIN",
    PRINCIPAL = "PRINCIPAL",
}

export type UserAndOrganization = {
    __typename: "UserAndOrganization";
    user?: UserDetails | null;
    organization?: Organization | null;
};

export type MigrateAccountContentInput = {
    stage?: boolean | null;
    userId?: string | null;
    commit?: boolean | null;
    migKey?: string | null;
};

export type TrackOrganizationPaywallInput = {
    event?: UpgradeEvent | null;
};

export enum UpgradeEvent {
    FOLDER_COLOR = "FOLDER_COLOR",
    FOLDER_EMOJI = "FOLDER_EMOJI",
    PASSWORD_PROTECT = "PASSWORD_PROTECT",
    PERSONALIZE_PROFILE = "PERSONALIZE_PROFILE",
    USERNAME_CHANGE = "USERNAME_CHANGE",
    REMOVE_ADS = "REMOVE_ADS",
    MAX_VIDEO_AI = "MAX_VIDEO_AI",
    MAX_PDF_AI = "MAX_PDF_AI",
    MAX_NOTE_FLASHCARDS = "MAX_NOTE_FLASHCARDS",
    MAX_NOTE_TESTS = "MAX_NOTE_TESTS",
    MAX_PROMPT = "MAX_PROMPT",
    MAX_EXPLAIN = "MAX_EXPLAIN",
}

export type VerifiedSchool = {
    __typename: "VerifiedSchool";
    type: VerifiedSchoolType;
    schoolId: string;
    name: string;
    country: string;
    state: string;
    city: string;
    updated: string;
    address?: Address | null;
    mailing?: Address | null;
    population?: SchoolPopulation | null;
    aliases?: Array<string> | null;
    gradesLow?: string | null;
    gradesHigh?: string | null;
    website?: string | null;
    phone?: string | null;
};

export enum VerifiedSchoolType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    ADULT_EDUCATION = "ADULT_EDUCATION",
    CHARTER = "CHARTER",
    JUNIOR_COLLEGE = "JUNIOR_COLLEGE",
    TECHNICAL_COLLEGE = "TECHNICAL_COLLEGE",
    BUSINESS_COLLEGE = "BUSINESS_COLLEGE",
    COSMETOLOGY = "COSMETOLOGY",
    COMPUTER_TRAINING = "COMPUTER_TRAINING",
    FLIGHT = "FLIGHT",
    FINE_ARTS = "FINE_ARTS",
    UNIVERSITY = "UNIVERSITY",
    EDUCATIONAL_SUPPORT = "EDUCATIONAL_SUPPORT",
}

export type Address = {
    __typename: "Address";
    streetOne: string;
    streetTwo?: string | null;
    city: string;
    county?: string | null;
    state: string;
    zip: string;
    country: string;
};

export type SchoolPopulation = {
    __typename: "SchoolPopulation";
    teachers: number;
    ft: number;
    pt: number;
};

export type ListNotificationsByUserInput = {
    nextToken?: string | null;
    limit?: number | null;
};

export type NotificationConnection = {
    __typename: "NotificationConnection";
    items: Array<Notification>;
    nextToken?: string | null;
};

export type GetNoteInput = {
    noteId: string;
    password?: string | null;
};

export type GetMediaInput = {
    mediaId: string;
    password?: string | null;
};

export type ListMediaInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type MediaConnection = {
    __typename: "MediaConnection";
    items: Array<Media>;
    nextToken?: string | null;
};

export type ListNoteInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ModelNoteConnection = {
    __typename: "ModelNoteConnection";
    items: Array<Note>;
    nextToken?: string | null;
};

export type ListFlashcardSetByUserInput = {
    userId: string;
    standalone?: boolean | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type FlashcardSetConnection = {
    __typename: "FlashcardSetConnection";
    items: Array<FlashcardSet>;
    nextToken?: string | null;
};

export type ListFlashcardSetByNoteInput = {
    noteId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardsByFlashcardSetInput = {
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFollowingInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
    extraDetails?: boolean | null;
};

export type UserConnection = {
    __typename: "UserConnection";
    items: Array<User>;
    nextToken?: string | null;
};

export type ListFollowersInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
    extraDetails?: boolean | null;
};

export type ListExamFRQsInput = {
    exam: string;
    examUnit?: string | null;
    difficulty: ExamDifficulty;
    limit?: number | null;
    nextToken?: string | null;
};

export type ExamFRQConnection = {
    __typename: "ExamFRQConnection";
    items: Array<ExamFRQ>;
    nextToken?: string | null;
};

export type ListSubjectsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type SubjectConnection = {
    __typename: "SubjectConnection";
    items: Array<Subject>;
    nextToken?: string | null;
};

export type Subject = {
    __typename: "Subject";
    subject?: string | null;
    picture?: string | null;
    color?: string | null;
    category?: string | null;
    order?: number | null;
    topics?: string | null;
};

export type BannerConnection = {
    __typename: "BannerConnection";
    items: Array<Banner>;
};

export type Banner = {
    __typename: "Banner";
    bannerId: string;
    type: BannerType;
    message: string;
    startTime: number;
    endTime: number;
    href?: string | null;
    promoCode?: string | null;
};

export enum BannerType {
    INFO = "INFO",
    WARNING = "WARNING",
    URGENT = "URGENT",
    PROMO = "PROMO",
}

export type ListExamsInput = {
    type?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ExamConnection = {
    __typename: "ExamConnection";
    items: Array<Exam>;
    nextToken?: string | null;
};

export type Exam = {
    __typename: "Exam";
    type: string;
    name: string;
    description?: string | null;
    unitLabel: string;
    sectionLabel: string;
    baseLink: string;
    image: string;
    category?: string | null;
    color?: string | null;
    date?: string | null;
    time?: number | null;
    index?: boolean | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    discord?: Array<ExamResourceType | null> | null;
    youtube?: Array<ExamResourceType | null> | null;
    units?: Array<ExamUnit | null> | null;
};

export type ExamResourceType = {
    __typename: "ExamResourceType";
    name: string;
    link?: string | null;
    image?: string | null;
};

export type ExamUnit = {
    __typename: "ExamUnit";
    name: string;
    image?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    sections?: Array<string | null> | null;
};

export type GetCurrentUserInput = {
    forceStripeVerify?: boolean | null;
};

export type ListBookmarkByUserIdInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type BookmarkConnection = {
    __typename: "BookmarkConnection";
    items: Array<Bookmark>;
    nextToken?: string | null;
};

export type Bookmark = {
    __typename: "Bookmark";
    userId: string;
    ID: string;
    type: ItemType;
    time?: number | null;
};

export type IsBookmarkInput = {
    ID: string;
};

export type IsUsernameAvailableInput = {
    username: string;
};

export type ESQueryFullInput = {
    index: Array<ItemType | null>;
    body: string;
};

export type ESResult = {
    __typename: "ESResult";
    result: string;
};

export type ListFoldersByUserInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type FolderConnection = {
    __typename: "FolderConnection";
    items: Array<Folder>;
    nextToken?: string | null;
};

export type ListFoldersByParentInput = {
    parentId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListNotesByFolderInput = {
    folderId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardSetsByFolderInput = {
    folderId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListMediaByFolderInput = {
    folderId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type GetFolderInput = {
    folderId: string;
    password?: string | null;
};

export type GetRatingInput = {
    noteId: string;
    userId: string;
};

export type GetUserRatingStatsInput = {
    userId: string;
};

export type RatingStats = {
    __typename: "RatingStats";
    count?: number | null;
    rating?: number | null;
};

export type GetNoteRatingStatsInput = {
    noteId: string;
};

export type GetFlashcardSetViewerInput = {
    userId: string;
    flashcardSetId: string;
};

export type QuizletParseInput = {
    url?: string | null;
};

export type QuizletFlashcards = {
    __typename: "QuizletFlashcards";
    data?: string | null;
    title?: string | null;
};

export type AICompletionInput = {
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    input: string;
    subject?: string | null;
    topic?: string | null;
    type: AICompletionType;
};

export type GetExamInput = {
    exam: string;
};

export type GetExamMCQInput = {
    questionId: string;
};

export type GetExamFRQInput = {
    questionId: string;
};

export type FullExamFRQ = {
    __typename: "FullExamFRQ";
    question: ExamFRQ;
    stimuli?: Array<ExamStimulus> | null;
};

export type ExamStimulus = {
    __typename: "ExamStimulus";
    questionId: string;
    stimulusId: string;
    content: string;
    stimulusType: ExamStimulusType;
};

export enum ExamStimulusType {
    TEXT = "TEXT",
    CODE = "CODE",
    IMAGE = "IMAGE",
}

export type GetSubjectInput = {
    subject: string;
};

export type GetBlogInput = {
    slug: string;
};

export type GetBlogByIdInput = {
    blogId: string;
};

export type GetRecommendedBlogInput = {
    tags: string;
    filter?: string | null;
};

export type BlogConnection = {
    __typename: "BlogConnection";
    items: Array<Blog>;
    nextToken?: string | null;
};

export type ListBlogsInput = {
    tags?: string | null;
    sort?: BlogSortKey | null;
    limit?: number | null;
    nextToken?: string | null;
};

export enum BlogSortKey {
    CREATED = "CREATED",
    UPDATE = "UPDATE",
}

export type ListBlogsByUserInput = {
    userId: string;
    sort?: BlogSortKey | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type GetStudySessionInput = {
    itemId: string;
};

export type ListStudySessionsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type StudySessionConnection = {
    __typename: "StudySessionConnection";
    items: Array<StudySession>;
    nextToken?: string | null;
};

export type ListRecentStudySessionsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardStudyStatesInput = {
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type FlashcardStudyStateConnection = {
    __typename: "FlashcardStudyStateConnection";
    items: Array<FlashcardStudyState>;
    nextToken?: string | null;
};

export type ListItemAIChatsInput = {
    itemId: string;
    itemType: ItemType;
    limit?: number | null;
    nextToken?: string | null;
};

export type AICompletionConnection = {
    __typename: "AICompletionConnection";
    items: Array<AICompletion>;
    nextToken?: string | null;
};

export type GetNotificationTokenLevelInput = {
    type: NotificationTokenType;
};

export type GetClassInput = {
    classId: string;
};

export type ClassConnection = {
    __typename: "ClassConnection";
    items: Array<Class>;
    nextToken?: string | null;
};

export type ListClassesInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ListClassStudySessionsInput = {
    classId: string;
    itemId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListClassFlashcardStudyStatesInput = {
    classId: string;
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListNotesByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardSetsByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListMediasByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFoldersByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type CreateNoteMutationVariables = {
    input: CreateNoteInput;
};

export type CreateNoteMutation = {
    createNote?: {
        __typename: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type DeleteNoteMutationVariables = {
    input: DeleteNoteInput;
};

export type DeleteNoteMutation = {
    deleteNote?: {
        __typename: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type UpdateNoteMutationVariables = {
    input: UpdateNoteInput;
};

export type UpdateNoteMutation = {
    updateNote?: {
        __typename: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type GenerateMediaTranscriptionMutationVariables = {
    input: GenerateMediaTranscriptionInput;
};

export type GenerateMediaTranscriptionMutation = {
    generateMediaTranscription?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type CreateMediaWithTranscriptionMutationVariables = {
    input: CreateMediaWithTranscriptionInput;
};

export type CreateMediaWithTranscriptionMutation = {
    createMediaWithTranscription?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type ImportFromLinkMutationVariables = {
    input: ImportFromLinkInput;
};

export type ImportFromLinkMutation = {
    importFromLink?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type DuplicateMediaMutationVariables = {
    input: DuplicateMediaInput;
};

export type DuplicateMediaMutation = {
    duplicateMedia?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type UpdateMediaMutationVariables = {
    input: UpdateMediaInput;
};

export type UpdateMediaMutation = {
    updateMedia?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type DeleteMediaMutationVariables = {
    input: DeleteMediaInput;
};

export type DeleteMediaMutation = {
    deleteMedia?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type CreateFlashcardSetMutationVariables = {
    input: CreateFlashcardSetInput;
};

export type CreateFlashcardSetMutation = {
    createFlashcardSet?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type UpdateFlashcardSetMutationVariables = {
    input: UpdateFlashcardSetInput;
};

export type UpdateFlashcardSetMutation = {
    updateFlashcardSet?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type DeleteFlashcardSetMutationVariables = {
    input: DeleteFlashcardSetInput;
};

export type DeleteFlashcardSetMutation = {
    deleteFlashcardSet?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type CreateFlashcardMutationVariables = {
    input: FlashcardInput;
};

export type CreateFlashcardMutation = {
    createFlashcard?: {
        __typename: "Flashcard";
        flashcardId: string;
        flashcardSetId?: string | null;
        userId?: string | null;
        term?: string | null;
        termAudio?: string | null;
        definition?: string | null;
        definitionAudio?: string | null;
        distractorIds?: Array<string | null> | null;
        questionType?: QuestionType | null;
        isCorrect?: boolean | null;
        draft?: boolean | null;
        trash?: boolean | null;
        disabled?: boolean | null;
        image?: string | null;
        secondaryImage?: string | null;
        edited?: boolean | null;
        created?: string | null;
        updated?: string | null;
        twoSided?: boolean | null;
        schedule?: string | null;
        quality?: number | null;
    } | null;
};

export type BatchUpdateFlashcardMutationVariables = {
    input: FlashcardListInput;
};

export type BatchUpdateFlashcardMutation = {
    batchUpdateFlashcard?: {
        __typename: "FlashcardConnection";
        items: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type DeleteFlashcardMutationVariables = {
    input: FlashcardInput;
};

export type DeleteFlashcardMutation = {
    deleteFlashcard?: {
        __typename: "Flashcard";
        flashcardId: string;
        flashcardSetId?: string | null;
        userId?: string | null;
        term?: string | null;
        termAudio?: string | null;
        definition?: string | null;
        definitionAudio?: string | null;
        distractorIds?: Array<string | null> | null;
        questionType?: QuestionType | null;
        isCorrect?: boolean | null;
        draft?: boolean | null;
        trash?: boolean | null;
        disabled?: boolean | null;
        image?: string | null;
        secondaryImage?: string | null;
        edited?: boolean | null;
        created?: string | null;
        updated?: string | null;
        twoSided?: boolean | null;
        schedule?: string | null;
        quality?: number | null;
    } | null;
};

export type MarkAsReadMutationVariables = {
    input: NotificationInput;
};

export type MarkAsReadMutation = {
    markAsRead?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type DeleteNotificationMutationVariables = {
    input: NotificationInput;
};

export type DeleteNotificationMutation = {
    deleteNotification?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type UpdateUserDetailsMutationVariables = {
    input: UserDetailsInput;
};

export type UpdateUserDetailsMutation = {
    updateUserDetails: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    };
};

export type FollowMutationVariables = {
    input: FollowInput;
};

export type FollowMutation = {
    follow?: {
        __typename: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type UnfollowMutationVariables = {
    input: UnfollowInput;
};

export type UnfollowMutation = {
    unfollow?: {
        __typename: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type RemoveFollowerMutationVariables = {
    input: RemoveFollowerInput;
};

export type RemoveFollowerMutation = {
    removeFollower?: {
        __typename: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type UpdateUsernameMutationVariables = {
    input: UpdateUsernameInput;
};

export type UpdateUsernameMutation = {
    updateUsername?: {
        __typename: "User";
        userId: string;
        pictureUrl?: string | null;
        Name?: string | null;
        accountType?: string | null;
        username?: string | null;
        Email?: string | null;
    } | null;
};

export type ViewMutationVariables = {
    input: ViewInput;
};

export type ViewMutation = {
    view?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type BookmarkMutationVariables = {
    input: CreateBookmarkInput;
};

export type BookmarkMutation = {
    bookmark?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type UnbookmarkMutationVariables = {
    input: DeleteBookmarkInput;
};

export type UnbookmarkMutation = {
    unbookmark?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type CreateSchoolMutationVariables = {
    input?: SchoolInput | null;
};

export type CreateSchoolMutation = {
    createSchool?: {
        __typename: "School";
        schoolId: string;
        name?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        type?: SchoolType | null;
    } | null;
};

export type CreateCourseMutationVariables = {
    input?: CreateCourseInput | null;
};

export type CreateCourseMutation = {
    createCourse?: {
        __typename: "Course";
        courseId: string;
        schoolId: string;
        name?: string | null;
        description?: string | null;
        subject?: string | null;
        topic?: string | null;
    } | null;
};

export type CreateFolderMutationVariables = {
    input: CreateFolderInput;
};

export type CreateFolderMutation = {
    createFolder?: {
        __typename: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type UpdateFolderMutationVariables = {
    input: UpdateFolderInput;
};

export type UpdateFolderMutation = {
    updateFolder?: {
        __typename: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type DeleteFolderMutationVariables = {
    input: DeleteFolderInput;
};

export type DeleteFolderMutation = {
    deleteFolder?: {
        __typename: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type CreateRatingMutationVariables = {
    input: CreateRatingInput;
};

export type CreateRatingMutation = {
    createRating?: {
        __typename: "Rating";
        noteId: string;
        userId: string;
        noteOwnerId: string;
        message?: string | null;
        rating?: number | null;
    } | null;
};

export type UpdateRatingMutationVariables = {
    input: UpdateRatingInput;
};

export type UpdateRatingMutation = {
    updateRating?: {
        __typename: "Rating";
        noteId: string;
        userId: string;
        noteOwnerId: string;
        message?: string | null;
        rating?: number | null;
    } | null;
};

export type CreateFlashcardSetViewerMutationVariables = {
    input: FlashcardSetViewerInput;
};

export type CreateFlashcardSetViewerMutation = {
    createFlashcardSetViewer?: {
        __typename: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type UpdateFlashcardSetViewerMutationVariables = {
    input: FlashcardSetViewerInput;
};

export type UpdateFlashcardSetViewerMutation = {
    updateFlashcardSetViewer?: {
        __typename: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type MigrateFlashcardSetMutationVariables = {
    input: GetFlashcardSetInput;
};

export type MigrateFlashcardSetMutation = {
    migrateFlashcardSet?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type MigrateUserFlashcardSetsMutationVariables = {
    input: UserIdInput;
};

export type MigrateUserFlashcardSetsMutation = {
    migrateUserFlashcardSets?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type VerifyStripeCheckoutMutationVariables = {
    input: StripeConnectionInput;
};

export type VerifyStripeCheckoutMutation = {
    verifyStripeCheckout?: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    } | null;
};

export type RequestDeleteAccountMutationVariables = {
    input: DeleteAccountRequestInput;
};

export type RequestDeleteAccountMutation = {
    requestDeleteAccount?: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    } | null;
};

export type RegisterNotificationTokenMutationVariables = {
    input: CreateNotificationTokenInput;
};

export type RegisterNotificationTokenMutation = {
    registerNotificationToken?: {
        __typename: "NotificationToken";
        userId: string;
        tokenId: string;
        active: number;
        type: NotificationTokenType;
        created: number;
        updated: number;
        os: NotificationTokenOSType;
        level: NotificationLevel;
    } | null;
};

export type UpdateNotificationTokenMutationVariables = {
    input: UpdateNotificationTokenInput;
};

export type UpdateNotificationTokenMutation = {
    updateNotificationToken?: {
        __typename: "NotificationToken";
        userId: string;
        tokenId: string;
        active: number;
        type: NotificationTokenType;
        created: number;
        updated: number;
        os: NotificationTokenOSType;
        level: NotificationLevel;
    } | null;
};

export type UpdateNotificationTokenTypeMutationVariables = {
    input: UpdateNotificationTokenTypeInput;
};

export type UpdateNotificationTokenTypeMutation = {
    updateNotificationTokenType?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type DeleteNotificationTokenMutationVariables = {
    input: DeleteNotificationTokenInput;
};

export type DeleteNotificationTokenMutation = {
    deleteNotificationToken?: {
        __typename: "NotificationToken";
        userId: string;
        tokenId: string;
        active: number;
        type: NotificationTokenType;
        created: number;
        updated: number;
        os: NotificationTokenOSType;
        level: NotificationLevel;
    } | null;
};

export type SendImmediateNotificationMutationVariables = {
    input: SendImmediateNotificationInput;
};

export type SendImmediateNotificationMutation = {
    sendImmediateNotification?: {
        __typename: "Notification";
        userId: string;
        notificationId: string;
        read: number;
        trash: number;
        timestamp: string;
        scheduledAt: string;
        color?: string | null;
        image?: string | null;
        title?: string | null;
        message?: string | null;
        action?: string | null;
        level?: NotificationLevel | null;
        usage: EventUsage;
    } | null;
};

export type SendScheduledNotificationMutationVariables = {
    input: SendScheduledNotificationInput;
};

export type SendScheduledNotificationMutation = {
    sendScheduledNotification?: {
        __typename: "ScheduledEvent";
        created: string;
        updated: string;
        eventTime: number;
        eventId: string;
        userId: string;
        notifType: ScheduledEventEnum;
        usage: EventUsage;
        itemId?: string | null;
        itemType?: ItemType | null;
        completed?: boolean | null;
        color?: string | null;
        image?: string | null;
        title?: string | null;
        message?: string | null;
        action?: string | null;
        level?: NotificationLevel | null;
    } | null;
};

export type CancelScheduledNotificationMutationVariables = {
    input: CancelScheduledNotificationInput;
};

export type CancelScheduledNotificationMutation = {
    cancelScheduledNotification?: {
        __typename: "ScheduledEvent";
        created: string;
        updated: string;
        eventTime: number;
        eventId: string;
        userId: string;
        notifType: ScheduledEventEnum;
        usage: EventUsage;
        itemId?: string | null;
        itemType?: ItemType | null;
        completed?: boolean | null;
        color?: string | null;
        image?: string | null;
        title?: string | null;
        message?: string | null;
        action?: string | null;
        level?: NotificationLevel | null;
    } | null;
};

export type DetectLanguageMutationVariables = {
    input: TextToSpeechInput;
};

export type DetectLanguageMutation = {
    detectLanguage?: {
        __typename: "LanguageResponse";
        language?: Language | null;
        confidence?: number | null;
    } | null;
};

export type TextToSpeechMutationVariables = {
    input: TextToSpeechInput;
};

export type TextToSpeechMutation = {
    textToSpeech?: {
        __typename: "TextToSpeechResponse";
        url: string;
    } | null;
};

export type DeleteAudioMutationVariables = {
    input: TextToSpeechInput;
};

export type DeleteAudioMutation = {
    deleteAudio?: {
        __typename: "TextToSpeechResponse";
        url: string;
    } | null;
};

export type CreatePasswordMutationVariables = {
    input: PasswordInput;
};

export type CreatePasswordMutation = {
    createPassword?: {
        __typename: "Password";
        password: string;
        type: ItemType;
        id: string;
    } | null;
};

export type CheckPasswordMutationVariables = {
    input: PasswordInput;
};

export type CheckPasswordMutation = {
    checkPassword?: boolean | null;
};

export type EditChapterMutationVariables = {
    input: EditChapterInput;
};

export type EditChapterMutation = {
    editChapter?: {
        __typename: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type CreateBlogMutationVariables = {
    input?: BlogInput | null;
};

export type CreateBlogMutation = {
    createBlog?: {
        __typename: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type UpdateBlogMutationVariables = {
    input?: BlogInput | null;
};

export type UpdateBlogMutation = {
    updateBlog?: {
        __typename: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type DeleteBlogMutationVariables = {
    input?: BlogInput | null;
};

export type DeleteBlogMutation = {
    deleteBlog?: {
        __typename: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type StartFlashcardSetStudySessionRoundMutationVariables = {
    input: FlashcardSetStudySessionInput;
};

export type StartFlashcardSetStudySessionRoundMutation = {
    startFlashcardSetStudySessionRound?: {
        __typename: "FlashcardSetStudySessionConnection";
        studySession: {
            __typename: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            know?: number | null;
            dKnow?: number | null;
            settings: {
                __typename: "StudySessionSettings";
                LEARN?: {
                    __typename: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                } | null;
                TEST?: {
                    __typename: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sort?: boolean | null;
                    review?: boolean | null;
                } | null;
            };
            progress: {
                __typename: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            syncTime: string;
            title?: string | null;
        };
        questions: Array<{
            __typename: "FlashcardSetStudySessionQuestion";
            flashcardId: string;
            distractors: Array<string>;
            questionType: QuestionType;
            answerSide: FlashcardSide;
            buttons?: Array<number> | null;
            tfIsCorrect: boolean;
            progress: StudySessionProgressEnum;
            spacing?: {
                __typename: "SpacingIntervals";
                AGAIN: number;
                HARD: number;
                GOOD: number;
                EASY: number;
            } | null;
        }>;
    } | null;
};

export type GradeFlashcardSetStudySessionMutationVariables = {
    input: GradeFlashcardSetStudySessionInput;
};

export type GradeFlashcardSetStudySessionMutation = {
    gradeFlashcardSetStudySession?: {
        __typename: "FlashcardSetStudySessionRound";
        studySession: {
            __typename: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            know?: number | null;
            dKnow?: number | null;
            settings: {
                __typename: "StudySessionSettings";
                LEARN?: {
                    __typename: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                } | null;
                TEST?: {
                    __typename: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sort?: boolean | null;
                    review?: boolean | null;
                } | null;
            };
            progress: {
                __typename: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            syncTime: string;
            title?: string | null;
        };
        studyStates: Array<{
            __typename: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            sort?: boolean | null;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
    } | null;
};

export type ClearFlashcardSetStudySessionMutationVariables = {
    input: ClearFlashcardSetStudySessionInput;
};

export type ClearFlashcardSetStudySessionMutation = {
    clearFlashcardSetStudySession?: {
        __typename: "StudySession";
        userId: string;
        itemId: string;
        type: ItemType;
        created: string;
        updated: string;
        examDate?: string | null;
        newCards: number;
        nextDue: string;
        nextNewDue: string;
        know?: number | null;
        dKnow?: number | null;
        settings: {
            __typename: "StudySessionSettings";
            LEARN?: {
                __typename: "LearnStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                length?: number | null;
            } | null;
            TEST?: {
                __typename: "TestStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                length: number;
            } | null;
            SPACED?: {
                __typename: "SpacedStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                cardsPerSession: number;
                intervals?: Array<number> | null;
            } | null;
            MATCH?: {
                __typename: "MatchStudySessionSetting";
                grid: boolean;
                starred: boolean;
                length: number;
            } | null;
            REVIEW?: {
                __typename: "ReviewStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                sort?: boolean | null;
                review?: boolean | null;
            } | null;
        };
        progress: {
            __typename: "StudySessionProgress";
            NEW: number;
            LEARNING: number;
            REVIEWING: number;
            MASTERED: number;
        };
        syncTime: string;
        title?: string | null;
    } | null;
};

export type SubmitAICompletionMutationVariables = {
    input?: SubmitAICompletionInput | null;
};

export type SubmitAICompletionMutation = {
    submitAICompletion?: {
        __typename: "AICompletion";
        userId: string;
        timestamp?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        flashcardId?: string | null;
        itemId?: string | null;
        itemType?: ItemType | null;
        id?: string | null;
        input: string;
        messages?: Array<{
            __typename: "AIMessage";
            role?: string | null;
            content?: string | null;
        } | null> | null;
        output: string;
        inputTokens?: number | null;
        outputTokens?: number | null;
        subject?: string | null;
        topic?: string | null;
        type?: AICompletionType | null;
        timeTaken?: number | null;
    } | null;
};

export type UpdateEmailMutationVariables = {
    input: UpdateEmailInput;
};

export type UpdateEmailMutation = {
    updateEmail?: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    } | null;
};

export type ManuallyVerifyUserMutationVariables = {
    input: ManuallyVerifyUserInput;
};

export type ManuallyVerifyUserMutation = {
    manuallyVerifyUser?: boolean | null;
};

export type CreateClassMutationVariables = {
    input: CreateClassInput;
};

export type CreateClassMutation = {
    createClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type UpdateClassMutationVariables = {
    input: UpdateClassInput;
};

export type UpdateClassMutation = {
    updateClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type DeleteClassMutationVariables = {
    input: DeleteClassInput;
};

export type DeleteClassMutation = {
    deleteClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type AddMediaS3UserTagMutationVariables = {
    input: AddMediaS3UserTagInput;
};

export type AddMediaS3UserTagMutation = {
    addMediaS3UserTag?: boolean | null;
};

export type JoinClassMutationVariables = {
    input: JoinClassInput;
};

export type JoinClassMutation = {
    joinClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type LeaveClassMutationVariables = {
    input: LeaveClassInput;
};

export type LeaveClassMutation = {
    leaveClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type GenerateReferralCodeMutationVariables = {};

export type GenerateReferralCodeMutation = {
    generateReferralCode?: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    } | null;
};

export type CreateEmbeddingsMutationVariables = {
    input: CreateEmbeddingsInput;
};

export type CreateEmbeddingsMutation = {
    createEmbeddings?: string | null;
};

export type CreateExamMCQSessionMutationVariables = {
    input: CreateExamMCQSessionInput;
};

export type CreateExamMCQSessionMutation = {
    createExamMCQSession?: {
        __typename: "ExamMCQConnection";
        items: Array<{
            __typename: "ExamMCQ";
            exam: string;
            questionId: string;
            difficulty: ExamDifficulty;
            choices: Array<string>;
            question: string;
            reasons: Array<string>;
            rating: number;
            ratingCount: number;
            examUnit: string;
            examSection?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            subject?: string | null;
            topic?: string | null;
            created: string;
            updated: string;
            url?: string | null;
            prompt: ExamPromptType;
        }>;
        nextToken?: string | null;
    } | null;
};

export type RateExamMCQMutationVariables = {
    input: RateExamMCQInput;
};

export type RateExamMCQMutation = {
    rateExamMCQ?: {
        __typename: "ExamMCQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        choices: Array<string>;
        question: string;
        reasons: Array<string>;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
    } | null;
};

export type RateExamFRQMutationVariables = {
    input: RateExamFRQInput;
};

export type RateExamFRQMutation = {
    rateExamFRQ?: {
        __typename: "ExamFRQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        question: string;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
        stimuli?: Array<string> | null;
        name: string;
        parts: Array<{
            __typename: "ExamFRQPart";
            question: string;
            rating: number;
            ratingCount: number;
        }>;
        rubric: Array<{
            __typename: "ExamFRQRubricPart";
            guidelines: string;
            points: number;
        }>;
        starred: boolean;
    } | null;
};

export type ReferOrganizationMutationVariables = {
    input: ReferOrganizationInput;
};

export type ReferOrganizationMutation = {
    referOrganization?: {
        __typename: "Organization";
        org: string;
        schoolId: string;
        created: string;
        updated: string;
        schools: Array<string>;
        country?: string | null;
        state?: string | null;
        students: number;
        teachers: number;
        paywall?: {
            __typename: "OrganizationPaywallInfo";
            count: number;
            lastSent: number;
        } | null;
        aliases?: Array<string> | null;
        stage: OrganizationStage;
        referrals: Array<{
            __typename: "OrganizationReferral";
            userId: string;
            created: string;
            sent: boolean;
            type: string;
            email: string;
        }>;
        dpa?: {
            __typename: "OrganizationDPA";
            name?: string | null;
            email?: string | null;
            link?: string | null;
            type: DPAType;
        } | null;
    } | null;
};

export type SelectSchoolMutationVariables = {
    input: SelectSchoolInput;
};

export type SelectSchoolMutation = {
    selectSchool?: {
        __typename: "UserAndOrganization";
        user?: {
            __typename: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: string | null;
            ID?: string | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            recentlyViewed?: Array<{
                __typename: "View";
                ID: string;
                type?: ItemType | null;
                time?: number | null;
                count?: number | null;
            } | null> | null;
            views?: number | null;
            birthday?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                interval?: string | null;
                status?: string | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            personalizedAds?: boolean | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
                history?: Array<{
                    __typename: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    cost?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename: "ClassInfo";
                enrolled: Array<{
                    __typename: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
        } | null;
        organization?: {
            __typename: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            schools: Array<string>;
            country?: string | null;
            state?: string | null;
            students: number;
            teachers: number;
            paywall?: {
                __typename: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
        } | null;
    } | null;
};

export type MigrateAccountContentMutationVariables = {
    input: MigrateAccountContentInput;
};

export type MigrateAccountContentMutation = {
    migrateAccountContent?: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    } | null;
};

export type TrackOrganizationPaywallMutationVariables = {
    input: TrackOrganizationPaywallInput;
};

export type TrackOrganizationPaywallMutation = {
    trackOrganizationPaywall?: {
        __typename: "Organization";
        org: string;
        schoolId: string;
        created: string;
        updated: string;
        schools: Array<string>;
        country?: string | null;
        state?: string | null;
        students: number;
        teachers: number;
        paywall?: {
            __typename: "OrganizationPaywallInfo";
            count: number;
            lastSent: number;
        } | null;
        aliases?: Array<string> | null;
        stage: OrganizationStage;
        referrals: Array<{
            __typename: "OrganizationReferral";
            userId: string;
            created: string;
            sent: boolean;
            type: string;
            email: string;
        }>;
        dpa?: {
            __typename: "OrganizationDPA";
            name?: string | null;
            email?: string | null;
            link?: string | null;
            type: DPAType;
        } | null;
    } | null;
};

export type _unusedSchoolApiMutationVariables = {};

export type _unusedSchoolApiMutation = {
    _unusedSchoolApi?: {
        __typename: "VerifiedSchool";
        type: VerifiedSchoolType;
        schoolId: string;
        name: string;
        country: string;
        state: string;
        city: string;
        updated: string;
        address?: {
            __typename: "Address";
            streetOne: string;
            streetTwo?: string | null;
            city: string;
            county?: string | null;
            state: string;
            zip: string;
            country: string;
        } | null;
        mailing?: {
            __typename: "Address";
            streetOne: string;
            streetTwo?: string | null;
            city: string;
            county?: string | null;
            state: string;
            zip: string;
            country: string;
        } | null;
        population?: {
            __typename: "SchoolPopulation";
            teachers: number;
            ft: number;
            pt: number;
        } | null;
        aliases?: Array<string> | null;
        gradesLow?: string | null;
        gradesHigh?: string | null;
        website?: string | null;
        phone?: string | null;
    } | null;
};

export type ListNotificationsByUserQueryVariables = {
    input: ListNotificationsByUserInput;
};

export type ListNotificationsByUserQuery = {
    listNotificationsByUser?: {
        __typename: "NotificationConnection";
        items: Array<{
            __typename: "Notification";
            userId: string;
            notificationId: string;
            read: number;
            trash: number;
            timestamp: string;
            scheduledAt: string;
            color?: string | null;
            image?: string | null;
            title?: string | null;
            message?: string | null;
            action?: string | null;
            level?: NotificationLevel | null;
            usage: EventUsage;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetNoteQueryVariables = {
    input: GetNoteInput;
};

export type GetNoteQuery = {
    getNote?: {
        __typename: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type GetMediaQueryVariables = {
    input: GetMediaInput;
};

export type GetMediaQuery = {
    getMedia?: {
        __typename: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type ListMediaQueryVariables = {
    input: ListMediaInput;
};

export type ListMediaQuery = {
    listMedia?: {
        __typename: "MediaConnection";
        items: Array<{
            __typename: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesQueryVariables = {
    input: ListNoteInput;
};

export type ListNotesQuery = {
    listNotes?: {
        __typename: "ModelNoteConnection";
        items: Array<{
            __typename: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetFlashcardSetQueryVariables = {
    input: GetFlashcardSetInput;
};

export type GetFlashcardSetQuery = {
    getFlashcardSet?: {
        __typename: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        textbookId?: string | null;
        chapterId?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        courseId?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type ListFlashcardSetByUserQueryVariables = {
    input: ListFlashcardSetByUserInput;
};

export type ListFlashcardSetByUserQuery = {
    listFlashcardSetByUser?: {
        __typename: "FlashcardSetConnection";
        items: Array<{
            __typename: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetByNoteQueryVariables = {
    input: ListFlashcardSetByNoteInput;
};

export type ListFlashcardSetByNoteQuery = {
    listFlashcardSetByNote?: {
        __typename: "FlashcardSetConnection";
        items: Array<{
            __typename: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardsByFlashcardSetQueryVariables = {
    input: ListFlashcardsByFlashcardSetInput;
};

export type ListFlashcardsByFlashcardSetQuery = {
    listFlashcardsByFlashcardSet?: {
        __typename: "FlashcardConnection";
        items: Array<{
            __typename: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFollowingQueryVariables = {
    input: ListFollowingInput;
};

export type ListFollowingQuery = {
    listFollowing?: {
        __typename: "UserConnection";
        items: Array<{
            __typename: "User";
            userId: string;
            pictureUrl?: string | null;
            Name?: string | null;
            accountType?: string | null;
            username?: string | null;
            Email?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFollowersQueryVariables = {
    input: ListFollowersInput;
};

export type ListFollowersQuery = {
    listFollowers?: {
        __typename: "UserConnection";
        items: Array<{
            __typename: "User";
            userId: string;
            pictureUrl?: string | null;
            Name?: string | null;
            accountType?: string | null;
            username?: string | null;
            Email?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListExamFRQsQueryVariables = {
    input: ListExamFRQsInput;
};

export type ListExamFRQsQuery = {
    listExamFRQs?: {
        __typename: "ExamFRQConnection";
        items: Array<{
            __typename: "ExamFRQ";
            exam: string;
            questionId: string;
            difficulty: ExamDifficulty;
            question: string;
            rating: number;
            ratingCount: number;
            examUnit: string;
            examSection?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            subject?: string | null;
            topic?: string | null;
            created: string;
            updated: string;
            url?: string | null;
            prompt: ExamPromptType;
            stimuli?: Array<string> | null;
            name: string;
            parts: Array<{
                __typename: "ExamFRQPart";
                question: string;
                rating: number;
                ratingCount: number;
            }>;
            rubric: Array<{
                __typename: "ExamFRQRubricPart";
                guidelines: string;
                points: number;
            }>;
            starred: boolean;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListSubjectsQueryVariables = {
    input?: ListSubjectsInput | null;
};

export type ListSubjectsQuery = {
    listSubjects?: {
        __typename: "SubjectConnection";
        items: Array<{
            __typename: "Subject";
            subject?: string | null;
            picture?: string | null;
            color?: string | null;
            category?: string | null;
            order?: number | null;
            topics?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBannersQueryVariables = {};

export type ListBannersQuery = {
    listBanners?: {
        __typename: "BannerConnection";
        items: Array<{
            __typename: "Banner";
            bannerId: string;
            type: BannerType;
            message: string;
            startTime: number;
            endTime: number;
            href?: string | null;
            promoCode?: string | null;
        }>;
    } | null;
};

export type ListExamsQueryVariables = {
    input?: ListExamsInput | null;
};

export type ListExamsQuery = {
    listExams?: {
        __typename: "ExamConnection";
        items: Array<{
            __typename: "Exam";
            type: string;
            name: string;
            description?: string | null;
            unitLabel: string;
            sectionLabel: string;
            baseLink: string;
            image: string;
            category?: string | null;
            color?: string | null;
            date?: string | null;
            time?: number | null;
            index?: boolean | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            discord?: Array<{
                __typename: "ExamResourceType";
                name: string;
                link?: string | null;
                image?: string | null;
            } | null> | null;
            youtube?: Array<{
                __typename: "ExamResourceType";
                name: string;
                link?: string | null;
                image?: string | null;
            } | null> | null;
            units?: Array<{
                __typename: "ExamUnit";
                name: string;
                image?: string | null;
                noteId?: string | null;
                flashcardSetId?: string | null;
                sections?: Array<string | null> | null;
            } | null> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type IsFollowingQueryVariables = {
    input: FollowInput;
};

export type IsFollowingQuery = {
    isFollowing?: boolean | null;
};

export type GetCurrentUserQueryVariables = {
    input?: GetCurrentUserInput | null;
};

export type GetCurrentUserQuery = {
    getCurrentUser: {
        __typename: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: string | null;
        ID?: string | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        recentlyViewed?: Array<{
            __typename: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null> | null;
        views?: number | null;
        birthday?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            interval?: string | null;
            status?: string | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        personalizedAds?: boolean | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            cost?: number | null;
            history?: Array<{
                __typename: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename: "ClassInfo";
            enrolled: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
    };
};

export type GetCurrentUserAndOrganizationQueryVariables = {
    input?: GetCurrentUserInput | null;
};

export type GetCurrentUserAndOrganizationQuery = {
    getCurrentUserAndOrganization: {
        __typename: "UserAndOrganization";
        user?: {
            __typename: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: string | null;
            ID?: string | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            recentlyViewed?: Array<{
                __typename: "View";
                ID: string;
                type?: ItemType | null;
                time?: number | null;
                count?: number | null;
            } | null> | null;
            views?: number | null;
            birthday?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                interval?: string | null;
                status?: string | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            personalizedAds?: boolean | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                cost?: number | null;
                history?: Array<{
                    __typename: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    cost?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename: "ClassInfo";
                enrolled: Array<{
                    __typename: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
        } | null;
        organization?: {
            __typename: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            schools: Array<string>;
            country?: string | null;
            state?: string | null;
            students: number;
            teachers: number;
            paywall?: {
                __typename: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
        } | null;
    };
};

export type ListBookmarkByUserIdQueryVariables = {
    input: ListBookmarkByUserIdInput;
};

export type ListBookmarkByUserIdQuery = {
    listBookmarkByUserId?: {
        __typename: "BookmarkConnection";
        items: Array<{
            __typename: "Bookmark";
            userId: string;
            ID: string;
            type: ItemType;
            time?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type IsBookmarkedQueryVariables = {
    input: IsBookmarkInput;
};

export type IsBookmarkedQuery = {
    isBookmarked?: boolean | null;
};

export type IsUsernameAvailableQueryVariables = {
    input: IsUsernameAvailableInput;
};

export type IsUsernameAvailableQuery = {
    isUsernameAvailable?: boolean | null;
};

export type ESQueryFullQueryVariables = {
    input: ESQueryFullInput;
};

export type ESQueryFullQuery = {
    ESQueryFull?: {
        __typename: "ESResult";
        result: string;
    } | null;
};

export type ListFoldersByUserQueryVariables = {
    input: ListFoldersByUserInput;
};

export type ListFoldersByUserQuery = {
    listFoldersByUser?: {
        __typename: "FolderConnection";
        items: Array<{
            __typename: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFoldersByParentQueryVariables = {
    input: ListFoldersByParentInput;
};

export type ListFoldersByParentQuery = {
    listFoldersByParent?: {
        __typename: "FolderConnection";
        items: Array<{
            __typename: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesByFolderQueryVariables = {
    input: ListNotesByFolderInput;
};

export type ListNotesByFolderQuery = {
    listNotesByFolder?: {
        __typename: "ModelNoteConnection";
        items: Array<{
            __typename: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetsByFolderQueryVariables = {
    input: ListFlashcardSetsByFolderInput;
};

export type ListFlashcardSetsByFolderQuery = {
    listFlashcardSetsByFolder?: {
        __typename: "FlashcardSetConnection";
        items: Array<{
            __typename: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListMediaByFolderQueryVariables = {
    input: ListMediaByFolderInput;
};

export type ListMediaByFolderQuery = {
    listMediaByFolder?: {
        __typename: "MediaConnection";
        items: Array<{
            __typename: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetFolderQueryVariables = {
    input: GetFolderInput;
};

export type GetFolderQuery = {
    getFolder?: {
        __typename: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
    } | null;
};

export type GetRatingQueryVariables = {
    input: GetRatingInput;
};

export type GetRatingQuery = {
    getRating?: {
        __typename: "Rating";
        noteId: string;
        userId: string;
        noteOwnerId: string;
        message?: string | null;
        rating?: number | null;
    } | null;
};

export type GetUserRatingStatsQueryVariables = {
    input: GetUserRatingStatsInput;
};

export type GetUserRatingStatsQuery = {
    getUserRatingStats?: {
        __typename: "RatingStats";
        count?: number | null;
        rating?: number | null;
    } | null;
};

export type GetNoteRatingStatsQueryVariables = {
    input: GetNoteRatingStatsInput;
};

export type GetNoteRatingStatsQuery = {
    getNoteRatingStats?: {
        __typename: "RatingStats";
        count?: number | null;
        rating?: number | null;
    } | null;
};

export type GetFlashcardSetViewerQueryVariables = {
    input: GetFlashcardSetViewerInput;
};

export type GetFlashcardSetViewerQuery = {
    getFlashcardSetViewer?: {
        __typename: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type GetQuizletFlashcardsQueryVariables = {
    input: QuizletParseInput;
};

export type GetQuizletFlashcardsQuery = {
    getQuizletFlashcards?: {
        __typename: "QuizletFlashcards";
        data?: string | null;
        title?: string | null;
    } | null;
};

export type RequestAICompletionQueryVariables = {
    input: AICompletionInput;
};

export type RequestAICompletionQuery = {
    requestAICompletion?: {
        __typename: "AICompletion";
        userId: string;
        timestamp?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        flashcardId?: string | null;
        itemId?: string | null;
        itemType?: ItemType | null;
        id?: string | null;
        input: string;
        messages?: Array<{
            __typename: "AIMessage";
            role?: string | null;
            content?: string | null;
        } | null> | null;
        output: string;
        inputTokens?: number | null;
        outputTokens?: number | null;
        subject?: string | null;
        topic?: string | null;
        type?: AICompletionType | null;
        timeTaken?: number | null;
    } | null;
};

export type GetExamQueryVariables = {
    input: GetExamInput;
};

export type GetExamQuery = {
    getExam?: {
        __typename: "Exam";
        type: string;
        name: string;
        description?: string | null;
        unitLabel: string;
        sectionLabel: string;
        baseLink: string;
        image: string;
        category?: string | null;
        color?: string | null;
        date?: string | null;
        time?: number | null;
        index?: boolean | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        discord?: Array<{
            __typename: "ExamResourceType";
            name: string;
            link?: string | null;
            image?: string | null;
        } | null> | null;
        youtube?: Array<{
            __typename: "ExamResourceType";
            name: string;
            link?: string | null;
            image?: string | null;
        } | null> | null;
        units?: Array<{
            __typename: "ExamUnit";
            name: string;
            image?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            sections?: Array<string | null> | null;
        } | null> | null;
    } | null;
};

export type GetExamMCQQueryVariables = {
    input: GetExamMCQInput;
};

export type GetExamMCQQuery = {
    getExamMCQ?: {
        __typename: "ExamMCQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        choices: Array<string>;
        question: string;
        reasons: Array<string>;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
    } | null;
};

export type GetExamFRQQueryVariables = {
    input: GetExamFRQInput;
};

export type GetExamFRQQuery = {
    getExamFRQ?: {
        __typename: "FullExamFRQ";
        question: {
            __typename: "ExamFRQ";
            exam: string;
            questionId: string;
            difficulty: ExamDifficulty;
            question: string;
            rating: number;
            ratingCount: number;
            examUnit: string;
            examSection?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            subject?: string | null;
            topic?: string | null;
            created: string;
            updated: string;
            url?: string | null;
            prompt: ExamPromptType;
            stimuli?: Array<string> | null;
            name: string;
            parts: Array<{
                __typename: "ExamFRQPart";
                question: string;
                rating: number;
                ratingCount: number;
            }>;
            rubric: Array<{
                __typename: "ExamFRQRubricPart";
                guidelines: string;
                points: number;
            }>;
            starred: boolean;
        };
        stimuli?: Array<{
            __typename: "ExamStimulus";
            questionId: string;
            stimulusId: string;
            content: string;
            stimulusType: ExamStimulusType;
        }> | null;
    } | null;
};

export type GetSubjectQueryVariables = {
    input: GetSubjectInput;
};

export type GetSubjectQuery = {
    getSubject?: {
        __typename: "Subject";
        subject?: string | null;
        picture?: string | null;
        color?: string | null;
        category?: string | null;
        order?: number | null;
        topics?: string | null;
    } | null;
};

export type GetBlogQueryVariables = {
    input: GetBlogInput;
};

export type GetBlogQuery = {
    getBlog?: {
        __typename: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type GetBlogByIdQueryVariables = {
    input: GetBlogByIdInput;
};

export type GetBlogByIdQuery = {
    getBlogById?: {
        __typename: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type GetRecommendedBlogsQueryVariables = {
    input: GetRecommendedBlogInput;
};

export type GetRecommendedBlogsQuery = {
    getRecommendedBlogs?: {
        __typename: "BlogConnection";
        items: Array<{
            __typename: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBlogsQueryVariables = {
    input: ListBlogsInput;
};

export type ListBlogsQuery = {
    listBlogs?: {
        __typename: "BlogConnection";
        items: Array<{
            __typename: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBlogsByUserQueryVariables = {
    input: ListBlogsByUserInput;
};

export type ListBlogsByUserQuery = {
    listBlogsByUser?: {
        __typename: "BlogConnection";
        items: Array<{
            __typename: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetStudySessionQueryVariables = {
    input: GetStudySessionInput;
};

export type GetStudySessionQuery = {
    getStudySession?: {
        __typename: "StudySession";
        userId: string;
        itemId: string;
        type: ItemType;
        created: string;
        updated: string;
        examDate?: string | null;
        newCards: number;
        nextDue: string;
        nextNewDue: string;
        know?: number | null;
        dKnow?: number | null;
        settings: {
            __typename: "StudySessionSettings";
            LEARN?: {
                __typename: "LearnStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                length?: number | null;
            } | null;
            TEST?: {
                __typename: "TestStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                length: number;
            } | null;
            SPACED?: {
                __typename: "SpacedStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                cardsPerSession: number;
                intervals?: Array<number> | null;
            } | null;
            MATCH?: {
                __typename: "MatchStudySessionSetting";
                grid: boolean;
                starred: boolean;
                length: number;
            } | null;
            REVIEW?: {
                __typename: "ReviewStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                sort?: boolean | null;
                review?: boolean | null;
            } | null;
        };
        progress: {
            __typename: "StudySessionProgress";
            NEW: number;
            LEARNING: number;
            REVIEWING: number;
            MASTERED: number;
        };
        syncTime: string;
        title?: string | null;
    } | null;
};

export type ListStudySessionsQueryVariables = {
    input: ListStudySessionsInput;
};

export type ListStudySessionsQuery = {
    listStudySessions?: {
        __typename: "StudySessionConnection";
        items: Array<{
            __typename: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            know?: number | null;
            dKnow?: number | null;
            settings: {
                __typename: "StudySessionSettings";
                LEARN?: {
                    __typename: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                } | null;
                TEST?: {
                    __typename: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sort?: boolean | null;
                    review?: boolean | null;
                } | null;
            };
            progress: {
                __typename: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            syncTime: string;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListRecentStudySessionsQueryVariables = {
    input: ListRecentStudySessionsInput;
};

export type ListRecentStudySessionsQuery = {
    listRecentStudySessions?: {
        __typename: "StudySessionConnection";
        items: Array<{
            __typename: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            know?: number | null;
            dKnow?: number | null;
            settings: {
                __typename: "StudySessionSettings";
                LEARN?: {
                    __typename: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                } | null;
                TEST?: {
                    __typename: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sort?: boolean | null;
                    review?: boolean | null;
                } | null;
            };
            progress: {
                __typename: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            syncTime: string;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardStudyStatesQueryVariables = {
    input: ListFlashcardStudyStatesInput;
};

export type ListFlashcardStudyStatesQuery = {
    listFlashcardStudyStates?: {
        __typename: "FlashcardStudyStateConnection";
        items: Array<{
            __typename: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            sort?: boolean | null;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListItemAIChatsQueryVariables = {
    input: ListItemAIChatsInput;
};

export type ListItemAIChatsQuery = {
    listItemAIChats?: {
        __typename: "AICompletionConnection";
        items: Array<{
            __typename: "AICompletion";
            userId: string;
            timestamp?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            flashcardId?: string | null;
            itemId?: string | null;
            itemType?: ItemType | null;
            id?: string | null;
            input: string;
            messages?: Array<{
                __typename: "AIMessage";
                role?: string | null;
                content?: string | null;
            } | null> | null;
            output: string;
            inputTokens?: number | null;
            outputTokens?: number | null;
            subject?: string | null;
            topic?: string | null;
            type?: AICompletionType | null;
            timeTaken?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetNotificationTokenLevelQueryVariables = {
    input: GetNotificationTokenLevelInput;
};

export type GetNotificationTokenLevelQuery = {
    getNotificationTokenLevel?: NotificationLevel | null;
};

export type GetClassQueryVariables = {
    input: GetClassInput;
};

export type GetClassQuery = {
    getClass?: {
        __typename: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
    } | null;
};

export type ListEnrolledClassesQueryVariables = {};

export type ListEnrolledClassesQuery = {
    listEnrolledClasses?: {
        __typename: "ClassConnection";
        items: Array<{
            __typename: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListClassesQueryVariables = {
    input: ListClassesInput;
};

export type ListClassesQuery = {
    listClasses?: {
        __typename: "ClassConnection";
        items: Array<{
            __typename: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListClassStudySessionsQueryVariables = {
    input: ListClassStudySessionsInput;
};

export type ListClassStudySessionsQuery = {
    listClassStudySessions?: {
        __typename: "StudySessionConnection";
        items: Array<{
            __typename: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            know?: number | null;
            dKnow?: number | null;
            settings: {
                __typename: "StudySessionSettings";
                LEARN?: {
                    __typename: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                } | null;
                TEST?: {
                    __typename: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sort?: boolean | null;
                    review?: boolean | null;
                } | null;
            };
            progress: {
                __typename: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            syncTime: string;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListClassFlashcardStudyStatesQueryVariables = {
    input: ListClassFlashcardStudyStatesInput;
};

export type ListClassFlashcardStudyStatesQuery = {
    listClassFlashcardStudyStates?: {
        __typename: "FlashcardStudyStateConnection";
        items: Array<{
            __typename: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            sort?: boolean | null;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesByClassQueryVariables = {
    input: ListNotesByClassInput;
};

export type ListNotesByClassQuery = {
    listNotesByClass?: {
        __typename: "ModelNoteConnection";
        items: Array<{
            __typename: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetsByClassQueryVariables = {
    input: ListFlashcardSetsByClassInput;
};

export type ListFlashcardSetsByClassQuery = {
    listFlashcardSetsByClass?: {
        __typename: "FlashcardSetConnection";
        items: Array<{
            __typename: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListMediasByClassQueryVariables = {
    input: ListMediasByClassInput;
};

export type ListMediasByClassQuery = {
    listMediasByClass?: {
        __typename: "MediaConnection";
        items: Array<{
            __typename: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            courseId?: string | null;
            textbookId?: string | null;
            chapterId?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFoldersByClassQueryVariables = {
    input: ListFoldersByClassInput;
};

export type ListFoldersByClassQuery = {
    listFoldersByClass?: {
        __typename: "FolderConnection";
        items: Array<{
            __typename: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
        }>;
        nextToken?: string | null;
    } | null;
};
