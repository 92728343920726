import { Node as ProsemirrorNode } from "../../pm/model";
import { MarkdownSerializerState } from "../../pm/markdown";
import Node from "./Node";

export default class Heading extends Node {
    className = "heading-name";

    get name() {
        return "heading";
    }

    get defaultOptions() {
        return {
            levels: [1, 2, 3, 4],
            collapsed: undefined,
        };
    }

    get schema() {
        return {
            attrs: {
                level: {
                    default: 1,
                },
                collapsed: {
                    default: undefined,
                },
            },
            content: "inline*",
            group: "block",
            defining: true,
            draggable: false,
            parseDOM: this.options.levels.map(level => ({
                tag: `h${level}`,
                getAttrs: () => ({ level }),
            })),
            toDOM: node => [
                `h${node.attrs.level + (this.options.offset || 0)}`,
                ["span", { class: "heading-content" }, 0],
            ],
        };
    }

    toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
        state.write(state.repeat("#", node.attrs.level) + " ");
        state.renderInline(node);
        state.closeBlock(node);
    }

    parseMarkdown() {
        return {
            block: "heading",
            getAttrs: (token: Record<string, any>) => ({
                level: +token.tag.slice(1),
            }),
        };
    }
}
