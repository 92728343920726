import Node from "./Node";

export default class HorizontalRule extends Node {
    get name() {
        return "hr";
    }

    get schema() {
        return {
            attrs: {
                markup: {
                    default: "---",
                },
            },
            group: "block",
            parseDOM: [{ tag: "hr" }],
            toDOM: node => {
                return ["hr", { class: node.attrs.markup === "***" ? "page-break" : "" }];
            },
        };
    }

    toMarkdown(state, node) {
        state.write(`\n${node.attrs.markup}`);
        state.closeBlock(node);
    }

    parseMarkdown() {
        return {
            node: "hr",
            getAttrs: tok => ({
                markup: tok.markup,
            }),
        };
    }
}
