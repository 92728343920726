import LocalKeyValueStoreInterface from "@knowt/syncing/interfaces/LocalKeyValueStore";

const LocalKeyValueStore: LocalKeyValueStoreInterface = {
    get: async (key: string) => localStorage.getItem(key),
    set: async (key: string, value: string) => localStorage.setItem(key, value),
    remove: async (key: string) => localStorage.removeItem(key),
    getWithExpiry: async (key: string) => {
        const itemStr = localStorage[key];
        if (!itemStr) return null;
        const item: { expiry: number; value: unknown } = JSON.parse(itemStr);
        if (!item.expiry || !item.value) return null;
        if (new Date().getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    },
    setWithExpiry: async (key: string, value: unknown, ttl = 60_000) => {
        localStorage[key] = JSON.stringify({
            value,
            expiry: new Date().getTime() + ttl,
        });
    },
};

export default LocalKeyValueStore;
