import { pluralize } from "@/utils/stringUtils";
import dayjs from "dayjs";

/**
 * shows the value in milliseconds
 */
export enum TIME {
    SECOND = 1000,
    MINUTE = 1000 * 60,
    HOUR = 1000 * 60 * 60,
    DAY = 1000 * 60 * 60 * 24,
    WEEK = 1000 * 60 * 60 * 24 * 7,
    MONTH = 1000 * 60 * 60 * 24 * 30,
    YEAR = 1000 * 60 * 60 * 24 * 365,
}

/**
 * shows the value in seconds
 */
export enum TIME_SECONDS {
    SECOND = 1,
    MINUTE = 60,
    HOUR = 60 * 60,
    DAY = 60 * 60 * 24,
    WEEK = 60 * 60 * 24 * 7,
    MONTH = 60 * 60 * 24 * 30,
    YEAR = 60 * 60 * 24 * 365,
}

// returns in format MM/DD/YYYY or YYYY-MM-DD if withDashes is true
export const getParsedUTCDate = (date: number | string | Date, withDashes?: boolean) => {
    if (!date) return "";
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate().toString();
    const month = (dateObj.getUTCMonth() + 1).toString();
    const year = dateObj.getUTCFullYear().toString();

    return withDashes
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
};

export const getTimeFromNow = (timestamp: number | Date) => {
    timestamp = new Date(timestamp).getTime();

    // make sure timestamp is in ms
    if (timestamp < 1000000000000) {
        timestamp *= 1000;
    }

    const now = Date.now();
    const diff = Math.abs(timestamp - now);

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
        days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
    };
};

export const timeDeltaFromNow = (timestamp: number) => {
    if (!timestamp) {
        return "...";
    }

    const { days, hours, minutes, seconds } = getTimeFromNow(timestamp);

    if (days) {
        return `${days} ${pluralize("day", days)}`;
    } else if (hours) {
        return `${hours} ${pluralize("hour", hours)}`;
    } else if (minutes) {
        return `${minutes} ${pluralize("minute", minutes)}`;
    } else {
        return `${seconds} ${pluralize("second", seconds)}`;
    }
};

// returns in format 00:00:00 format, with hours only if > 0
export const formatTimeSpan = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours > 0 ? String(hours).padStart(2, "0") + ":" : "";
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return formattedHours + formattedMinutes + ":" + formattedSeconds;
};

export const secondsToLargerUnit = (seconds: number): { value: number; unit: "second" | "minute" | "hour" } => {
    if (seconds < 60) {
        return { value: Math.floor(seconds), unit: "second" };
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return { value: minutes, unit: "minute" };
    } else {
        const hours = Math.floor(seconds / 3600);
        return { value: hours, unit: "hour" };
    }
};

export const parseStringTimestampToFloat = timestamp => {
    const timeParts = timestamp?.split(":").map(parseFloat);
    let [hours, minutes, seconds] = timeParts;

    if (timeParts.length === 2) {
        hours = 0; // Set default value for hours
        [minutes, seconds] = timeParts;
    }

    return (hours * 3600 + minutes * 60 + seconds) * 1.0;
};

export const getUnixTimeFromDDMMYY = (dateStr: string) => {
    const [dayStr, monthStr, yearStr] = dateStr.split("-").map(Number);

    if (isNaN(dayStr) || isNaN(monthStr) || isNaN(yearStr)) {
        throw new Error('Invalid date format. Please use "dd-mm-yy" format.');
    }

    const date = new Date(yearStr, monthStr, dayStr);

    return date.getTime();
};

export const formatUnixSecondsTimestamp = (unixSecondsTimestamp: number | string | undefined | null) => {
    const date = new Date(Number(unixSecondsTimestamp) * 1000);

    // Format day, month, and year
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    // Format hour and minute
    const minute = date.getMinutes().toString().padStart(2, "0");
    const period = date.getHours() >= 12 ? "pm" : "am"; // https://english.stackexchange.com/a/35317
    const hourIn12HoursSystem = date.getHours() % 12;
    const hour = hourIn12HoursSystem === 0 ? "12" : hourIn12HoursSystem.toString().padStart(2, "0");

    return {
        day,
        month,
        year,
        hour,
        minute,
        period,
        date: unixSecondsTimestamp ? `${year}-${month}-${day}` : "N/A",
        time: unixSecondsTimestamp ? `${hour}:${minute} ${period}` : "N/A",
    };
};

export const formatTimeStudying = (seconds: number) => {
    const { value, unit } = secondsToLargerUnit(seconds);
    return `${value} ${pluralize(unit, value)}`;
};

export const formatUnixTimestampToDateTime = (timestamp: number) => {
    const { day, month, year, hour, minute, period } = formatUnixSecondsTimestamp(timestamp);

    return `${month}-${day}-${year} ${hour}:${minute} ${period}`;
};

export const convertUnixToDateTime = (unixTimestamp: number) => {
    const date = dayjs(unixTimestamp * 1000);
    const formattedDate = date.format("MM/DD/YYYY");
    const formattedTime = date.format("h:mm A");
    return {
        date: formattedDate,
        time: formattedTime,
    };
};
