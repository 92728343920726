import Node from "./Node";

export default class Blockquote extends Node {
    get name() {
        return "blockquote";
    }

    get schema() {
        return {
            content: "block+",
            group: "block",
            defining: true,
            parseDOM: [{ tag: "blockquote" }],
            toDOM: () => ["blockquote", 0],
        };
    }

    toMarkdown(state, node) {
        state.wrapBlock("> ", null, node, () => state.renderContent(node));
    }

    parseMarkdown() {
        return { block: "blockquote" };
    }
}
