export const passwordHasDigits = (password: string) => {
    return /\d/.test(password);
};

export const passwordHasLowercase = (password: string) => {
    return password.toUpperCase() !== password;
};

export const passwordLengthBiggerEqualTo8 = (password: string) => {
    return password.length >= 8;
};

export const checkPasswordMatches = (password: string, confirmPassword: string) => {
    return password === confirmPassword;
};

export const isEmail = email => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(String(email).toLowerCase());
};

export const usernameLengthCheck = (username: string) => {
    return username?.length && username.length >= 3 && username.length < 100;
};

export const nameLengthCheck = (name: string) => {
    // https://bit.ly/3e7FD40 :)
    return name?.length <= 747;
};

/**
 * Check username to only allow:
 * lowercase, uppercase, number, _, and .
 */
export const usernameHasProhibitedCharacter = (username: string) => {
    const regex = /^[a-zA-Z0-9_.]*$/;
    return !regex.test(username);
};

export const ageOlderThan13 = (birthday: Date) => {
    const birthdayToCheck = new Date(birthday.getFullYear() + 13, birthday.getMonth(), birthday.getDate());
    return birthdayToCheck <= new Date();
};

export const validateUsername = (username: string) => {
    if (!username) {
        throw new Error("Please enter a username");
    }
    if (usernameHasProhibitedCharacter(username)) {
        throw new Error("Username can only contain letters (a-z), numbers (0-9), and periods (.).");
    }
    if (!usernameLengthCheck(username)) {
        throw new Error("Username must be between 3 and 100 characters");
    }
};

export const validateUsernameSubtly = (username: string) => {
    if (usernameHasProhibitedCharacter(username)) {
        throw new Error("Username can only contain letters (a-z), numbers (0-9), and periods (.).");
    }
    if (!usernameLengthCheck(username)) {
        throw new Error("Username must be between 3 and 100 characters");
    }
};

export const validateBirthdayByPlainBirthdayString = (_birthday: Date) => {
    const birthday = new Date(_birthday);

    if (!birthday && !birthday?.toISOString()) {
        throw new Error("Please enter your birthdate");
    }
    if (!ageOlderThan13(birthday)) {
        throw new Error("You must be 13 or older to use Knowt.");
    }
};

export const validateAccountType = (accountType: "Student" | "Teacher") => {
    if (!(accountType === "Student" || accountType === "Teacher")) {
        throw new Error(`Account type can be either Student or Teacher. Found: ${accountType}`);
    }
};

export type AccountType = "Student" | "Teacher";

type ValidateSignupForm = {
    name: string;
    birthday: Date;
    username: string;
    email: string;
    password: string;
    accountType: AccountType;
};

export const validateSignupForm = ({ name, birthday, username, email, password, accountType }: ValidateSignupForm) => {
    validateBirthdayByPlainBirthdayString(birthday);

    if (!isEmail(email)) {
        throw new Error("Invalid email");
    }

    validateUsername(username);

    if (!passwordLengthBiggerEqualTo8(password)) {
        throw new Error("Password must be at least 8 characters in length");
    }
    if (!passwordHasDigits(password)) {
        throw new Error("Password must contain at least 1 digit.");
    }
    if (!passwordHasLowercase(password)) {
        throw new Error("Password must contain at least 1 lowercase letter.");
    }

    if (!nameLengthCheck(name)) {
        throw new Error(
            "The Guinness World Record for longest name is 747 characters. Submit an application and then email us!"
        );
    }

    validateAccountType(accountType);
};
