import { createHTMLDocument, VHTMLDocument } from "zeed-dom";
import { DOMSerializer, Node as ProsemirrorNode } from "../../pm/model";

export const serializeToHTML = ({ doc, domSerializer }: { doc: ProsemirrorNode; domSerializer: DOMSerializer }) => {
    const serializedFragment = domSerializer.serializeFragment(doc.content, {
        document: createHTMLDocument() as unknown as Document,
    }) as unknown as VHTMLDocument;

    return serializedFragment.render();
};
