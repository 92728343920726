import { parseHTML } from "zeed-dom";
import { isMarkdown } from "../md/helpers/isMarkdown";

export const isHtml = (content: string) => {
    if (!containsHtml(content)) return false;

    // if it has html, then it might be a html content,
    // or it might be a markdown content that contains html.
    // we will give more priority to markdown
    return !isMarkdown(content);
};

const containsHtml = (content: string) => {
    // some of our md content contains `<<` (it's a blue highlight),
    // if we don't clean it, `parseHTML` get stuck.
    const dom = parseHTML(removeDoubleLessThan(content));

    return dom.childNodes.some(node => node.nodeType === 1);
};

const removeDoubleLessThan = str => str.replace(/<</g, "");

// very loose check to see if a html string has color, bold, italic, underline, etc.
export const hasFormatting = (content: string) => {
    return (
        /style="[^"]*"/g.test(content) ||
        /data-color="/g.test(content) ||
        /<em>/g.test(content) ||
        /<strong>/g.test(content) ||
        /<ul><li>/g.test(content) ||
        /<ol><li>/g.test(content)
    );
};
