"use client";

import { forwardRef } from "react";
import CircularButton, { CircularButtonProps } from "../CircularButton";

export type CircularRectTextButtonProps = Omit<CircularButtonProps, "radius">;

/**
 * Circular rectangle text button based on `CircularButton`
 * NOTE:
 * - Please prefer to style with `sx` rather than `style`
 */
const CircularRectTextButton = forwardRef(({ sx, ...circularButtonProps }: CircularRectTextButtonProps, ref) => {
    return (
        <CircularButton
            ref={ref}
            sx={{
                fontFamily: "var(--knowt-font-name)",
                fontWeight: "600",
                border: "1px solid #00000000",
                ...sx,
            }}
            aria-label={undefined}
            title={undefined}
            {...circularButtonProps}
        />
    );
});

CircularRectTextButton.displayName = "CircularRectTextButton";
export default CircularRectTextButton;
