export const isMarkdown = (text: string) => {
    // ignore text inside `<pre><code>...</code></pre>`
    text = text.replace(/<pre\b[^>]*><code\b[^>]*>[\s\S]*?<\/code><\/pre>/g, "");

    // code-ish
    const fences = text.match(/^```/gm);
    if (fences && fences.length > 1) return true;

    // link-ish
    if (text.match(/\[[^]+]\(https?:\/\/\S+\)/gm)) return true;
    if (text.match(/\[[^]+]\(\/\S+\)/gm)) return true;

    // heading-ish
    if (text.match(/^#{1,6}\s+\S+/gm)) return true;

    // list-ish
    const listItems = text.match(/^[\d-*].?\s\S+/gm);
    return listItems && listItems.length > 1;
};
