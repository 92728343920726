import Node from "./Node";

export default class BulletList extends Node {
    get name() {
        return "bullet_list";
    }

    get schema() {
        return {
            content: "list_item+",
            group: "block",
            parseDOM: [{ tag: "ul" }],
            toDOM: () => ["ul", 0],
        };
    }

    toMarkdown(state, node) {
        state.renderList(node, "  ", () => (node.attrs.bullet || "*") + " ");
    }

    parseMarkdown() {
        return { block: "bullet_list" };
    }
}
