import { useEffect } from "react";
import { Fetcher, Key, Middleware, mutate, SWRConfiguration, SWRHook, unstable_serialize, useSWRConfig } from "swr";
// eslint-disable-next-line no-restricted-imports
import useSWRImmutableOrig from "swr/immutable";
import { isFunction } from "@/utils/genericUtils";

// wrapper around swr `mutate` that does not mutate the cache unless it exists..
// if it does not exist, it triggers a revalidation instead.
// TODO: revalidations only happen if there is a fetcher associated with this key!!!
//  so revisit this hook and reconsider if it's needed.
// TODO(types): this says it returns any[], but it can be a record type too.
export const safeLocalMutate = async (key, updater) => {
    let shouldRevalidate = false;
    const boundedMutate = (...args) => mutate(key, ...args);

    const maybeUpdatedData = await boundedMutate(
        async oldData => {
            if (!oldData) {
                shouldRevalidate = true;
                return;
            }

            return await updater(oldData);
        },
        { revalidate: false }
    );

    if (!shouldRevalidate) {
        return maybeUpdatedData;
    }

    return await boundedMutate();
};

export const populateCacheWithFallbackData = useSWRNext => {
    const useInitialData = (key, fetcher, config) => {
        const globalConfig = useSWRConfig();

        const result = useSWRNext(key, fetcher, config);
        const { mutate } = result;

        const { data: populatedKeys, mutate: mutatePopulatedKeys } = useSWRImmutableOrig("populatedKeys", () => ({}));

        useEffect(() => {
            const asyncEffect = async () => {
                const wasAlreadyPopulated = populatedKeys?.[key];
                if (wasAlreadyPopulated) return;

                const fallbackData = config.fallbackData || globalConfig.fallback[unstable_serialize(key)];
                if (!fallbackData) return;

                const currentCache = await mutate(current => current, { revalidate: false });
                if (currentCache) return;

                mutate(fallbackData, { revalidate: false });
                mutatePopulatedKeys(current => ({ ...current, [key]: true }), { revalidate: false });
            };

            asyncEffect();
        }, [mutate, config.fallbackData, key, globalConfig.fallback, populatedKeys, mutatePopulatedKeys]);

        return result;
    };

    return useInitialData;
};

const withMiddleware = (useSWR: SWRHook, middleware: Middleware): SWRHook => {
    return <Data = any, Error = any>(
        ...args: [Key] | [Key, Fetcher<Data> | null] | [Key, Fetcher<Data> | null, SWRConfiguration | undefined]
    ) => {
        const [key, fn, config] = normalize(args);
        const uses = (config.use || []).concat(middleware);
        return useSWR<Data, Error>(key, fn, { ...config, use: uses });
    };
};

/**
 * returns [key, fetcher, config] with fetcher set to null if not provided
 * @param args
 */
const normalize = <KeyType = Key, Data = any>(
    args: [KeyType] | [KeyType, Fetcher<Data> | null] | [KeyType, Fetcher<Data> | null, SWRConfiguration | undefined]
): [KeyType, Fetcher<Data> | null, Partial<SWRConfiguration<Data>>] => {
    return [args[0], isFunction(args[1]) ? args[1] : null, args[2] || {}];
};

/**
 * Context: swr hooks (either useSWR or useSWRImmutable) does not populate the cache with
 * `fallbackData` by default; instead, the cache is only populated when a fetch is made.
 * However, if using `useSWRImmutable` with provided `fallbackData`, a fetch will never be made,
 * hence, the cache will remain empty -- this hook solves that problem by making sure the cache
 * is populated when suitable, using the `populateCacheWithFallbackData` middleware.
 */
export const useSWRImmutable = withMiddleware(useSWRImmutableOrig, populateCacheWithFallbackData);
