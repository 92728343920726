import * as APITypes from "./schema";
import * as CustomAPITypes from "./customSchema";

type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

export const listNotesNoContent = /* GraphQL */ `query ListNotes($input: ListNoteInput!) {
  listNotes(input: $input) {
    items {
      userId
      noteId
      hash
      public
      password
      summary
      title
      trash
      created
      updated
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, CustomAPITypes.ListNotesNoContentQuery>;

export const listFlashcardSetByUserNoContent =
    /* GraphQL */ `query ListFlashcardSetByUser($input: ListFlashcardSetByUserInput!) {
  listFlashcardSetByUser(input: $input) {
    items {
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      answerSide
      shuffled
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByUserQueryVariables, CustomAPITypes.ListFlashcardSetByUserNoContentQuery>;

export const getNoteMetadata = /* GraphQL */ `query GetNote($input: GetNoteInput!) {
  getNote(input: $input) {
    userId
    noteId
    hash
    public
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    views
    verified
    classPublic
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;

export const listFlashcardSetIdByNote =
    /* GraphQL */ `query ListFlashcardSetByNote($input: ListFlashcardSetByNoteInput!) {
  listFlashcardSetByNote(input: $input) {
    items {
      userId
      flashcardSetId
      updated
      draft
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByNoteQueryVariables, APITypes.ListFlashcardSetByNoteQuery>;

export const listRawFlashcardsByFlashcardSet =
    /* GraphQL */ `query ListFlashcardsByFlashcardSet($input: ListFlashcardsByFlashcardSetInput!) {
  listFlashcardsByFlashcardSet(input: $input) {
    items {
      flashcardId
      flashcardSetId
      userId
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<
        APITypes.ListFlashcardsByFlashcardSetQueryVariables,
        CustomAPITypes.ListRawFlashcardsByFlashcardSetQuery
    >;

export const listExamsMetadata = /* GraphQL */ `query ListExams($input: ListExamsInput) {
  listExams(input: $input) {
    items {
      type
      name
      description
      category
      color
      index
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamsQueryVariables, CustomAPITypes.ListExamsMetadataQuery>;

export const listSubjectsMetadata = /* GraphQL */ `query ListSubjects($input: ListSubjectsInput) {
  listSubjects(input: $input) {
    items {
      subject
      color
      category
      order
      __typename
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListSubjectsQueryVariables, CustomAPITypes.ListSubjectsMetadataQuery>;

export const getRawFlashcardSet = /* GraphQL */ `query GetFlashcardSet($input: GetFlashcardSetInput!) {
  getFlashcardSet(input: $input) {
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFlashcardSetQueryVariables, CustomAPITypes.GetRawFlashcardSetQuery>;
