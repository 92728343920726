import { MarkSpec } from "../../../pm/model";
import Mark from "../Mark";
import { markRule } from "../../rules";

export class RedHighlight extends Mark {
    get name() {
        return "highlight_red";
    }

    get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "red",
                },
            },
            excludes: "highlight",
            group: "highlight",
            parseDOM: [
                {
                    tag: "mark",
                    getAttrs: node => node.getAttribute("class") === "red highlight",
                },
                {
                    style: "background-color",
                    getAttrs: value => !!value && value === "red",
                },
            ],
            toDOM: () => [
                "mark",
                {
                    "data-color": "red",
                },
            ],
        };
    }

    get rulePlugins() {
        return [markRule({ delim: "==", mark: "highlight_red" })];
    }

    get toMarkdown() {
        return {
            open: "==",
            close: "==",
            mixable: true,
            expelEnclosingWhitespace: true,
        };
    }

    parseMarkdown() {
        return { mark: "highlight_red" };
    }
}
