import { MarkdownSerializerState } from "../../pm/markdown";
import { Node as ProsemirrorNode, NodeSpec } from "../../pm/model";
import Extension from "../Extension";

export default abstract class Node extends Extension {
    get type() {
        return "node";
    }

    abstract get schema(): NodeSpec;

    get markdownToken(): string {
        return "";
    }

    toMarkdown(_state: MarkdownSerializerState, _node: ProsemirrorNode) {
        return;
    }

    parseMarkdown() {
        return;
    }
}
