import { FlashcardSide } from "@knowt/syncing/graphql/schema";
import { platform } from "../platform";

/**
 * gets random int INCLUSIVE of the min and max => [min, max]
 */
export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fromEntries = <T>(entries: [string, T][]) => {
    if (!entries) return null;

    return entries.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {} as Record<string, T>);
};

export const max = <T>(items: T[], key: string) =>
    items.reduce((max, curr, idx) => {
        if (idx === 0) return curr;
        return curr[key] > max[key] ? curr : max;
    }, null);

export const min = <T>(items: T[], key: string) =>
    items.reduce((min, curr, idx) => {
        if (idx === 0) return curr;
        return curr[key] < min[key] ? curr : min;
    }, null);

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const avg = (items: number[]) => {
    if (items.length === 0) return 0;
    return items.reduce((acc, x) => acc + x, 0) / items.length;
};

/***
 * Takes in a function and returns a debounced version of it that won't be called until there has been
 * no call to the function (with the same UID as input) for a length of time equal to debounceInterval.
 * This is useful for storing notes and preventing too many successive saves to the same note.
 */
export const debounce = (fun, debounceInterval = 1000) => {
    const callRecord = {};

    const debounceFn = (uid, ...rest) => {
        const lastCall = callRecord[uid];
        if (lastCall) {
            clearTimeout(lastCall);
        }
        callRecord[uid] = setTimeout(() => {
            fun(uid, ...rest);
            callRecord[uid] = null;
        }, debounceInterval);
    };

    return debounceFn;
};

/**
 * Takes in a function that returns a promise and retries that promise until it resolves (up until maxRetries),
 * waiting the given delay quantity.
 */
export const retry: <T>(fun: () => Promise<T>, maxRetries?: number, delay?: number) => Promise<T> = (
    fun,
    maxRetries = 3,
    delay = 2000
) => {
    return new Promise((resolve, reject) => {
        fun()
            .then(resolve)
            .catch(async err => {
                if (maxRetries === 0) {
                    reject(err);
                    return;
                }

                await platform.analytics.logging().then(({ log }) => log(err));
                await wait(delay);

                retry(fun, maxRetries - 1, delay)
                    .then(resolve)
                    .catch(reject);
            });
    });
};

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Example: {'a': 'b', 'c': 'd'} => '?a=b&c=d'
 *          { } => ''
 */
export const createQueryStrings = (
    input: Record<string, string | number | boolean | null | undefined>,
    valueParser: (value: string | number | boolean) => string = value => value.toString()
) => {
    const filtered = Object.entries(input).filter(([_, value]) => !!value);

    if (filtered.length === 0) {
        return "";
    }

    return (
        "?" +
        filtered
            // we can safely cast because of this filter expression
            .map(([key, value]) => `${key}=${valueParser(value as string | number | boolean)}`)
            .join("&")
    );
};

export const renameKey = <T extends Record<string, unknown>, K extends keyof T>(obj: T, oldKey: K, newKey: string) => {
    delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];
};

// TODO: move to somewhere related to flashcards
export const getAnswerSide = ({
    answerWithTerm,
    answerWithDef,
}: {
    answerWithTerm: boolean;
    answerWithDef: boolean;
}) => {
    if (answerWithTerm && answerWithDef) {
        return FlashcardSide.BOTH;
    } else if (answerWithTerm) {
        return FlashcardSide.TERM;
    } else {
        return FlashcardSide.DEFINITION;
    }
};

// TODO: move to somewhere related to flashcards
export const getAnswerToggles = (answerSide?: FlashcardSide) => {
    if (!answerSide) {
        return {
            answerWithTerm: true,
            answerWithDef: false,
        };
    }

    if (answerSide === FlashcardSide.BOTH) {
        return {
            answerWithTerm: true,
            answerWithDef: true,
        };
    } else if (answerSide === FlashcardSide.TERM) {
        return {
            answerWithTerm: true,
            answerWithDef: false,
        };
    } else {
        return {
            answerWithTerm: false,
            answerWithDef: true,
        };
    }
};

/**
 * checks if given color is dark or light - might be useful for custom themes in the future
 * @param color
 */

export const isDarkColor = (color?: string) => {
    if (!color) return false;
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 < 150;
};

export const isFunction = <T extends (...args: any[]) => any = (...args: any[]) => any>(v: unknown): v is T =>
    typeof v == "function";
