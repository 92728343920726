import clsx from "clsx";
import { Rem } from "@/types/common";
import styles from "./container.module.css";
import { Flex, FlexColumn } from "@/components/Flex";

type StyleMustProps = {
    /** Since every page has different top and bottom padding, so *it is a must* to pass the `padding-block` */
    paddingBlock: `${Rem} ${Rem}`;
} & React.CSSProperties;

/**
 * Our general main container width and it's max-width.
 * - This main container should be the first wrapper to all the layout inside it such: The content/InnerContainer and the ads.
 * - This is container is just a normal `Flex` with some style defining it's width, max-width, side's padding on desktop and mobile, and margin.
 * - Every page has different top and bottom padding, so *it is a must* to pass the `padding-block` through style.
 */
export const MainContainer = ({
    children,
    className,
    style,
    ...divProps
}: { style: StyleMustProps } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Flex
            style={{ margin: "0 auto", ...style }}
            className={clsx(styles.mainSidePadding, styles.mainContainerWidth, className)}
            {...divProps}>
            {children}
        </Flex>
    );
};

/**
 * Our general content container
 * - Inner container should be the wrapper for main content "not including ads"
 * - This is just a `FlexColumn` with some style defining it's width, max-width, and margin and force the content to stretch until it's maximum
 */
export const InnerContainer = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            style={{ flex: 1, margin: "0 auto", ...style }}
            className={clsx(styles.innerContainerWidth, divProps.className)}
            {...divProps}>
            {children}
        </FlexColumn>
    );
};

/**
 * Our general section container
 * - This is just a `FlexColumn` with some style defining it's width, max-width, and margin and force the content to stretch until it's maximum
 * - This is used to wrap a section of content that has a title and subtitle
 */
export const Section = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
    return <FlexColumn style={{ ...style }}>{children}</FlexColumn>;
};

/**
 * Our general section header
 * - This is just a `h2` with some style defining it's font size and font weight
 * - This is used to wrap a section of content that has a title and then some component under it (mostly landing pages)
 */
export const SectionHeader = ({ style = {}, children }) => {
    return <h2 style={{ fontSize: "2.2rem", fontWeight: "600", ...style }}>{children}</h2>;
};
