export default class Extension {
    options: Record<string, any>;
    editor;

    constructor(options: Record<string, any> = {}) {
        this.options = {
            ...this.defaultOptions,
            ...options,
        };
    }

    bindEditor(editor) {
        this.editor = editor;
    }

    get type() {
        return "extension";
    }

    get name() {
        return "";
    }

    get plugins(): Plugin[] {
        return [];
    }

    get rulePlugins(): ((md: any) => void)[] {
        return [];
    }

    keys(_: any) {
        return {};
    }

    inputRules(_: any) {
        return [];
    }

    commands(_: any) {
        return (_: any) => () => false;
    }

    get defaultOptions() {
        return {};
    }
}
