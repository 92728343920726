import { Rem } from "@/types/common";

type Spacing = "NONE" | "XXS" | "XS" | "XS_2" | "SM" | "MD" | "LG_S" | "LG" | "LG_2" | "XL";

/**
 * Represents a collection of spacing values for consistent layout and design.
 */
export const spacing: {
    [key in Spacing]: Rem;
} = {
    /** @value "0" */
    NONE: "0rem",

    /** @value "0.4rem" */
    XXS: "0.4rem",

    /** @value "0.8rem" */
    XS: "0.8rem",

    /** @value "1.2rem" */
    XS_2: "1.2rem",

    /** @value "1.6rem" */
    SM: "1.6rem",

    /** @value "2.4rem" */
    MD: "2.4rem",

    /** @value "2.8rem" */
    LG_S: "2.8rem",

    /** @value "3.2rem" */
    LG: "3.2rem",

    /** @value "3.6rem" */
    LG_2: "3.6rem",

    /** @value "5.0rem" */
    XL: "5.0rem",
};
