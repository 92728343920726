import { AnyFunction } from "@/types/common";

class Event {
    private listeners: Map<string, AnyFunction> = new Map();

    register(listener: AnyFunction) {
        const id = Math.random().toString(36).substr(2, 9);
        this.listeners.set(id, listener);

        return () => {
            this.listeners.delete(id);
        };
    }

    notify(...args: unknown[]) {
        const clonedListeners = Array.from(this.listeners.values());
        clonedListeners.forEach(listener => listener(...args));
    }
}

export default Event;
