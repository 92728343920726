/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./schema";
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

export const listNotificationsByUser =
    /* GraphQL */ `query ListNotificationsByUser($input: ListNotificationsByUserInput!) {
  listNotificationsByUser(input: $input) {
    items {
      userId
      notificationId
      read
      trash
      timestamp
      scheduledAt
      color
      image
      title
      message
      action
      level
      usage
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotificationsByUserQueryVariables, APITypes.ListNotificationsByUserQuery>;
export const getNote = /* GraphQL */ `query GetNote($input: GetNoteInput!) {
  getNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    textbookId
    chapterId
    views
    verified
    classPublic
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const getMedia = /* GraphQL */ `query GetMedia($input: GetMediaInput!) {
  getMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    transcript
    schoolId
    grade
    courseId
    textbookId
    chapterId
    views
    jwt
    classId
    addedAt
    sections
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const listMedia = /* GraphQL */ `query ListMedia($input: ListMediaInput!) {
  listMedia(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      transcript
      schoolId
      grade
      courseId
      textbookId
      chapterId
      views
      jwt
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediaQueryVariables, APITypes.ListMediaQuery>;
export const listNotes = /* GraphQL */ `query ListNotes($input: ListNoteInput!) {
  listNotes(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      textbookId
      chapterId
      views
      verified
      classPublic
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const getFlashcardSet = /* GraphQL */ `query GetFlashcardSet($input: GetFlashcardSetInput!) {
  getFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    textbookId
    chapterId
    verified
    schoolId
    grade
    courseId
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
  }
}
` as GeneratedQuery<APITypes.GetFlashcardSetQueryVariables, APITypes.GetFlashcardSetQuery>;
export const listFlashcardSetByUser =
    /* GraphQL */ `query ListFlashcardSetByUser($input: ListFlashcardSetByUserInput!) {
  listFlashcardSetByUser(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      textbookId
      chapterId
      verified
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByUserQueryVariables, APITypes.ListFlashcardSetByUserQuery>;
export const listFlashcardSetByNote =
    /* GraphQL */ `query ListFlashcardSetByNote($input: ListFlashcardSetByNoteInput!) {
  listFlashcardSetByNote(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      textbookId
      chapterId
      verified
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByNoteQueryVariables, APITypes.ListFlashcardSetByNoteQuery>;
export const listFlashcardsByFlashcardSet =
    /* GraphQL */ `query ListFlashcardsByFlashcardSet($input: ListFlashcardsByFlashcardSetInput!) {
  listFlashcardsByFlashcardSet(input: $input) {
    items {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardsByFlashcardSetQueryVariables, APITypes.ListFlashcardsByFlashcardSetQuery>;
export const listFollowing = /* GraphQL */ `query ListFollowing($input: ListFollowingInput!) {
  listFollowing(input: $input) {
    items {
      userId
      pictureUrl
      Name
      accountType
      username
      Email
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFollowingQueryVariables, APITypes.ListFollowingQuery>;
export const listFollowers = /* GraphQL */ `query ListFollowers($input: ListFollowersInput!) {
  listFollowers(input: $input) {
    items {
      userId
      pictureUrl
      Name
      accountType
      username
      Email
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFollowersQueryVariables, APITypes.ListFollowersQuery>;
export const listExamFRQs = /* GraphQL */ `query ListExamFRQs($input: ListExamFRQsInput!) {
  listExamFRQs(input: $input) {
    items {
      exam
      questionId
      difficulty
      question
      rating
      ratingCount
      examUnit
      examSection
      noteId
      flashcardSetId
      subject
      topic
      created
      updated
      url
      prompt
      stimuli
      name
      parts {
        question
        rating
        ratingCount
      }
      rubric {
        guidelines
        points
      }
      starred
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamFRQsQueryVariables, APITypes.ListExamFRQsQuery>;
export const listSubjects = /* GraphQL */ `query ListSubjects($input: ListSubjectsInput) {
  listSubjects(input: $input) {
    items {
      subject
      picture
      color
      category
      order
      topics
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListSubjectsQueryVariables, APITypes.ListSubjectsQuery>;
export const listBanners = /* GraphQL */ `query ListBanners {
  listBanners {
    items {
      bannerId
      type
      message
      startTime
      endTime
      href
      promoCode
    }
  }
}
` as GeneratedQuery<APITypes.ListBannersQueryVariables, APITypes.ListBannersQuery>;
export const listExams = /* GraphQL */ `query ListExams($input: ListExamsInput) {
  listExams(input: $input) {
    items {
      type
      name
      description
      unitLabel
      sectionLabel
      baseLink
      image
      category
      color
      date
      time
      index
      noteId
      flashcardSetId
      discord {
        name
        link
        image
      }
      youtube {
        name
        link
        image
      }
      units {
        name
        image
        noteId
        flashcardSetId
        sections
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamsQueryVariables, APITypes.ListExamsQuery>;
export const isFollowing = /* GraphQL */ `query IsFollowing($input: FollowInput!) {
  isFollowing(input: $input)
}
` as GeneratedQuery<APITypes.IsFollowingQueryVariables, APITypes.IsFollowingQuery>;
export const getCurrentUser = /* GraphQL */ `query GetCurrentUser($input: GetCurrentUserInput) {
  getCurrentUser(input: $input) {
    Email
    org
    accountType
    ID
    timeZone
    referral
    Name
    pictureUrl
    username
    recentlyViewed {
      ID
      type
      time
      count
    }
    views
    birthday
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      interval
      status
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    personalizedAds
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      cost
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
  }
}
` as GeneratedQuery<APITypes.GetCurrentUserQueryVariables, APITypes.GetCurrentUserQuery>;
export const getCurrentUserAndOrganization =
    /* GraphQL */ `query GetCurrentUserAndOrganization($input: GetCurrentUserInput) {
  getCurrentUserAndOrganization(input: $input) {
    user {
      Email
      org
      accountType
      ID
      timeZone
      referral
      Name
      pictureUrl
      username
      recentlyViewed {
        ID
        type
        time
        count
      }
      views
      birthday
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        interval
        status
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      personalizedAds
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        cost
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          cost
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
    }
    organization {
      org
      schoolId
      created
      updated
      schools
      country
      state
      students
      teachers
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
    }
  }
}
` as GeneratedQuery<APITypes.GetCurrentUserAndOrganizationQueryVariables, APITypes.GetCurrentUserAndOrganizationQuery>;
export const listBookmarkByUserId = /* GraphQL */ `query ListBookmarkByUserId($input: ListBookmarkByUserIdInput!) {
  listBookmarkByUserId(input: $input) {
    items {
      userId
      ID
      type
      time
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBookmarkByUserIdQueryVariables, APITypes.ListBookmarkByUserIdQuery>;
export const isBookmarked = /* GraphQL */ `query IsBookmarked($input: IsBookmarkInput!) {
  isBookmarked(input: $input)
}
` as GeneratedQuery<APITypes.IsBookmarkedQueryVariables, APITypes.IsBookmarkedQuery>;
export const isUsernameAvailable = /* GraphQL */ `query IsUsernameAvailable($input: IsUsernameAvailableInput!) {
  isUsernameAvailable(input: $input)
}
` as GeneratedQuery<APITypes.IsUsernameAvailableQueryVariables, APITypes.IsUsernameAvailableQuery>;
export const ESQueryFull = /* GraphQL */ `query ESQueryFull($input: ESQueryFullInput!) {
  ESQueryFull(input: $input) {
    result
  }
}
` as GeneratedQuery<APITypes.ESQueryFullQueryVariables, APITypes.ESQueryFullQuery>;
export const listFoldersByUser = /* GraphQL */ `query ListFoldersByUser($input: ListFoldersByUserInput!) {
  listFoldersByUser(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByUserQueryVariables, APITypes.ListFoldersByUserQuery>;
export const listFoldersByParent = /* GraphQL */ `query ListFoldersByParent($input: ListFoldersByParentInput!) {
  listFoldersByParent(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByParentQueryVariables, APITypes.ListFoldersByParentQuery>;
export const listNotesByFolder = /* GraphQL */ `query ListNotesByFolder($input: ListNotesByFolderInput!) {
  listNotesByFolder(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      textbookId
      chapterId
      views
      verified
      classPublic
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesByFolderQueryVariables, APITypes.ListNotesByFolderQuery>;
export const listFlashcardSetsByFolder =
    /* GraphQL */ `query ListFlashcardSetsByFolder($input: ListFlashcardSetsByFolderInput!) {
  listFlashcardSetsByFolder(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      textbookId
      chapterId
      verified
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetsByFolderQueryVariables, APITypes.ListFlashcardSetsByFolderQuery>;
export const listMediaByFolder = /* GraphQL */ `query ListMediaByFolder($input: ListMediaByFolderInput!) {
  listMediaByFolder(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      transcript
      schoolId
      grade
      courseId
      textbookId
      chapterId
      views
      jwt
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediaByFolderQueryVariables, APITypes.ListMediaByFolderQuery>;
export const getFolder = /* GraphQL */ `query GetFolder($input: GetFolderInput!) {
  getFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
  }
}
` as GeneratedQuery<APITypes.GetFolderQueryVariables, APITypes.GetFolderQuery>;
export const getRating = /* GraphQL */ `query GetRating($input: GetRatingInput!) {
  getRating(input: $input) {
    noteId
    userId
    noteOwnerId
    message
    rating
  }
}
` as GeneratedQuery<APITypes.GetRatingQueryVariables, APITypes.GetRatingQuery>;
export const getUserRatingStats = /* GraphQL */ `query GetUserRatingStats($input: GetUserRatingStatsInput!) {
  getUserRatingStats(input: $input) {
    count
    rating
  }
}
` as GeneratedQuery<APITypes.GetUserRatingStatsQueryVariables, APITypes.GetUserRatingStatsQuery>;
export const getNoteRatingStats = /* GraphQL */ `query GetNoteRatingStats($input: GetNoteRatingStatsInput!) {
  getNoteRatingStats(input: $input) {
    count
    rating
  }
}
` as GeneratedQuery<APITypes.GetNoteRatingStatsQueryVariables, APITypes.GetNoteRatingStatsQuery>;
export const getFlashcardSetViewer = /* GraphQL */ `query GetFlashcardSetViewer($input: GetFlashcardSetViewerInput!) {
  getFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedQuery<APITypes.GetFlashcardSetViewerQueryVariables, APITypes.GetFlashcardSetViewerQuery>;
export const getQuizletFlashcards = /* GraphQL */ `query GetQuizletFlashcards($input: QuizletParseInput!) {
  getQuizletFlashcards(input: $input) {
    data
    title
  }
}
` as GeneratedQuery<APITypes.GetQuizletFlashcardsQueryVariables, APITypes.GetQuizletFlashcardsQuery>;
export const requestAICompletion = /* GraphQL */ `query RequestAICompletion($input: AICompletionInput!) {
  requestAICompletion(input: $input) {
    userId
    timestamp
    noteId
    flashcardSetId
    flashcardId
    itemId
    itemType
    id
    input
    messages {
      role
      content
    }
    output
    inputTokens
    outputTokens
    subject
    topic
    type
    timeTaken
  }
}
` as GeneratedQuery<APITypes.RequestAICompletionQueryVariables, APITypes.RequestAICompletionQuery>;
export const getExam = /* GraphQL */ `query GetExam($input: GetExamInput!) {
  getExam(input: $input) {
    type
    name
    description
    unitLabel
    sectionLabel
    baseLink
    image
    category
    color
    date
    time
    index
    noteId
    flashcardSetId
    discord {
      name
      link
      image
    }
    youtube {
      name
      link
      image
    }
    units {
      name
      image
      noteId
      flashcardSetId
      sections
    }
  }
}
` as GeneratedQuery<APITypes.GetExamQueryVariables, APITypes.GetExamQuery>;
export const getExamMCQ = /* GraphQL */ `query GetExamMCQ($input: GetExamMCQInput!) {
  getExamMCQ(input: $input) {
    exam
    questionId
    difficulty
    choices
    question
    reasons
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
  }
}
` as GeneratedQuery<APITypes.GetExamMCQQueryVariables, APITypes.GetExamMCQQuery>;
export const getExamFRQ = /* GraphQL */ `query GetExamFRQ($input: GetExamFRQInput!) {
  getExamFRQ(input: $input) {
    question {
      exam
      questionId
      difficulty
      question
      rating
      ratingCount
      examUnit
      examSection
      noteId
      flashcardSetId
      subject
      topic
      created
      updated
      url
      prompt
      stimuli
      name
      parts {
        question
        rating
        ratingCount
      }
      rubric {
        guidelines
        points
      }
      starred
    }
    stimuli {
      questionId
      stimulusId
      content
      stimulusType
    }
  }
}
` as GeneratedQuery<APITypes.GetExamFRQQueryVariables, APITypes.GetExamFRQQuery>;
export const getSubject = /* GraphQL */ `query GetSubject($input: GetSubjectInput!) {
  getSubject(input: $input) {
    subject
    picture
    color
    category
    order
    topics
  }
}
` as GeneratedQuery<APITypes.GetSubjectQueryVariables, APITypes.GetSubjectQuery>;
export const getBlog = /* GraphQL */ `query GetBlog($input: GetBlogInput!) {
  getBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedQuery<APITypes.GetBlogQueryVariables, APITypes.GetBlogQuery>;
export const getBlogById = /* GraphQL */ `query GetBlogById($input: GetBlogByIdInput!) {
  getBlogById(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedQuery<APITypes.GetBlogByIdQueryVariables, APITypes.GetBlogByIdQuery>;
export const getRecommendedBlogs = /* GraphQL */ `query GetRecommendedBlogs($input: GetRecommendedBlogInput!) {
  getRecommendedBlogs(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.GetRecommendedBlogsQueryVariables, APITypes.GetRecommendedBlogsQuery>;
export const listBlogs = /* GraphQL */ `query ListBlogs($input: ListBlogsInput!) {
  listBlogs(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBlogsQueryVariables, APITypes.ListBlogsQuery>;
export const listBlogsByUser = /* GraphQL */ `query ListBlogsByUser($input: ListBlogsByUserInput!) {
  listBlogsByUser(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBlogsByUserQueryVariables, APITypes.ListBlogsByUserQuery>;
export const getStudySession = /* GraphQL */ `query GetStudySession($input: GetStudySessionInput!) {
  getStudySession(input: $input) {
    userId
    itemId
    type
    created
    updated
    examDate
    newCards
    nextDue
    nextNewDue
    know
    dKnow
    settings {
      LEARN {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        length
      }
      TEST {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        length
      }
      SPACED {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        cardsPerSession
        intervals
      }
      MATCH {
        grid
        starred
        length
      }
      REVIEW {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        sort
        review
      }
    }
    progress {
      NEW
      LEARNING
      REVIEWING
      MASTERED
    }
    syncTime
    title
  }
}
` as GeneratedQuery<APITypes.GetStudySessionQueryVariables, APITypes.GetStudySessionQuery>;
export const listStudySessions = /* GraphQL */ `query ListStudySessions($input: ListStudySessionsInput!) {
  listStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      newCards
      nextDue
      nextNewDue
      know
      dKnow
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sort
          review
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      syncTime
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListStudySessionsQueryVariables, APITypes.ListStudySessionsQuery>;
export const listRecentStudySessions =
    /* GraphQL */ `query ListRecentStudySessions($input: ListRecentStudySessionsInput!) {
  listRecentStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      newCards
      nextDue
      nextNewDue
      know
      dKnow
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sort
          review
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      syncTime
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListRecentStudySessionsQueryVariables, APITypes.ListRecentStudySessionsQuery>;
export const listFlashcardStudyStates =
    /* GraphQL */ `query ListFlashcardStudyStates($input: ListFlashcardStudyStatesInput!) {
  listFlashcardStudyStates(input: $input) {
    items {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      nextDue
      difficulty
      interval
      eFactor
      sort
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardStudyStatesQueryVariables, APITypes.ListFlashcardStudyStatesQuery>;
export const listItemAIChats = /* GraphQL */ `query ListItemAIChats($input: ListItemAIChatsInput!) {
  listItemAIChats(input: $input) {
    items {
      userId
      timestamp
      noteId
      flashcardSetId
      flashcardId
      itemId
      itemType
      id
      input
      messages {
        role
        content
      }
      output
      inputTokens
      outputTokens
      subject
      topic
      type
      timeTaken
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListItemAIChatsQueryVariables, APITypes.ListItemAIChatsQuery>;
export const getNotificationTokenLevel =
    /* GraphQL */ `query GetNotificationTokenLevel($input: GetNotificationTokenLevelInput!) {
  getNotificationTokenLevel(input: $input)
}
` as GeneratedQuery<APITypes.GetNotificationTokenLevelQueryVariables, APITypes.GetNotificationTokenLevelQuery>;
export const getClass = /* GraphQL */ `query GetClass($input: GetClassInput!) {
  getClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
  }
}
` as GeneratedQuery<APITypes.GetClassQueryVariables, APITypes.GetClassQuery>;
export const listEnrolledClasses = /* GraphQL */ `query ListEnrolledClasses {
  listEnrolledClasses {
    items {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListEnrolledClassesQueryVariables, APITypes.ListEnrolledClassesQuery>;
export const listClasses = /* GraphQL */ `query ListClasses($input: ListClassesInput!) {
  listClasses(input: $input) {
    items {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassesQueryVariables, APITypes.ListClassesQuery>;
export const listClassStudySessions =
    /* GraphQL */ `query ListClassStudySessions($input: ListClassStudySessionsInput!) {
  listClassStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      newCards
      nextDue
      nextNewDue
      know
      dKnow
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sort
          review
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      syncTime
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassStudySessionsQueryVariables, APITypes.ListClassStudySessionsQuery>;
export const listClassFlashcardStudyStates = /* GraphQL */ `query ListClassFlashcardStudyStates(
  $input: ListClassFlashcardStudyStatesInput!
) {
  listClassFlashcardStudyStates(input: $input) {
    items {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      nextDue
      difficulty
      interval
      eFactor
      sort
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassFlashcardStudyStatesQueryVariables, APITypes.ListClassFlashcardStudyStatesQuery>;
export const listNotesByClass = /* GraphQL */ `query ListNotesByClass($input: ListNotesByClassInput!) {
  listNotesByClass(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      textbookId
      chapterId
      views
      verified
      classPublic
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesByClassQueryVariables, APITypes.ListNotesByClassQuery>;
export const listFlashcardSetsByClass =
    /* GraphQL */ `query ListFlashcardSetsByClass($input: ListFlashcardSetsByClassInput!) {
  listFlashcardSetsByClass(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      textbookId
      chapterId
      verified
      schoolId
      grade
      courseId
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetsByClassQueryVariables, APITypes.ListFlashcardSetsByClassQuery>;
export const listMediasByClass = /* GraphQL */ `query ListMediasByClass($input: ListMediasByClassInput!) {
  listMediasByClass(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      transcript
      schoolId
      grade
      courseId
      textbookId
      chapterId
      views
      jwt
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediasByClassQueryVariables, APITypes.ListMediasByClassQuery>;
export const listFoldersByClass = /* GraphQL */ `query ListFoldersByClass($input: ListFoldersByClassInput!) {
  listFoldersByClass(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByClassQueryVariables, APITypes.ListFoldersByClassQuery>;
