import { tableRule } from "../rules";
import Node from "./Node";

export default class Table extends Node {
    get name() {
        return "table";
    }

    get schema() {
        return {
            content: "tr+",
            tableRole: "table",
            isolating: true,
            group: "block",
            parseDOM: [{ tag: "table" }],
            toDOM() {
                return ["table", ["tbody", 0]];
            },
        };
    }

    get rulePlugins() {
        return [tableRule];
    }

    toMarkdown(state, node) {
        state.renderTable(node);
        state.closeBlock(node);
    }

    parseMarkdown() {
        return { block: "table" };
    }
}
