import { breakRule } from "../rules";
import Node from "./Node";

export default class HardBreak extends Node {
    get name() {
        return "br";
    }

    get schema() {
        return {
            inline: true,
            group: "inline",
            selectable: false,
            parseDOM: [{ tag: "br" }],
            toDOM: () => ["br"],
        };
    }

    get rulePlugins() {
        return [breakRule];
    }

    toMarkdown(state) {
        state.write(" \\n ");
    }

    parseMarkdown() {
        return { node: "br" };
    }
}
